<div *ngIf="!hideIfEmpty || !currentResourceFieldComponents || currentResourceFieldComponents.length > 0" class="card">
  <div
    *ngIf="showHeader"
    [ngbPopover]="structTypeDescription"
    class="card-header justify-content-between d-flex position-relative"
    triggers="hover"
  >
    <div class="align-content-center"><i *ngIf="icon" [class]="icon"></i> {{ resourceStructType | prettifyName }}</div>
    <button (click)="onDelete()" *ngIf="showDeleteButton" class="btn btn-danger delete-button" id="delete-struct-instance-button">
      <i class="bi bi-trash"></i>
    </button>
  </div>

  <div *ngFor="let fieldComponent of currentResourceFieldComponents">
    <app-field [data]="fieldComponent.data" [fieldComponent]="fieldComponent.editor" />
  </div>

  <div *ngIf="currentResourceFieldComponents && !currentResourceFieldComponents.length && showHeader">
    <p class="text-danger m-2">
      We expected at least one field to be shown here, but none were found. Please check the schema to make sure the fields have field
      editors.
    </p>
  </div>

  <ng-content select="[slot=custom]"></ng-content>
</div>
