import { EventEmitter, Type } from '@angular/core';
import { IconFieldComponent } from '../_components/dynamic-fields/primitive-fields/icon-field/icon-field.component';
import { InlineListEditorComponent } from '../_components/editors/inline-list-editor/inline-list-editor.component';
import { TextAreaFieldComponent } from '../_components/dynamic-fields/primitive-fields/text-area-field/text-area-field.component';
import { AudioFieldComponent } from '../_components/dynamic-fields/non-primitive-fields/audio-field/audio-field.component';
import { CheckFieldComponent } from '../_components/dynamic-fields/primitive-fields/check-field/check-field.component';
import { NumberInputFieldComponent } from '../_components/dynamic-fields/primitive-fields/number-input-field/number-input-field.component';
import { ImageFieldComponent } from '../_components/dynamic-fields/non-primitive-fields/image-field/image-field.component';
import { SelectTypeSelectorComponent } from '../_components/dynamic-fields/primitive-fields/select-type-selector/select-type-selector.component';
import { ResourceSelectorFieldComponent } from '../_components/dynamic-fields/non-primitive-fields/resource-selector-field/resource-selector-field.component';
import { EnumInstanceEditorComponent } from '../_components/editors/enum-instance-editor/enum-instance-editor.component';
import { VideoFieldComponent } from '../_components/dynamic-fields/non-primitive-fields/video-field/video-field.component';
import { ColorFieldComponent } from '../_components/dynamic-fields/primitive-fields/color-field/color-field-component';
import { CanvasEditorComponent } from '../_components/editors/canvas-editor/canvas-editor.component';
import { VectorFieldComponent } from '../_components/dynamic-fields/primitive-fields/vector-field/vector-field.component';
import { FieldType, FieldTypes } from './entities/helpers';
import { Field, FieldValue } from '@services/entities/helpers';
import { ChatComponent } from '../_components/editors/mission/activity/chat/chat.component';
import { ChatFieldComponent } from '../_components/dynamic-fields/non-primitive-fields/chat-field/chat-field.component';
import { StructInstanceFieldEditorComponent } from '../_components/editors/struct-instance-field-editor/struct-instance-field-editor.component';
import { DataInstance } from '@services/entities';
import { Logger } from '@services/utils';
import { StructTypeRepository } from '@services/repositories';
import GTInjector from '@services/GTInjector';
import { TextWithAudioFieldComponent } from '../_components/dynamic-fields/non-primitive-fields/text-with-audio-field/text-with-audio-field.component';
import { GeneratedFieldEditor } from '@services/types/generated';
import { TextInputFieldComponent } from '../_components/dynamic-fields/primitive-fields/text-input-field/text-input-field.component';
import { FieldEditorType } from '@services/types/FieldEditorType';
import { VariableSelectorFieldComponent } from '../_components/dynamic-fields/primitive-fields/variable-selector-field/variable-selector-field';

export type FieldEditorComponentType<T = unknown> = Type<FieldEditorComponent<T>> & {
  editorType?: FieldEditorType;
  readonly onChange?: EventEmitter<void>;
};

export type FieldDefinition = { editor: FieldEditorComponentType; data: FieldValue };

export abstract class FieldEditorComponent<T> {
  public abstract data?: FieldValue;
  public abstract value: T;
}

function Seamless<T>(component: FieldEditorComponentType<T>): FieldEditorComponentType<T> {
  return class SeamlessComponent extends component {
    static override editorType = FieldEditorType.SeamlessInline;
    static override onChange = new EventEmitter<void>();
    data?: FieldValue;
    value!: T;
  };
}

function Hidden<T>(component: FieldEditorComponentType<T>): FieldEditorComponentType<T> {
  return class HiddenComponent extends component {
    static override editorType = FieldEditorType.Hidden;
    static override onChange = new EventEmitter<void>();
    data?: FieldValue;
    value!: T;
  };
}

function Inline<T>(component: FieldEditorComponentType<T>): FieldEditorComponentType<T> {
  return class InlineComponent extends component {
    static override editorType = FieldEditorType.Inline;
    static override onChange = new EventEmitter<void>();
    data?: FieldValue;
    value!: T;
  };
}

export class DynamicFieldService {
  public static getComponent(field: Field, inSeamlessInline: boolean): FieldEditorComponentType {
    if (field.fieldEditor?.hideInSeamlessInline && inSeamlessInline) {
      return Hidden(this.getComponentForField(field));
    }
    switch (field.fieldEditor?.editorType) {
      case 'CanvasEditor':
        return Seamless(CanvasEditorComponent);
      case 'ChatEditor':
        return Seamless(ChatComponent);
      case 'Chat':
        return ChatFieldComponent;
      case 'Hidden':
        return Hidden(this.getComponentForField(field));
      case 'Inline':
        return Inline(this.getComponentForField(field));
      case 'SeamlessInline':
        return Seamless(this.getComponentForField(field));
      default:
        return Inline(this.getComponentForField(field));
    }
  }

  public static async getFieldComponents(
    dataInstance: DataInstance,
    resourceStructTypeId: string,
    showMedia = false,
    isSeamlessInline: boolean,
  ) {
    const structTypeRepository = await GTInjector.inject(StructTypeRepository);
    const structType = await structTypeRepository.get(resourceStructTypeId);

    return (
      await Promise.all(
        Object.values(structType.fields).map(async (field) => {
          // This is to ensure that the image, video and color field are not shown as they are already in the canvas as filepicker.
          // We cannot just set the editorType to hidden as they need to be visible in for example visualElements.
          // TODO: Remove it once showIf is implemented in the fieldEditor
          if (!showMedia) {
            switch (resourceStructTypeId) {
              case 'ImagePlaceableMedia':
                if (field.fieldId === 'image') return undefined;
                break;
              case 'VideoPlaceableMedia':
                if (field.fieldId === 'video') return undefined;
                break;
              case 'SolidColorPlaceableMedia':
                if (field.fieldId === 'color') return undefined;
                break;
            }
          }

          try {
            const component = this.getComponent(field, isSeamlessInline);

            if (field.fieldEditor?.showIf) {
              if (Object.keys(dataInstance.fieldValues).includes(field.fieldEditor.showIf)) {
                const fieldValue = dataInstance.fieldValues[field.fieldEditor.showIf];
                if (fieldValue) {
                  const handle = () => {
                    if (fieldValue.value !== field.fieldEditor?.showIfValue) component.editorType = FieldEditorType.Hidden;
                    else component.editorType = field.fieldEditor?.editorType;
                    component.onChange!.emit();
                  };

                  fieldValue.onChange.subscribe(handle.bind(this));
                  handle();
                }
              } else {
                console.error(`Field ${field.fieldId} has a showIf field that does not exist: ${field.fieldEditor.showIf}`);
              }
            }

            if (!component) {
              Logger.warn(`Failed to get component for field ${field.fieldId}`);
              return undefined;
            }

            if (!dataInstance.fieldValues[field.fieldId]) {
              const value = await FieldValue.serializeValue(field.type, await field.getDefault());
              dataInstance.fieldValues[field.fieldId] = new FieldValue({
                field: field,
                dataInstanceUid: await dataInstance.identifier,
                value: '',
              });
              await dataInstance.fieldValues[field.fieldId]!.set(value);
            }

            return { editor: component, data: dataInstance.fieldValues[field.fieldId]! } satisfies FieldDefinition;
          } catch (e) {
            Logger.error(`Failed to get component for field '${field.fieldId}'`, e);
            return undefined;
          }
        }),
      )
    ).filter(Boolean) as FieldDefinition[];
  }

  private static getComponentForField(field: Field): FieldEditorComponentType {
    switch (field.type) {
      case FieldType.STRING:
        if (field.fieldEditor?.textEditorType === GeneratedFieldEditor.TextEditorTypeEnum.Field) return TextInputFieldComponent;
        return TextAreaFieldComponent;
      case FieldType.BOOLEAN:
        return CheckFieldComponent;
      case FieldType.INT:
      case FieldType.FLOAT:
        return NumberInputFieldComponent;
      case FieldType.IMAGE_REF:
        return ImageFieldComponent;
      case FieldType.VIDEO_REF:
        return VideoFieldComponent;
      case FieldType.AUDIO_REF:
        return AudioFieldComponent;
      case FieldType.ICON:
        return IconFieldComponent;
      case FieldType.COLOR:
        return ColorFieldComponent;
      case FieldType.VECTOR2:
      case FieldType.VECTOR3:
        // TODO: The VectorFieldComponent only handles Vector2 for some reason..
        return VectorFieldComponent;
      case FieldType.VARIABLE_REF:
        return VariableSelectorFieldComponent;
    }

    if (FieldTypes.isListType(field.type)) {
      const referencing = FieldTypes.getReferencedTypeId(field.type);

      if (!referencing) {
        throw new Error('Unknown field type: ' + field.type);
      }

      if (FieldTypes.matches('STRUCT_MATCHER', referencing) || FieldTypes.matches('ENUM_MATCHER', referencing)) {
        return InlineListEditorComponent;
      }

      if (FieldTypes.isReferencing(referencing)) {
        return ResourceSelectorFieldComponent;
      }

      throw new Error('Unknown field type: ' + field.type);
    }

    if (FieldTypes.isReferencing(field.type)) {
      return ResourceSelectorFieldComponent;
    }

    if (FieldTypes.matches('STRUCT_MATCHER', field.type)) {
      if (FieldTypes.getReferencedTypeId(field.type) === 'TextWithAudio') return TextWithAudioFieldComponent;
      return StructInstanceFieldEditorComponent;
    }
    if (FieldTypes.matches('ENUM_MATCHER', field.type)) return EnumInstanceEditorComponent;
    if (FieldTypes.matches('SELECT_MATCHER', field.type)) return SelectTypeSelectorComponent;

    throw new Error('Unknown field type: ' + field.type);
  }
}
