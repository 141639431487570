import { Repository } from './Repository';
import { Injectable } from '@angular/core';
import { GeneratedTag } from '../types/generated';
import { BehaviorSubject, lastValueFrom, map } from 'rxjs';
import { Cache } from '../utils';
import { Tag } from '@services/entities';
import { TagEndpoints } from '@services/api';
import { DirtyHandling } from '@services/decorators/DirtyHandling';

@Injectable({ providedIn: 'root' })
export class TagRepository extends Repository<Tag> {
  public readonly cacheSubject = new BehaviorSubject<Tag[]>([]);
  public readonly cache$ = this.cacheSubject.asObservable();
  private readonly cache = new Cache<Tag>();

  constructor(private tagEndpoints: TagEndpoints) {
    super();
  }

  @DirtyHandling()
  public override async save(entity: Tag): Promise<void> {
    await lastValueFrom(this.tagEndpoints.updateTag(await entity.serialize()));
    this.updateCache(entity);
  }

  public override async delete(_entity: Tag): Promise<void> {
    await lastValueFrom(this.tagEndpoints.deleteTag(_entity.uid));
    this.removeFromCache(_entity.uid);
  }

  public override async create(data: GeneratedTag): Promise<Tag> {
    const tag = await Tag.deserialize(await lastValueFrom(this.tagEndpoints.createTag(data)));
    this.updateCache(tag);
    return tag;
  }

  public override async get(uid: string, skipCache: boolean = false): Promise<Tag> {
    if (!skipCache && this.cache.isValid(uid)) {
      return this.cache.get(uid)!.value;
    }

    if (this.requests[uid] !== undefined) {
      return await lastValueFrom(this.requests[uid]);
    }

    this.requests[uid] = this.tagEndpoints.getTag(uid).pipe(
      map(async (data) => {
        const tag = await Tag.deserialize(data);
        this.updateCache(tag);
        return tag;
      }),
    );

    const data = await lastValueFrom(this.requests[uid]);
    delete this.requests[uid];
    return data;
  }

  public async getAll(): Promise<Tag[]> {
    const tags = await Promise.all(
      (await lastValueFrom(this.tagEndpoints.getTags())).map(async (tag) => {
        if (this.cache.isValid(tag.uid)) return this.cache.get(tag.uid)!.value;
        return this.cache.set(tag.uid, await Tag.deserialize(tag), 5);
      }),
    );
    this.cacheSubject.next(tags);
    return tags;
  }

  private updateCache(tag: Tag) {
    this.cache.set(tag.uid, tag, 5);
    const updatedTags = Object.values(this.cache.getAll());
    this.cacheSubject.next(updatedTags);
  }

  private removeFromCache(uid: string) {
    this.cache.invalidate(uid);
    const updatedTags = Object.values(this.cache.getAll()).filter((tag) => tag.uid !== uid);
    this.cacheSubject.next(updatedTags);
  }
}
