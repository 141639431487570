<div class="position-relative">
  <div #rete class="rete" id="#rete"></div>

  <div class="position-absolute bottom-0">
    <div class="bg-body w-auto">
      <button (click)="setShowLegend(!showLegend)" *ngIf="flowchartType === FlowchartType.MISSION" class="btn btn-sm">
        <i *ngIf="showLegend" class="bi-caret-down-fill">&nbsp;Hide legend</i>
        <i *ngIf="!showLegend" class="bi-caret-up-fill">&nbsp;Show legend</i>
      </button>

      <div *ngIf="flowchartType === FlowchartType.MISSION && showLegend" class="p-1 pt-0">
        <div *ngFor="let activityType of activityTypes" class="d-flex">
          <div class="col-1">
            <div [ngStyle]="{ 'background-color': activityColors[activityType], width: '20px', height: '20px' }"></div>
          </div>

          <div class="col-8">
            <p class="px-2 mb-0 small">{{ activityType }}</p>
          </div>

          <div class="col-3 ms-1">
            <button
              (click)="createNode(activityType)"
              [ngStyle]="{ height: '20px' }"
              class="btn btn-primary btn-sm px-1 py-0 align-content-center"
            >
              <i class="bi-plus-circle"></i> add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="position-absolute bottom-0 end-0 p-1 d-flex flex-column gap-1">
    <div class="d-flex justify-content-end">
      <button
        (click)="arrangeNodes()"
        class="btn btn-primary btn-sm ms-1"
        ngbPopover="Arrange nodes"
        style="font-size: medium"
        triggers="hover"
      >
        <i class="bi-shuffle"></i>
      </button>

      <button
        (click)="zoomAtAllNodes()"
        class="btn btn-primary btn-sm ms-1"
        ngbPopover="Fit to screen"
        style="font-size: medium"
        triggers="hover"
      >
        <i class="bi-fullscreen"></i>
      </button>

      <button
        (click)="showPasteModal()"
        *ngIf="flowchartType === FlowchartType.MISSION"
        class="btn btn-primary btn-sm ms-1"
        ngbPopover="Paste activities"
        style="font-size: medium"
        triggers="hover"
      >
        <i class="bi-clipboard-plus-fill"></i>
      </button>
    </div>

    <div class="d-flex">
      <button
        (click)="copySelectedNodes()"
        *ngIf="flowchartType === FlowchartType.MISSION"
        [disabled]="!selector || selector.entities.size === 0"
        class="btn btn-primary btn-sm ms-1"
      >
        Copy selected
      </button>
      <button
        (click)="duplicateSelectedNodes()"
        *ngIf="flowchartType === FlowchartType.MISSION"
        [disabled]="!selector || selector.entities.size === 0"
        class="btn btn-primary btn-sm ms-1"
      >
        Duplicate selected
      </button>
      <button
        (click)="deleteSelectedNodes()"
        *ngIf="flowchartType === FlowchartType.MISSION"
        [disabled]="!selector || selector.entities.size === 0"
        class="btn btn-danger btn-sm ms-1"
      >
        Delete selected
      </button>
    </div>
  </div>
</div>

<ng-template #pasteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Paste Activities</h4>
    <button (click)="modal.dismiss('cross click')" aria-describedby="modal-title" class="btn-close" type="button">&nbsp;</button>
  </div>

  <div class="modal-body">
    <p>Enter the UIDs of the activities to be pasted. Separate multiple UIDs with a comma.</p>

    <textarea
      (paste)="formatPastedActivities()"
      [(ngModel)]="pasteActivityUids"
      class="form-control"
      rows="5"
      style="max-height: 50vh"
      type="text"
    ></textarea>
  </div>

  <div class="modal-footer">
    <button (click)="modal.dismiss('cancel click')" class="btn btn-outline-secondary" type="button">Cancel</button>

    <button (click)="modal.close('confirm click')" [disabled]="!pasteActivityUids.length" class="btn btn-primary" type="button">
      Paste
    </button>
  </div>
</ng-template>
