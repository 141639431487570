import { Entity } from './Entity';
import { AutoSave } from '../decorators/AutoSave';
import { SelectTypeOption } from './helpers/SelectTypeOption';
import { GeneratedSelectType } from '../types/generated';
import GTInjector from '../GTInjector';
import { SelectTypeRepository } from '@services/repositories';
import { Tag } from '@services/entities';

export interface NewSelectType<T extends string = string> {
  typeId: string;
  name: string;
  description?: string;
  options: SelectTypeOption<T>[];
  tags: Tag[];
}

@AutoSave()
export class SelectType<T extends string = string> extends Entity {
  public readonly typeId: string;

  public name: string;
  public description?: string;
  public options: SelectTypeOption<T>[];
  public tags: Tag[];

  constructor({ typeId, name, description, options, tags }: NewSelectType<T>) {
    super();
    this.typeId = typeId;
    this.name = name;
    this.description = description;
    this.options = options;
    this.tags = tags;
  }

  public override get identifier() {
    return this.typeId;
  }

  public static async deserialize<T extends string = string>(data: GeneratedSelectType): Promise<SelectType<T>> {
    const selectType = new SelectType<T>({
      typeId: data.typeId,
      name: data.name,
      description: data.description,
      options: await Promise.all(data.options.map((o) => SelectTypeOption.deserialize<T>(o))),
      tags: await Promise.all(data.tags.map((tag) => Tag.deserialize(tag))),
    });

    await selectType.updateLastSavedValueHash();
    return selectType;
  }

  /**
   * @deprecated This method should be rarely used by a developer because @AutoSave() should handle most cases. If you
   * find any cases where auto saving does not work, please open a bug report so it can be fixed.
   */
  public async save() {
    return (await GTInjector.inject(SelectTypeRepository)).save<T>(this);
  }

  public async serialize(): Promise<Readonly<GeneratedSelectType>> {
    return Object.freeze({
      typeId: this.typeId,
      name: this.name,
      description: this.description,
      options: await Promise.all(this.options.map((o) => o.serialize())),
      tags: await Promise.all(this.tags.map((tag) => tag.serialize())),
    });
  }
}
