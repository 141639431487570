import { GeneratedFileMeta } from '@services/types/generated';
import { DataInstance, Variable } from '@services/entities';

export type ListInstance = GeneratedFileMeta | DataInstance | Variable;

export function isDataInstance(instance: ListInstance): instance is DataInstance {
  return instance instanceof DataInstance;
}

export function isVariable(instance: ListInstance): instance is Variable {
  return instance instanceof Variable;
}

export function isGeneratedFileMeta(instance: ListInstance): instance is GeneratedFileMeta {
  return 'name' in instance && 'alt' in instance;
}

export function getNameOfListInstance(instance: ListInstance): string {
  if (isDataInstance(instance)) {
    return instance.getName();
  }
  return instance.name;
}

export function getUidOfListInstance(instance: ListInstance): string {
  if (isDataInstance(instance)) {
    return instance.randomIdentifier;
  } else if (isVariable(instance)) {
    return instance.variableRef;
  }
  return instance.uid;
}
