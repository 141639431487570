<div *ngIf="variable" class="p-4">
  <div class="card" id="variable-editor">
    <div class="card-header lb-card-header">Variable: {{ variable.variableRef }}</div>

    <div class="d-flex justify-content-between mb-2">
      <div class="label" id="name-label">Name</div>

      <input
        #name="ngModel"
        (change)="hasChanges = true"
        [(ngModel)]="newName"
        class="form-control"
        id="name"
        name="name"
        required
        type="text"
      />

      <div *ngIf="name.invalid && name.touched" class="alert alert-danger">Name is required.</div>
    </div>

    <div class="d-flex justify-content-between mb-2">
      <div class="label" id="description-label">Description</div>
      <input (change)="hasChanges = true" [(ngModel)]="newDescription" class="form-control" id="description" type="text" />
    </div>

    <div class="d-flex justify-content-between mb-2">
      <div class="label">Is play through statistic</div>
      <div class="form-check form-switch w-100">
        <input
          (change)="hasChanges = true"
          [(ngModel)]="newIsPlayThroughStatistic"
          class="form-check-input"
          id="is-play-through-statistic"
          role="switch"
          type="checkbox"
        />
      </div>
    </div>

    <div class="d-flex justify-content-between mb-2">
      <div class="label">Value type</div>

      <select (change)="onValueTypeChange()" [(ngModel)]="newValueType" class="form-select" id="value-type">
        <option *ngFor="let option of valueTypeOptions">
          {{ option }}
        </option>
      </select>
    </div>

    <div *ngIf="newValueType" class="d-flex justify-content-between mb-2">
      <div class="label">Start Value</div>

      <input
        (change)="hasChanges = true"
        *ngIf="newValueType === VariableType.String"
        [(ngModel)]="newStartValue"
        class="form-control"
        id="start-value-string"
        type="text"
      />

      <input
        (change)="onNewNumber()"
        *ngIf="newValueType === VariableType.Number"
        [(ngModel)]="newStartValue"
        class="form-control"
        id="start-value-number"
        type="number"
      />

      <div *ngIf="newValueType === VariableType.Boolean" class="form-check form-switch w-100">
        <input
          (change)="hasChanges = true"
          [(ngModel)]="newStartValue"
          class="form-check-input"
          id="start-value-boolean"
          role="switch"
          type="checkbox"
        />
      </div>

      <div *ngIf="newValueType === VariableType.Color" class="form-check form-switch w-100">
        <input
          (colorPickerSelect)="updateColor($event)"
          [colorPicker]="String(newStartValue)"
          [cpAlphaChannel]="'always'"
          [cpEyeDropper]="true"
          [cpOKButtonText]="'Save'"
          [cpOKButton]="true"
          [cpOutputFormat]="'hex'"
          [style.background]="newStartValue"
          [value]="newStartValue"
          id="start-value-color"
          style="width: 100px"
        />
      </div>
    </div>
  </div>

  <div class="d-flex w-100 justify-content-end gap-1 mt-2">
    <button (click)="deleteVariable()" class="btn btn-danger">
      <i class="bi bi-trash"></i>
      Delete
    </button>

    <button (click)="saveVariable()" [disabled]="!hasChanges" class="btn btn-primary" id="save-variable-button">
      <i class="far fa-save"></i>
      Save
    </button>
  </div>
</div>

<app-spinner *ngIf="!variable" />
