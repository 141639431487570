import { Serializable } from '../types/Serializable';

export type Vector2Type = [number, number] | [string, string] | { x: number; y: number } | `${number};${number}` | string;

export class Vector2 implements Serializable<string> {
  public x: number;
  public y: number;

  constructor(data: Vector2Type) {
    switch (typeof data) {
      case 'object': {
        const isArray = Array.isArray(data);
        this.x = +(isArray ? data[0] : data.x);
        this.y = +(isArray ? data[1] : data.y);
        break;
      }
      case 'string': {
        if (!Vector2.isValidString(data)) throw new Error('Invalid Vector2 string: ' + data);
        const [x, y] = data.split(';');
        this.x = +x;
        this.y = +y;
        break;
      }
    }
  }

  static get zero() {
    return new Vector2({ x: 0, y: 0 });
  }

  static isValidString(value: string): boolean {
    const split = value.split(';');
    if (split.length !== 2) return false;
    const [x, y] = split;
    return !isNaN(+x) && !isNaN(+y);
  }

  static add(a: Vector2, b: Vector2) {
    return new Vector2({ x: a.x + b.x, y: a.y + b.y });
  }

  serialize() {
    return Promise.resolve(`${this.x};${this.y}`);
  }
}

export type Vector3Type =
  | [number, number, number]
  | [string, string, string]
  | { x: number; y: number; z: number }
  | `${number};${number};${number}`
  | string;

export class Vector3 implements Serializable<string> {
  public x: number;
  public y: number;
  public z: number;

  constructor(data: Vector3Type) {
    switch (typeof data) {
      case 'object': {
        const isArray = Array.isArray(data);
        this.x = +(isArray ? data[0] : data.x);
        this.y = +(isArray ? data[1] : data.y);
        this.z = +(isArray ? data[2] : data.z);
        break;
      }
      case 'string': {
        if (!Vector3.isValidString(data)) throw new Error('Invalid Vector3 string: ' + data);
        const [x, y, z] = data.split(';');
        this.x = +x;
        this.y = +y;
        this.z = +z;
        break;
      }
    }
  }

  static get zero() {
    return new Vector3({ x: 0, y: 0, z: 0 });
  }

  static isValidString(value: string): boolean {
    const split = value.split(';');
    if (split.length !== 3) return false;
    const [x, y, z] = split;
    return !isNaN(+x) && !isNaN(+y) && !isNaN(+z);
  }

  static add(a: Vector3, b: Vector3) {
    return new Vector3({ x: a.x + b.x, y: a.y + b.y, z: a.z + b.z });
  }

  serialize() {
    return Promise.resolve(`${this.x};${this.y};${this.z}`);
  }
}
