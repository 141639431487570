import { Injectable } from '@angular/core';
import { ApiBase } from '../ApiBase';
import { Observable } from 'rxjs';
import { GeneratedSelectType } from '../../types/generated';

@Injectable({ providedIn: 'root' })
export class SelectTypeEndpoints extends ApiBase {
  public getSelectTypes(): Observable<GeneratedSelectType[]> {
    return this.get<GeneratedSelectType[]>(`/game/${this.gameId}/schema/select-types`);
  }

  public getSelectType(selectTypeId: string): Observable<GeneratedSelectType> {
    return this.get<GeneratedSelectType>(`/game/${this.gameId}/schema/select-types/${selectTypeId}`);
  }

  public createSelectType(selectType: GeneratedSelectType): Observable<GeneratedSelectType> {
    return this.post<GeneratedSelectType>(`/game/${this.gameId}/schema/select-types`, selectType);
  }

  public updateSelectType(selectType: GeneratedSelectType): Observable<GeneratedSelectType> {
    return this.put<GeneratedSelectType>(`/game/${this.gameId}/schema/select-types/${selectType.typeId}`, selectType);
  }

  public deleteSelectType(selectTypeId: string, force = false): Observable<void> {
    return this.delete<void>(`/game/${this.gameId}/schema/select-types/${selectTypeId}`, {
      params: force ? { force: 'true' } : undefined,
    });
  }
}
