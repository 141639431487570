type Shape = {
  type: 'Circle' | 'Rectangle';
  x: number;
  y: number;
  radius?: number; // percentage of width
  size?: {
    width: number; // percentage of width
    height: number; // percentage of height
  };
  isVisualElement: boolean;
};

export type Preset = {
  name: string;
  shapes: Shape[];
};

const width = 1080;
const height = 1920;

export const Presets: Preset[] = [
  {
    name: 'tekst-2 bal',
    shapes: [
      {
        type: 'Rectangle',
        size: {
          width: 1030 / width,
          height: 180 / height,
        },
        x: 25 / width,
        y: 559 / height,
        isVisualElement: true,
      },
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 284 / width,
        y: 1064 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 796 / width,
        y: 1064 / height,
        isVisualElement: false,
      },
    ],
  },
  {
    name: '2 bal-tekst',
    shapes: [
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 285 / width,
        y: 465 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 795 / width,
        y: 465 / height,
        isVisualElement: false,
      },
      {
        type: 'Rectangle',
        size: {
          width: 960 / width,
          height: 960 / height,
        },
        x: (540 - 960 / 2) / width,
        y: (1350 - 960 / 2) / height,
        isVisualElement: true,
      },
    ],
  },
  {
    name: 'tekst-3 bal',
    shapes: [
      {
        type: 'Rectangle',
        size: {
          width: 1030 / width,
          height: 180 / height,
        },
        x: 25 / width,
        y: 285 / height,
        isVisualElement: true,
      },
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 284 / width,
        y: 790 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 796 / width,
        y: 790 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 540 / width,
        y: 1337 / height,
        isVisualElement: false,
      },
    ],
  },
  {
    name: 'tekst-4 bal',
    shapes: [
      {
        type: 'Rectangle',
        size: {
          width: 1030 / width,
          height: 180 / height,
        },
        x: 25 / width,
        y: 285 / height,
        isVisualElement: true,
      },
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 284 / width,
        y: 790 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 796 / width,
        y: 790 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 284 / width,
        y: 1337 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 450 / width / 2,
        x: 796 / width,
        y: 1337 / height,
        isVisualElement: false,
      },
    ],
  },
  {
    name: 'tekst-1 bal',
    shapes: [
      {
        type: 'Rectangle',
        size: {
          width: 1030 / width,
          height: 180 / height,
        },
        x: 25 / width,
        y: 410 / height,
        isVisualElement: true,
      },
      {
        type: 'Circle',
        radius: 750 / width / 2,
        x: 540 / width,
        y: 1065 / height,
        isVisualElement: true,
      },
    ],
  },
  {
    name: 'tekst-6 bal',
    shapes: [
      {
        type: 'Rectangle',
        size: {
          width: 1030 / width,
          height: 180 / height,
        },
        x: 25 / width,
        y: 237 / height,
        isVisualElement: true,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 645 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 645 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 1072 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 1072 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 1499 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 1499 / height,
        isVisualElement: false,
      },
    ],
  },
  {
    name: 'tekst-40 bal',
    shapes: [
      {
        type: 'Rectangle',
        size: {
          width: 1030 / width,
          height: 180 / height,
        },
        x: 25 / width,
        y: 237 / height,
        isVisualElement: true,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 645 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 645 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 1072 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 1072 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 1499 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 1499 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 1926 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 1926 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 2353 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 2353 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 2780 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 2780 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 3207 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 3207 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 3634 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 3634 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 4061 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 4061 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 4488 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 4488 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 4915 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 4915 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 5342 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 5342 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 5769 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 5769 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 6196 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 6196 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 6623 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 6623 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 7050 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 7050 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 7477 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 7477 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 7904 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 7904 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 8331 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 8331 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 325 / width,
        y: 8758 / height,
        isVisualElement: false,
      },
      {
        type: 'Circle',
        radius: 330 / width / 2,
        x: 755 / width,
        y: 8758 / height,
        isVisualElement: false,
      },
    ],
  },
  {
    name: 'tekst met volgendeknop',
    shapes: [
      {
        type: 'Rectangle',
        size: {
          width: 984 / width,
          height: 1584 / height,
        },
        x: 48 / width,
        y: 48 / height,
        isVisualElement: true,
      },
      {
        type: 'Rectangle',
        size: {
          width: 192 / width,
          height: 192 / height,
        },
        x: 840 / width,
        y: 1680 / height,
        isVisualElement: false,
      },
    ],
  },
];
