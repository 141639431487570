/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeneratedFieldValue } from './fieldValue';
import { GeneratedTag } from './tag';

/**
 *
 */
export interface GeneratedDataInstance {
  uid: string;
  dataType: string;
  fieldValues: Array<GeneratedFieldValue>;
  subObjects?: { [key: string]: GeneratedDataInstance };
  tags: Array<GeneratedTag>;
  modified?: string;
}
