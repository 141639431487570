import GTInjector from '../../GTInjector';
import { EnumTypeRepository, SelectTypeRepository, StructTypeRepository } from '@services/repositories';

export enum FieldType {
  INT = 'int',
  FLOAT = 'float',
  STRING = 'string',
  BOOLEAN = 'bool',
  VECTOR2 = 'Vector2',
  VECTOR3 = 'Vector3',
  COLOR = 'Color',
  ICON = 'Icon',
  IMAGE_REF = 'ImageRef',
  AUDIO_REF = 'AudioRef',
  VIDEO_REF = 'VideoRef',
  FILE_REF = 'FileRef',
  VARIABLE_REF = 'VariableRef',

  STRUCT = 'Struct',
  STRUCT_REF = 'StructRef',
  ENUM = 'Enum',
  ENUM_REF = 'EnumRef',
  SELECT = 'Select',
  LIST = 'List',
}

enum FieldTypeMatcher {
  STRUCT_REF_MATCHER = 'STRUCT_REF_MATCHER',
  STRUCT_MATCHER = 'STRUCT_MATCHER',
  ENUM_REF_MATCHER = 'ENUM_REF_MATCHER',
  ENUM_MATCHER = 'ENUM_MATCHER',
  SELECT_MATCHER = 'SELECT_MATCHER',
  LIST_MATCHER = 'LIST_MATCHER',
}

/**
 * The reason why we return functions that build the regex is due to Regex being a bit weird in javascript,
 * it keeps a sort of history of matches and can lead to unexpected behavior.
 */
const FieldTypeMatchers: Record<FieldTypeMatcher, () => RegExp> = {
  STRUCT_REF_MATCHER: () => /^StructRef<(.+)>$/,
  STRUCT_MATCHER: () => /^Struct<(.+)>$/,
  ENUM_REF_MATCHER: () => /^EnumRef<(.+)>$/,
  ENUM_MATCHER: () => /^Enum<(.+)>$/,
  SELECT_MATCHER: () => /^Select<(.+)>$/,
  LIST_MATCHER: () => /^List<(.+)>$/,
} as const;

export class FieldTypes {
  public static isDataInstanceReferenceType(type: string) {
    return [
      FieldTypeMatchers.STRUCT_MATCHER(),
      FieldTypeMatchers.STRUCT_REF_MATCHER(),
      FieldTypeMatchers.ENUM_MATCHER(),
      FieldTypeMatchers.ENUM_REF_MATCHER(),
    ].some((matcher) => type.match(matcher));
  }

  public static isReferencing(type: string) {
    return [FieldTypeMatchers.STRUCT_REF_MATCHER(), FieldTypeMatchers.ENUM_REF_MATCHER()].some((matcher) => type.match(matcher));
  }

  /**
   * Given a type, gets the deepest reference, i.e. the referenced type that is not a reference itself.
   * @example
   * FieldTypes.getDeepestReference('List<StructRef<Struct>>') // returns 'Struct'
   * FieldTypes.getDeepestReference('StructRef<Struct>') // returns 'Struct'
   * FieldTypes.getDeepestReference('Struct') // returns 'Struct'
   * FieldTypes.getDeepestReference('int') // returns 'int'
   */
  public static getDeepestReference(type: string): string {
    const match = this.getReferencedTypeId(type);

    if (match) {
      return this.getDeepestReference(match);
    }

    return type;
  }

  public static getListType(type: string): string | null {
    const match = type.match(FieldTypeMatchers.LIST_MATCHER());
    return match?.[1] ?? null;
  }

  public static getReferencedTypeId(type: string): string | null {
    const match = [
      FieldTypeMatchers.STRUCT_MATCHER(),
      FieldTypeMatchers.STRUCT_REF_MATCHER(),
      FieldTypeMatchers.ENUM_MATCHER(),
      FieldTypeMatchers.ENUM_REF_MATCHER(),
      FieldTypeMatchers.SELECT_MATCHER(),
      FieldTypeMatchers.LIST_MATCHER(),
    ]
      .map((matcher) => type.match(matcher))
      .find((match) => match && match[1] !== undefined)?.[1];

    return match || null;
  }

  public static isListType(type: string): boolean {
    return !!type.match(FieldTypeMatchers.LIST_MATCHER());
  }

  public static async isValid(type: string): Promise<boolean> {
    if (Object.values(FieldType).includes(type as FieldType)) return true;

    const match = type.match(FieldTypeMatchers.LIST_MATCHER());
    if (match) return await FieldTypes.isValid(match[1]);

    if (await FieldTypes.isStructTypeValid(type)) return true;
    if (await FieldTypes.isEnumTypeValid(type)) return true;

    return await FieldTypes.isSelectTypeValid(type);
  }

  public static async isStructTypeValid(structType: string) {
    const structTypeRepository = await GTInjector.inject(StructTypeRepository);
    const structTypeIds = await structTypeRepository.getAllIds();

    if (structTypeIds.includes(structType)) return true;

    for (const matcher of [FieldTypeMatchers.STRUCT_REF_MATCHER, FieldTypeMatchers.STRUCT_MATCHER]) {
      const match = structType.match(matcher());
      if (!match) continue;

      if (structTypeIds.includes(match[1])) return true;
      if (await FieldTypes.isValid(match[1])) return true;
    }

    return false;
  }

  public static async isEnumTypeValid(enumType: string) {
    const enumTypeRepository = await GTInjector.inject(EnumTypeRepository);
    const enumTypeIds = await enumTypeRepository.getAllIds();

    if (enumTypeIds.includes(enumType)) return true;

    for (const matcher of [FieldTypeMatchers.ENUM_REF_MATCHER, FieldTypeMatchers.ENUM_MATCHER]) {
      const match = enumType.match(matcher());
      if (!match) continue;

      if (enumTypeIds.includes(match[1])) return true;
      if (await FieldTypes.isValid(match[1])) return true;
    }

    return false;
  }

  public static async isSelectTypeValid(selectType: string) {
    const selectTypeRepository = await GTInjector.inject(SelectTypeRepository);
    const selectTypeIds = await selectTypeRepository.getAllIds();

    const match = selectType.match(FieldTypeMatchers.SELECT_MATCHER());
    if (!match) return false;

    if (selectTypeIds.includes(match[1])) return true;
    return await FieldTypes.isValid(match[1]);
  }

  public static getFieldType(type: string): FieldType {
    if (Object.values(FieldType).includes(type as FieldType)) {
      return type as FieldType;
    }

    const structMatch = type.match(FieldTypeMatchers.STRUCT_MATCHER());
    if (structMatch) {
      return FieldType.STRUCT;
    }

    const structRefMatch = type.match(FieldTypeMatchers.STRUCT_REF_MATCHER());
    if (structRefMatch) {
      return FieldType.STRUCT_REF;
    }

    const enumMatch = type.match(FieldTypeMatchers.ENUM_MATCHER());
    if (enumMatch) {
      return FieldType.ENUM;
    }

    const enumRefMatch = type.match(FieldTypeMatchers.ENUM_REF_MATCHER());
    if (enumRefMatch) {
      return FieldType.ENUM_REF;
    }

    const selectMatch = type.match(FieldTypeMatchers.SELECT_MATCHER());
    if (selectMatch) {
      return FieldType.SELECT;
    }

    const listMatch = type.match(FieldTypeMatchers.LIST_MATCHER());
    if (listMatch) {
      return FieldType.LIST;
    }

    throw new Error(`Invalid type '${type}'`);
  }

  public static matches(key: keyof typeof FieldTypeMatcher, type: string) {
    return type.match(FieldTypeMatchers[key]());
  }
}
