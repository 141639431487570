import { Component, Input } from '@angular/core';
import { ClassicPreset } from 'rete';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'connection',
  template: `
    <svg data-testid="connection">
      <path [attr.d]="path" />
    </svg>
  `,
  styleUrls: ['./connection.component.css'],
})
export class ConnectionComponent {
  @Input() data!: ClassicPreset.Connection<ClassicPreset.Node, ClassicPreset.Node>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() start: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() end: any;
  @Input()
  path!: string;
}
