import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { Logger } from '@services/utils';

@Component({
  selector: 'app-text-area-field',
  templateUrl: './text-area-field.component.html',
  styleUrls: ['./text-area-field.component.scss'],
})
export class TextAreaFieldComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  @Output() textChanged = new EventEmitter<string>();

  value!: string;

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.value = this.data.getDeserializedValue(FieldType.STRING, this.data.value);
  }

  async update() {
    try {
      await this.data.set(this.value);
      this.textChanged.emit(this.value);
    } catch (e) {
      Logger.error(`Failed to save field ${this.data.field.fieldId}: `, e);
      this.alertService.error(`Failed to save field value`);
    }
  }
}
