import { Repository } from './Repository';
import { StructType } from '@services/entities';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable, shareReplay } from 'rxjs';
import { GeneratedStructType } from '../types/generated';
import { StructTypeEndpoints } from '../api';
import { Cache } from '../utils';
import GTInjector from '@services/GTInjector';
import { ResourceService } from '@services/resource.service';
import { DirtyHandling } from '@services/decorators/DirtyHandling';

@Injectable({ providedIn: 'root' })
export class StructTypeRepository extends Repository<StructType> {
  private readonly cache = new Cache<StructType>();

  private allRequest?: Observable<Promise<StructType[]>>;
  private allIdsRequest?: Observable<string[]>;
  private allIds?: string[];

  constructor(private structTypeEndpoints: StructTypeEndpoints) {
    super();
  }

  @DirtyHandling()
  public override async save(entity: StructType, force = false): Promise<void> {
    await lastValueFrom(this.structTypeEndpoints.updateStructType(await entity.serialize(), force));
    (await GTInjector.inject(ResourceService)).update(entity);
  }

  public override async delete(entity: StructType, force = false): Promise<void> {
    await lastValueFrom(this.structTypeEndpoints.deleteStructType(entity.typeId, force));
    (await GTInjector.inject(ResourceService)).delete(entity);
    this.cache.invalidate(entity.typeId);
  }

  public override async create(data: GeneratedStructType): Promise<StructType> {
    const structType = await StructType.deserialize(await lastValueFrom(this.structTypeEndpoints.createStructType(data)));
    this.allIds?.push(structType.typeId);
    (await GTInjector.inject(ResourceService)).update(structType);
    return this.cache.set(structType.typeId, structType, 5);
  }

  public async getAll(): Promise<StructType[]> {
    if (this.cache.allKeysPresent) return Object.values(this.cache.getAll());

    if (this.allRequest !== undefined) {
      return await lastValueFrom(this.allRequest);
    }

    this.allRequest = this.structTypeEndpoints.getAllStructTypes().pipe(
      map((response) =>
        Promise.all(
          response.map(async (structType) => {
            if (this.cache.isValid(structType.typeId)) return this.cache.get(structType.typeId)!.value;
            return await StructType.deserialize(structType);
          }),
        ),
      ),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.allRequest);
    this.cache.setAll(data, (d) => d.typeId, 5);
    this.allRequest = undefined;
    return data;
  }

  public async getAllResources() {
    // TODO: This could be handled by a separate request to the backend.
    return (await this.getAll()).filter((r) => r.isResource);
  }

  public async getAllIds(): Promise<string[]> {
    if (this.allIds !== undefined) {
      return this.allIds;
    }

    if (this.allIdsRequest !== undefined) {
      return await lastValueFrom(this.allIdsRequest);
    }

    this.allIdsRequest = this.structTypeEndpoints.getAllStructTypes().pipe(
      map((response) => response.map((struct) => struct.typeId)),
      shareReplay(1),
    );

    this.allIds = await lastValueFrom(this.allIdsRequest);
    this.allIdsRequest = undefined;

    return this.allIds;
  }

  public override async get(id: string, skipCache: boolean = false): Promise<StructType> {
    if (!skipCache && this.cache.isValid(id)) {
      return this.cache.get(id)!.value;
    }

    if (this.requests[id] !== undefined) {
      return await lastValueFrom(this.requests[id]);
    }

    this.requests[id] = this.structTypeEndpoints.getStructType(id).pipe(
      map(async (response) => this.cache.set(response.typeId, await StructType.deserialize(response), 5)),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.requests[id]);
    delete this.requests[id];
    return data;
  }
}
