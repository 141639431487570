export class Dummy {
  static prefix = `dummy_${Math.random().toString(36).substring(2, 6)}_`;

  static id() {
    return this.prefix + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  static is(identifier: string) {
    return identifier.startsWith(this.prefix);
  }
}
