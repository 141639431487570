<div class="container">
  <button (click)="addStructInstance()" *ngIf="!data.value" class="btn btn-primary">Add</button>

  <div *ngIf="currentResource" class="d-flex align-items-center">
    <app-text-area-field [data]="currentResource.fieldValues['text']!" class="flex-grow-1" />

    <button (click)="openAudioModal()" [ngbPopover]="popoverContent" class="btn ml-2" triggers="hover" type="button">
      <span *ngIf="isAudioFilledIn()" class="bi bi-mic-fill"></span>
      <span *ngIf="!isAudioFilledIn()" class="bi bi-mic"></span>
    </button>
  </div>

  <ng-template #audioModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Audio File</h4>
    </div>
    <div class="modal-body">
      <app-audio-field [data]="currentResource!.fieldValues['audio']!" />
    </div>
    <div class="modal-footer">
      <button (click)="modal.close('Save click')" class="btn btn-primary" type="button">Close</button>
    </div>
  </ng-template>

  <ng-template #popoverContent> Select an audio file<br />{{ currentAudioName }}</ng-template>
</div>
