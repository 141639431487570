/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 */
export interface GeneratedFieldValidation {
  validationType: GeneratedFieldValidation.ValidationTypeEnum;
  validationValue: string;
  fieldId: string;
}
export namespace GeneratedFieldValidation {
  export type ValidationTypeEnum = 'REGEX' | 'MIN_LENGTH' | 'MAX_LENGTH';
  export const ValidationTypeEnum = {
    Regex: 'REGEX' as ValidationTypeEnum,
    MinLength: 'MIN_LENGTH' as ValidationTypeEnum,
    MaxLength: 'MAX_LENGTH' as ValidationTypeEnum,
  };
}
