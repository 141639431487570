<div class="container-fluid p-4">
  <div class="card shadow-sm">
    <div class="card-header">
      <h2>Test requests the game does</h2>
    </div>
    <div class="my-1 px-2">
      <p>This is useful for testing if caching works correctly</p>
      <button (click)="requestDataPackage()" class="btn btn-primary mb-1" type="button">Request data package</button>
      <br />
      <button (click)="requestSomeFile()" class="btn btn-primary mb-1" type="button">Request some file</button>
    </div>
  </div>
</div>
