import { ApiBase } from './ApiBase';
import { Injectable } from '@angular/core';
import { GeneratedTag } from '../types/generated';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TagEndpoints extends ApiBase {
  public getTags(): Observable<GeneratedTag[]> {
    return this.get<GeneratedTag[]>(`/game/${this.gameId}/tags`);
  }

  public getTag(uid: string): Observable<GeneratedTag> {
    return this.get<GeneratedTag>(`/game/${this.gameId}/tags/${uid}`);
  }

  public createTag(tag: GeneratedTag): Observable<GeneratedTag> {
    return this.post<GeneratedTag>(`/game/${this.gameId}/tags`, tag);
  }

  public updateTag(tag: GeneratedTag): Observable<GeneratedTag> {
    return this.put<GeneratedTag>(`/game/${this.gameId}/tags`, tag);
  }

  public deleteTag(tagUid: string): Observable<void> {
    return this.delete<void>(`/game/${this.gameId}/tags/${tagUid}`);
  }
}
