import { ApiBase } from './ApiBase';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneratedDataPackage } from '../types/generated';

@Injectable({ providedIn: 'root' })
export class DataPackageEndpoints extends ApiBase {
  getDataPackage(): Observable<GeneratedDataPackage> {
    return this.get<GeneratedDataPackage>(`/game/${this.gameId}/data/${this.dataPackage}`);
  }
}
