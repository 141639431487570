import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  currentTheme: 'dark' | 'light' = 'light';

  constructor() {}

  loadTheme() {
    this.currentTheme = (localStorage.getItem('theme') ??
      (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')) as 'dark' | 'light';

    //Set the theme based on the user's system preference
    document.documentElement.setAttribute('data-bs-theme', this.currentTheme);
  }

  setTheme(theme: 'dark' | 'light') {
    this.currentTheme = theme;
    document.documentElement.setAttribute('data-bs-theme', theme);
    localStorage.setItem('theme', theme);
  }
}
