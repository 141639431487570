import { ChangeDetectorRef, Component, HostBinding, Input, OnChanges } from '@angular/core';
import { Socket } from 'rete/_types/presets/classic';

@Component({
  template: ``,
  styleUrls: ['./socket.component.css'],
})
export class SocketComponent implements OnChanges {
  @Input() data!: Socket;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() rendered!: any;

  constructor(private cdr: ChangeDetectorRef) {
    this.cdr.detach();
  }

  @HostBinding('title') get title() {
    if (!this.data) return '';
    return this.data.name;
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
    requestAnimationFrame(() => this.rendered());
  }
}
