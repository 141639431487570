import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { Color, Logger } from '@services/utils';

@Component({
  selector: 'app-color-field',
  templateUrl: './color-field.component.html',
  styleUrls: ['./color-field.component.scss'],
})
export class ColorFieldComponent implements OnInit, FieldEditorComponent<Color> {
  @Input({ required: true }) data!: FieldValue;
  @Output() colorChanged: EventEmitter<string> = new EventEmitter<string>();

  value!: Color;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.COLOR, this.data.value);
  }

  async update(newColor: string) {
    try {
      this.value.hex = newColor;
      await this.data.set(this.value.hex);
      this.colorChanged.emit(this.value.hex);
    } catch (e) {
      Logger.error(`Failed to save field ${this.data.field.fieldId}: `, e);
      this.alertService.error(`Failed to save field value`);
    }
  }
}
