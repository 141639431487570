import { EventEmitter } from '@angular/core';

/**
 * A decorator to add an update emitter to a class.
 * This decorator can be used to make a class emit an event when its save method is called.
 */
export function UpdateEmitter() {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function <T extends new (...args: any[]) => object>(constructor: T) {
    return class extends constructor {
      public updateEmitter = new EventEmitter<void>();

      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      constructor(...args: any[]) {
        super(...args);
      }

      // noinspection JSUnusedGlobalSymbols
      public save() {
        this.updateEmitter.emit();
      }
    };
  };
}
