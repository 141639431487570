import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, Observable, of } from 'rxjs';
import { ConfirmationModalComponent } from '../../_components/UI-elements/confirmation-modal/confirmation-modal.component';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  constructor(private modalService: NgbModal) {}

  confirm(message: string): Observable<boolean> {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.message = message;

    return fromPromise(modalRef.result).pipe(
      map(Boolean),
      catchError(() => of(false)),
    );
  }
}
