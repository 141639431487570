import { Component, Input, OnInit } from '@angular/core';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldValue } from '@services/entities/helpers';

@Component({
  selector: 'app-audio-field',
  templateUrl: './audio-field.component.html',
  styleUrls: ['./audio-field.component.scss'],
})
export class AudioFieldComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value!: string;
  name?: string;

  constructor() {}

  ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.AUDIO_REF, this.data.value);
  }

  async changeAudio(event: { name: string; url: string }) {
    this.name = event.name;
    this.value = event.url;
  }
}
