/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 */
export interface GeneratedDataCleanupActionRequest {
  action: GeneratedDataCleanupActionRequest.ActionEnum;
  dataInstanceUid: string;
  fieldId?: string;
  fieldValue?: string;
}
export namespace GeneratedDataCleanupActionRequest {
  export type ActionEnum = 'UNKNOWN' | 'DELETE_INSTANCE' | 'CLEAR_FIELD' | 'MANUAL_FIX';
  export const ActionEnum = {
    Unknown: 'UNKNOWN' as ActionEnum,
    DeleteInstance: 'DELETE_INSTANCE' as ActionEnum,
    ClearField: 'CLEAR_FIELD' as ActionEnum,
    ManualFix: 'MANUAL_FIX' as ActionEnum,
  };
}
