import { Serializable } from '../../types/Serializable';
import { GeneratedResource } from '../../types/generated';

interface NewResource {
  name: string;
  value: string;
}

export class Resource implements Serializable<GeneratedResource> {
  public name: string;
  public value: string;

  public constructor({ name, value }: NewResource) {
    this.name = name;
    this.value = value;
  }

  public static async deserialize(data: GeneratedResource): Promise<Resource> {
    return new Resource(data);
  }

  public async serialize(): Promise<Readonly<GeneratedResource>> {
    return Object.freeze({
      name: this.name,
      value: this.value,
    });
  }
}
