import { Serializable } from '../../types/Serializable';
import { GeneratedSelectTypeOption } from '../../types/generated';

export class SelectTypeOption<T extends string = string> implements Serializable<GeneratedSelectTypeOption> {
  public optionId: T;
  public label: string;

  constructor({ optionId, label }: { optionId: T; label: string }) {
    this.optionId = optionId;
    this.label = label;
  }

  public static async deserialize<T extends string = string>(data: GeneratedSelectTypeOption): Promise<SelectTypeOption<T>> {
    return new SelectTypeOption<T>({
      optionId: data.optionId as T,
      label: data.label,
    });
  }

  public async serialize(): Promise<Readonly<GeneratedSelectTypeOption>> {
    return Object.freeze({
      optionId: String(this.optionId),
      label: this.label,
    });
  }
}
