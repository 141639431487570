/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeneratedStructType } from './structType';
import { GeneratedSelectType } from './selectType';
import { GeneratedVariable } from './variable';
import { GeneratedEnumType } from './enumType';

/**
 *
 */
export interface GeneratedSchema {
  gameId: string;
  structTypes: Array<GeneratedStructType>;
  enumTypes: Array<GeneratedEnumType>;
  selectTypes: Array<GeneratedSelectType>;
  variables: Array<GeneratedVariable>;
}
