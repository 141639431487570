/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 */
export interface GeneratedSchemaUpdateLog {
  logType: GeneratedSchemaUpdateLog.LogTypeEnum;
  message: string;
}
export namespace GeneratedSchemaUpdateLog {
  export type LogTypeEnum = 'ERROR' | 'WARNING' | 'SUCCESS' | 'INFO';
  export const LogTypeEnum = {
    Error: 'ERROR' as LogTypeEnum,
    Warning: 'WARNING' as LogTypeEnum,
    Success: 'SUCCESS' as LogTypeEnum,
    Info: 'INFO' as LogTypeEnum,
  };
}
