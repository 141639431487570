import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NO_AUTH } from '../authorization/token.interceptor';
import { ApiBase } from '@services/api/ApiBase';
import { environment } from '../../../environments/environment';

export interface AuthResponse {
  accessToken: string;
}

@Injectable({ providedIn: 'root' })
export class AuthEndpoints extends ApiBase {
  public override readonly baseUrl = environment.authServerHost;

  login(username: string, password: string) {
    return this.post<AuthResponse>(
      `/login/userpass`,
      { username, password },
      {
        context: new HttpContext().set(NO_AUTH, true),
      },
    );
  }

  logout(): Observable<unknown> {
    // TODO implement
    return of({});
  }
}
