import { Serializable } from '../types/Serializable';

export class Color implements Serializable<string> {
  constructor(hex: string) {
    if (!Color.isValidHex(hex)) {
      throw new Error(`Invalid hex color '${hex}'`);
    }
    this._hex = Color.normalizeHex(hex);
  }

  private _hex: string;

  get hex(): string {
    return this._hex;
  }

  set hex(hex: string) {
    if (!Color.isValidHex(hex)) {
      throw new Error(`Invalid hex color '${hex}'`);
    }

    this._hex = Color.normalizeHex(hex);
  }

  get rgba(): { r: number; g: number; b: number; a: number } {
    const hex = this._hex.slice(1);
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const a = hex.length === 8 ? parseInt(hex.slice(6, 8), 16) : 255;

    return { r, g, b, a };
  }

  set rgba({ r, g, b, a }: { r: number; g: number; b: number; a: number }) {
    this._hex = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}${a.toString(16).padStart(2, '0')}`;
  }

  get rgb(): { r: number; g: number; b: number } {
    const { r, g, b } = this.rgba;
    return { r, g, b };
  }

  set rgb({ r, g, b }: { r: number; g: number; b: number }) {
    this._hex = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}ff`;
  }

  static isValidHex(hex: string): boolean {
    return /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/.test(hex);
  }

  static normalizeHex(hex: string): string {
    const hexBody = hex.slice(1);

    if (hexBody.length === 3 || hexBody.length === 4) {
      const expanded = hexBody
        .split('')
        .map((char) => char.repeat(2))
        .join('');
      return `#${expanded}${hexBody.length === 3 ? 'ff' : ''}`;
    }

    return hex;
  }

  static getContrastColor(color: string): string {
    // Function to calculate the luminance of a color
    const luminance = (r: number, g: number, b: number) => {
      const a = [r, g, b].map((v) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
      });
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };

    // Convert hex color to RGB
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate the luminance
    const lum = luminance(r, g, b);

    // Return black or white based on luminance
    return lum > 0.5 ? 'black' : 'white';
  }

  static getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  serialize() {
    return Promise.resolve(this._hex);
  }
}
