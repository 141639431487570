import { Component, Input, OnInit } from '@angular/core';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldValue } from '@services/entities/helpers';

@Component({
  selector: 'app-image-field',
  templateUrl: './image-field.component.html',
  styleUrls: ['./image-field.component.scss'],
})
export class ImageFieldComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value!: string;

  currentURL = '/assets/images/select_image.png';
  currentName?: string;
  currentMimeType?: string = 'image/png';

  ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.IMAGE_REF, this.data.value);
  }

  changeImage(imageUrl: { name: string; url: string; mimeType: string }) {
    this.currentURL = imageUrl.url;
    this.currentName = imageUrl.name;
    this.currentMimeType = imageUrl.mimeType;
  }
}
