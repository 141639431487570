import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { DataInstance, NodePosition } from '@services/entities';
import { DataInstanceRepository, NodePositionRepository } from '@services/repositories';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
  @Input() uid = '';

  currentActivity?: DataInstance;
  currentActivitySubscription: Subscription | undefined;

  constructor(
    private nodePositionRepository: NodePositionRepository,
    private loadingScreenService: LoadingScreenService,
    private dataInstanceRepository: DataInstanceRepository,
  ) {}

  async ngOnInit() {
    this.currentActivitySubscription = this.nodePositionRepository.currentActivity$.subscribe(async (activity) => {
      await this.loadingScreenService.show(async () => await this.update(activity));
    });
  }

  private async update(newActivity: NodePosition | undefined) {
    if (!newActivity) {
      this.currentActivity = undefined;
      return;
    }

    this.currentActivity = await this.dataInstanceRepository.get(newActivity.dataInstanceUid);
    if (!this.currentActivity) {
      console.warn(`Instance ${newActivity.dataInstanceUid} not found`);
      return;
    }
  }
}
