import { ApiBase } from './ApiBase';
import { Injectable } from '@angular/core';
import { GeneratedVersionResponse } from '../types/generated';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MiscEndpoints extends ApiBase {
  public getVersion(): Observable<GeneratedVersionResponse> {
    return this.get<GeneratedVersionResponse>('/version');
  }
}
