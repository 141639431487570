import { ChangeDetectorRef, Component, HostBinding, Input, OnChanges } from '@angular/core';
import { ActivityNode } from '../nodes';
import { Socket } from 'rete/_types/presets/classic';

@Component({
  templateUrl: './kennis-node.component.html',
  styleUrls: ['./kennis-node.component.css'],
  host: {
    'data-testid': 'node',
  },
})
export class KennisNodeComponent implements OnChanges {
  @Input() data!: ActivityNode;
  @Input() emit!: (data: never) => void;
  @Input() rendered!: () => void;

  outputLabels: Record<string, string> = {};
  outputSockets: Record<string, Socket> = {};
  inputLabels: Record<string, string> = {};
  inputSockets: Record<string, Socket> = {};

  constructor(private cdr: ChangeDetectorRef) {}

  @HostBinding('class.selected') get selected() {
    return this.data.selected;
  }

  ngOnChanges() {
    this.cdr.detectChanges();
    Object.entries(this.data.outputs).forEach(([key, value]) => {
      if (key && value) {
        if (value.label) this.outputLabels[key] = value.label;
        if (value.socket) this.outputSockets[key] = value.socket;
      }
    });

    Object.entries(this.data.inputs).forEach(([key, value]) => {
      if (key && value) {
        if (value.label) this.inputLabels[key] = value.label;
        if (value.socket) this.inputSockets[key] = value.socket;
      }
    });
  }
}
