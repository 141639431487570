import { ApiBase } from './ApiBase';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GeneratedConvertedDuplicatedDateInstances,
  GeneratedDataInstance,
  GeneratedDuplicateDataInstanceRequest,
  GeneratedGetParentResponse,
  GeneratedResource,
} from '../types/generated';

@Injectable({ providedIn: 'root' })
export class DataInstanceEndpoints extends ApiBase {
  public getAllDataInstancesByStructTypeId(structTypeId: string): Observable<GeneratedDataInstance[]> {
    return this.get<GeneratedDataInstance[]>(`/game/${this.gameId}/data/${this.dataPackage}/${structTypeId}`);
  }

  public getDataInstancesAsResourceWithField(structTypeId: string, fieldId: string): Observable<GeneratedResource[]> {
    return this.get<GeneratedResource[]>(`/game/${this.gameId}/data/${this.dataPackage}/${structTypeId}/withField/${fieldId}`);
  }

  public getDataInstanceUidByFieldValue(structTypeId: string, fieldId: string, fieldValue: string): Observable<GeneratedResource> {
    return this.get<GeneratedResource>(`/game/${this.gameId}/data/${this.dataPackage}/${structTypeId}/${fieldId}/${fieldValue}`);
  }

  public getDataInstance(dataInstanceUid: string, structTypeId: string = '_'): Observable<GeneratedDataInstance> {
    // Apparently 'structTypeId' is not required in this url... But if you want to pass it, go ahead
    return this.get<GeneratedDataInstance>(`/game/${this.gameId}/data/${this.dataPackage}/${structTypeId}/${dataInstanceUid}`);
  }

  public getDataInstanceModified(dataInstanceUid: string): Observable<string> {
    return this.get<string>(`/game/${this.gameId}/data/${this.dataPackage}/${dataInstanceUid}/modified`);
  }

  public createDataInstance(dataInstance: GeneratedDataInstance): Observable<GeneratedDataInstance> {
    return this.post<GeneratedDataInstance>(`/game/${this.gameId}/data/${this.dataPackage}/${dataInstance.dataType}`, dataInstance);
  }

  public updateDataInstance(structTypeId: string = '_', dataInstance: GeneratedDataInstance): Observable<GeneratedDataInstance> {
    return this.put<GeneratedDataInstance>(
      `/game/${this.gameId}/data/${this.dataPackage}/${structTypeId}/${dataInstance.uid}`,
      dataInstance,
    );
  }

  public deleteDataInstances(uids: string[], force = false): Observable<void> {
    return this.delete(`/game/${this.gameId}/data/${this.dataPackage}/data-instances`, {
      params: { dataInstanceUids: uids.join(','), force: String(force) },
    });
  }

  public duplicateDataInstances(
    dataInstances: GeneratedDuplicateDataInstanceRequest,
  ): Observable<GeneratedConvertedDuplicatedDateInstances> {
    return this.post<GeneratedConvertedDuplicatedDateInstances>(`/game/${this.gameId}/data/${this.dataPackage}/duplicate`, dataInstances);
  }

  public getDataInstanceParent(dataInstanceUid: string): Observable<GeneratedGetParentResponse> {
    return this.get<GeneratedGetParentResponse>(`/game/${this.gameId}/search/${this.dataPackage}/datainstance/${dataInstanceUid}/parent`);
  }

  public getDataInstanceWithChildWithDatatypeAndFieldValue(
    parentDatatype: string,
    currentDataType: string,
    fieldValue: string,
  ): Observable<GeneratedDataInstance> {
    return this.get<GeneratedDataInstance>(
      `/game/${this.gameId}/search/${this.dataPackage}/parent-datatype/${parentDatatype}/current-datatype/${currentDataType}/fieldValue/${fieldValue}`,
    );
  }
}
