import { Injectable } from '@angular/core';
import { ApiBase } from '../ApiBase';
import { GeneratedEnumType } from '../../types/generated';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnumTypeEndpoints extends ApiBase {
  public getEnumTypes(): Observable<GeneratedEnumType[]> {
    return this.get<GeneratedEnumType[]>(`/game/${this.gameId}/schema/enum-types`);
  }

  public getEnumType(enumTypeId: string): Observable<GeneratedEnumType> {
    return this.get<GeneratedEnumType>(`/game/${this.gameId}/schema/enum-types/${enumTypeId}`);
  }

  public createEnumType(enumType: GeneratedEnumType, force = false): Observable<GeneratedEnumType> {
    return this.post<GeneratedEnumType>(`/game/${this.gameId}/schema/enum-types`, enumType, {
      params: force ? { force: 'true' } : undefined,
    });
  }

  public updateEnumType(updateEnumType: GeneratedEnumType, force = false): Observable<GeneratedEnumType> {
    return this.put<GeneratedEnumType>(`/game/${this.gameId}/schema/enum-types/${updateEnumType.typeId}`, updateEnumType, {
      params: force ? { force: 'true' } : undefined,
    });
  }

  public deleteEnumType(enumTypeId: string, force = false): Observable<void> {
    return this.delete<void>(`/game/${this.gameId}/schema/enum-types/${enumTypeId}`, {
      params: force ? { force: 'true' } : undefined,
    });
  }
}
