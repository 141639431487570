import { Repository } from './Repository';
import { EnumType } from '@services/entities';
import { Injectable } from '@angular/core';
import { EnumTypeEndpoints } from '../api';
import { lastValueFrom, map, Observable, shareReplay } from 'rxjs';
import { GeneratedEnumType } from '../types/generated';
import { Cache } from '../utils';
import { DirtyHandling } from '@services/decorators/DirtyHandling';

@Injectable({ providedIn: 'root' })
export class EnumTypeRepository extends Repository<EnumType> {
  private readonly cache = new Cache<EnumType>();

  private allRequest?: Observable<Promise<EnumType[]>>;
  private allIdsRequest?: Observable<string[]>;
  private allIds?: string[];

  constructor(private enumTypeEndpoints: EnumTypeEndpoints) {
    super();
  }

  @DirtyHandling()
  public override async save(entity: EnumType, force = false): Promise<void> {
    await lastValueFrom(this.enumTypeEndpoints.updateEnumType(await entity.serialize(), force));
  }

  public override async delete(entity: EnumType, force = false): Promise<void> {
    await lastValueFrom(this.enumTypeEndpoints.deleteEnumType(entity.typeId, force));
    this.cache.invalidate(entity.typeId);
  }

  public override async create(data: GeneratedEnumType): Promise<EnumType> {
    const enumType = await EnumType.deserialize(await lastValueFrom(this.enumTypeEndpoints.createEnumType(data)));
    this.allIds?.push(enumType.typeId);
    return this.cache.set(enumType.typeId, enumType, 5);
  }

  public async getAll(): Promise<EnumType[]> {
    if (this.cache.allKeysPresent) return Object.values(this.cache.getAll());

    if (this.allRequest !== undefined) {
      return await lastValueFrom(this.allRequest);
    }

    this.allRequest = this.enumTypeEndpoints.getEnumTypes().pipe(
      map((response) =>
        Promise.all(
          response.map(async (enumType) => {
            if (this.cache.isValid(enumType.typeId)) return this.cache.get(enumType.typeId)!.value;
            return await EnumType.deserialize(enumType);
          }),
        ),
      ),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.allRequest);
    this.cache.setAll(data, (d) => d.typeId, 5);
    this.allRequest = undefined;
    return data;
  }

  public async getAllIds(): Promise<string[]> {
    if (this.allIds !== undefined) {
      return this.allIds;
    }

    if (this.allIdsRequest !== undefined) {
      return await lastValueFrom(this.allIdsRequest);
    }

    this.allIdsRequest = this.enumTypeEndpoints.getEnumTypes().pipe(
      map((response) => response.map((enumType) => enumType.typeId)),
      shareReplay(1),
    );

    this.allIds = await lastValueFrom(this.allIdsRequest);
    this.allIdsRequest = undefined;

    return this.allIds;
  }

  public override async get(id: string, skipCache: boolean = false): Promise<EnumType> {
    if (!skipCache && this.cache.isValid(id)) {
      return this.cache.get(id)!.value;
    }

    if (this.requests[id] !== undefined) {
      return await lastValueFrom(this.requests[id]);
    }

    this.requests[id] = this.enumTypeEndpoints.getEnumType(id).pipe(
      map(async (data) => this.cache.set(data.typeId, await EnumType.deserialize(data), 5)),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.requests[id]);
    delete this.requests[id];
    return data;
  }
}
