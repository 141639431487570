import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { Observable } from 'rxjs';

@Injectable()
export class SavedChangesCheckerGuard implements CanDeactivate<UnsavedChangesCheck> {
  constructor(private confirmationService: ConfirmationModalService) {}

  canDeactivate(component: UnsavedChangesCheck): boolean | Observable<boolean> {
    if (!component.hasNoUnsavedChanges()) {
      return this.confirmationService.confirm('You have unsaved changes. Do you really want to leave?');
    }
    return true;
  }
}

export interface UnsavedChangesCheck {
  hasNoUnsavedChanges(): boolean;
}
