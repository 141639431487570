import { Logger } from '@services/utils';
import { Entity } from '@services/entities';

/**
 * A decorator to handle dirty checking and saving when a method is called
 * on an entity. If the entity is not dirty, the method is not called.
 *
 * TODO: Not very excited about this name, as it could be more clear.
 */
export function DirtyHandling() {
  return function <T extends Entity>(
    _: unknown,
    propertyKey: string,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    descriptor: TypedPropertyDescriptor<(entity: T, ...args: any[]) => Promise<any>>,
  ) {
    const originalMethod = descriptor.value;
    if (!originalMethod) return;

    descriptor.value = async function (entity: T, ...args) {
      const currentHash = await entity.getCurrentValueHash();

      if (currentHash === entity.lastSavedValueHash) {
        Logger.warn(`[DirtyHandling] '${propertyKey}' was called on an entity that is not dirty, skipping call.`);
        return;
      }

      await originalMethod.apply(this, [entity, ...args]);
      entity.lastSavedValueHash = currentHash;
    };
  };
}
