import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldTypes, FieldValue, SelectTypeOption } from '@services/entities/helpers';
import { SelectTypeRepository } from '@services/repositories';
import { Logger } from '@services/utils';

@Component({
  selector: 'app-select-type-selector',
  templateUrl: './select-type-selector.component.html',
  styleUrls: ['./select-type-selector.component.scss'],
})
export class SelectTypeSelectorComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value!: string;
  choices: SelectTypeOption[] = [];

  constructor(
    private alertService: AlertService,
    private selectTypeRepository: SelectTypeRepository,
  ) {}

  async ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.SELECT, this.data.value);

    const selectTypeId = FieldTypes.getDeepestReference(this.data.field.type);
    if (!selectTypeId) throw new Error('Select type not found');
    this.choices = (await this.selectTypeRepository.get(selectTypeId)).options;

    if (!this.value) {
      await this.data.set(
        this.data.field.defaultValue && this.choices.find((s) => s.optionId === this.data.field.defaultValue)
          ? this.data.field.defaultValue
          : this.choices[0].optionId,
      );
    }
  }

  // Update the data instance when the dropdown is changed
  async update() {
    try {
      await this.data.set(this.value);
    } catch (e) {
      Logger.error(e);
      this.alertService.error('Failed to save changes');
    }
  }
}
