import { environment } from '../../../environments/environment';

const logMethods = {
  info: console.log,
  debug: console.log,
  warn: console.warn,
  error: console.error,
};

const logLevels = {
  error: 0,
  warn: 1,
  info: 2,
  debug: 3,
};

export class Logger {
  public readonly scope: string;

  /**
   * Initializes a logger with the given scope
   * @param scope - string describing the scope of the logger
   */
  constructor(scope: string) {
    this.scope = scope;
  }

  /**
   * Retrieves the current log level from local storage, defaulting to {@link logLevels.error} in production and
   * {@link logLevels.debug} in all other environments
   * @returns The current log level
   */
  public static get logLevel() {
    const storageLevel = localStorage.getItem('loglevel');
    if (!storageLevel || !Object.keys(logLevels).includes(storageLevel)) {
      return environment.production ? logLevels.error : logLevels.debug;
    }
    return logLevels[storageLevel as keyof typeof logLevels];
  }

  public static info(...args: unknown[]) {
    if (Logger.logLevel >= logLevels.info) {
      Logger.handle('info', '[INFO]', ...args);
    }
  }

  public static warn(...args: unknown[]) {
    if (Logger.logLevel >= logLevels.warn) {
      Logger.handle('warn', '[WARN]', ...args);
    }
  }

  public static error(...args: unknown[]) {
    if (Logger.logLevel >= logLevels.error) {
      Logger.handle('error', '[ERROR]', ...args);
    }
  }

  public static debug(...args: unknown[]) {
    if (Logger.logLevel >= logLevels.debug) {
      Logger.handle('debug', '[DEBUG]', ...args);
    }
  }

  private static handle(type: keyof typeof logMethods, ...args: unknown[]) {
    logMethods[type](...args);
  }

  public log(...args: unknown[]) {
    Logger.info(`(${this.scope})`, ...args);
  }

  public warn(...args: unknown[]) {
    Logger.warn(`(${this.scope})`, ...args);
  }

  public error(...args: unknown[]) {
    Logger.error(`(${this.scope})`, ...args);
  }

  public debug(...args: unknown[]) {
    Logger.debug(`(${this.scope})`, ...args);
  }
}

if (window !== undefined) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).setLogLevel = function (level: keyof typeof logLevels) {
    if (!Object.keys(logLevels).includes(level)) {
      console.error(`Invalid log level: "${level}", must be one of: ${Object.keys(logLevels).join(', ')}`);
      return;
    }

    localStorage.setItem('loglevel', level);
    console.log(`Log level set to: ${level}`);
  };
}
