import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { firstValueFrom } from 'rxjs';

export const authGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const isLoggedIn = await firstValueFrom(authService.isLoggedIn());
  if (isLoggedIn) return true;
  // URL encode the url
  const encodedUrl = encodeURIComponent(state.url);
  return router.parseUrl('/login?redirectUrl=' + encodedUrl);
};
