import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@services/UI-elements/alert-service';
import { BootstrapClass } from '@services/types/BootstrapClass';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { EnumTypeRepository, SelectTypeRepository, StructTypeRepository, TagRepository } from '@services/repositories';
import { EnumType, SelectType, StructType, Tag } from '@services/entities';
import { Field, SelectTypeOption } from '@services/entities/helpers';
import { ResourceService } from '@services/resource.service';
import { Color, debounce } from '@services/utils';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import {
  GeneratedEnumType,
  GeneratedSchemaUpdateLog,
  GeneratedSelectType,
  GeneratedStructType,
  GeneratedTag,
} from '@services/types/generated';
import { SchemaEndpoints, SchemaUpdate } from '@services/api';
import { NavigationService } from '@services/navigation.service';
import LogTypeEnum = GeneratedSchemaUpdateLog.LogTypeEnum;

@Component({
  selector: 'app-schema-list',
  templateUrl: './schema-list.component.html',
  styleUrls: ['./schema-list.component.scss'],
})
export class SchemaListComponent implements OnInit, OnDestroy {
  schema: Array<StructType | EnumType | SelectType> = []; // The full schema
  activeSchema: Array<StructType | EnumType | SelectType> = []; // The schema the user sees (search filter + type filter)
  searchSchema: Array<StructType | EnumType | SelectType> = []; // The search filtered schema
  filterSchema: Array<StructType | EnumType | SelectType> = []; // The type filtered schema
  selectedTables: Array<StructType | EnumType | SelectType> = []; // An array of the selected tables from the multiselect

  searchString = ''; // The user search string
  resourceStructs: string[] = []; // A list of all resource StructTypes
  structTypes: Record<string, StructType> = {};
  enumTypes: Record<string, EnumType> = {};

  filteredTags: Tag[] = [];
  tags: Tag[] = [];
  isTagUsedForFilter: Record<string, boolean> = {};
  isTagUsedForFilterTableCopy: Record<string, boolean> = {};
  debouncedSearch = debounce(this.search.bind(this));
  protected readonly Color = Color;
  protected readonly GeneratedTag = GeneratedTag;
  private tagSubsciption?: Subscription;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private alertService: AlertService,
    private loadingScreenService: LoadingScreenService,
    private structTypeRepository: StructTypeRepository,
    private enumTypeRepository: EnumTypeRepository,
    private selectTypeRepository: SelectTypeRepository,
    private tagRepository: TagRepository,
    private resourceService: ResourceService,
    private confirmationModalService: ConfirmationModalService,
    private schemaEndpoints: SchemaEndpoints,
    private navigationService: NavigationService,
  ) {}

  /**
   * Initializes the component by loading the schema
   */
  async ngOnInit() {
    await this.loadingScreenService.show(async () => {
      await this.loadSchema();
    });
  }

  async ngOnDestroy() {
    this.tagSubsciption?.unsubscribe();
  }

  /**
   * Loads the schema and maps explicit typing to the Struct, Enum and Select Types
   */
  async loadSchema() {
    const structs: StructType[] = await this.structTypeRepository.getAll();
    structs.forEach((struct) => (this.structTypes[struct.typeId] = struct));
    const enums: EnumType[] = await this.enumTypeRepository.getAll();
    enums.forEach((enumType) => (this.enumTypes[enumType.typeId] = enumType));
    const selects: SelectType[] = await this.selectTypeRepository.getAll();

    this.resourceStructs = Object.keys(this.resourceService.resourceStructs);

    this.schema = [...structs, ...selects, ...enums].sort((a: StructType | EnumType | SelectType, b: StructType | EnumType | SelectType) =>
      a.name.localeCompare(b.name),
    );
    this.searchSchema = this.schema;
    this.filterSchema = this.schema;
    this.activeSchema = this.schema;

    this.tags = await this.tagRepository.getAll();

    this.tagSubsciption = this.tagRepository.cache$.subscribe((tags) => {
      this.tags = tags.filter((tag) => tag.scope === GeneratedTag.ScopeEnum.Model).sort((a, b) => a.name.localeCompare(b.name));
      this.filteredTags = this.tags.slice();
      this.tags.forEach((tag) => {
        if (!Object.keys(this.isTagUsedForFilter).includes(tag.uid)) {
          this.isTagUsedForFilter[tag.uid] = false;
          this.isTagUsedForFilterTableCopy[tag.uid] = false;
        }
      });
    });
  }

  /**
   * Creates a new table based on user input
   * @param type
   * @param name
   */
  async createTable(type: string, name: string) {
    if (!name) {
      alert('Please input a valid name');
      return;
    }

    try {
      switch (type) {
        case 'StructType': {
          const structTag = await this.tagRepository.get('tag_defaultStructTag');
          const structType: StructType = new StructType({
            typeId: this.toPascalCase(name),
            name: name,
            fields: [],
            description: '',
            isResource: false,
            tags: [structTag],
          });
          const structData = await this.structTypeRepository.create(await structType.serialize());
          await this.router.navigate(this.navigationService.getUrlOfDataModelType(structData.typeId, 'StructType'));
          break;
        }

        case 'EnumType': {
          const enumTag = await this.tagRepository.get('tag_defaultEnumTag');
          const enumType: EnumType = new EnumType({
            typeId: this.toPascalCase(name),
            name: name,
            options: [],
            description: '',
            tags: [enumTag],
          });
          const enumData = await this.enumTypeRepository.create(await enumType.serialize());
          await this.router.navigate(this.navigationService.getUrlOfDataModelType(enumData.typeId, 'EnumType'));
          break;
        }

        case 'SelectType': {
          const selectTag = await this.tagRepository.get('tag_defaultSelectTag');
          const selectType: SelectType = new SelectType({
            typeId: this.toPascalCase(name),
            name: name,
            options: [],
            description: '',
            tags: [selectTag],
          });
          const selectData = await this.selectTypeRepository.create(await selectType.serialize());
          await this.router.navigate(this.navigationService.getUrlOfDataModelType(selectData.typeId, 'SelectType'));
          break;
        }

        default:
          console.warn('No selected modal');
          return;
      }
      this.modalService.dismissAll();
    } catch (error) {
      this.handleError(error);
    }
  }

  openTable(event: MouseEvent, table: StructType | EnumType | SelectType) {
    event.preventDefault();
    const url = this.router.createUrlTree(this.navigationService.getUrlOfDataModelType(table.typeId, this.getTableType(table))).toString();
    if (event.ctrlKey) window.open(location.origin + url, '_blank')?.focus();
    else this.router.navigate(this.navigationService.getUrlOfDataModelType(table.typeId, this.getTableType(table))).then();
  }

  /**
   * Searches for tables based on user input
   */
  search() {
    // Filter tables based on table name and search string
    this.searchSchema = this.schema.filter(
      (table: EnumType | StructType | SelectType) => 'name' in table && table.name.toLowerCase().includes(this.searchString.toLowerCase()),
    );

    // Filter tables based on Field name
    if (this.searchString.startsWith('.')) {
      const searchStringNormalized = this.searchString.replace('.', '').toLowerCase();
      const searchSchemaSet = new Set<EnumType | StructType | SelectType>(); // Use a union type for the Set

      for (const table of this.schema) {
        const tableContainsSearchString = (element: Field | SelectTypeOption | string) => {
          let valueToSearch: string;
          if (element instanceof Field) {
            valueToSearch = element.name;
          } else if (element instanceof SelectTypeOption) {
            valueToSearch = element.label;
          } else {
            valueToSearch = element;
          }
          return valueToSearch.toLowerCase().includes(searchStringNormalized);
        };

        let fieldsContainSearchString = false;
        let optionsContainSearchString = false;

        if (table instanceof StructType) {
          fieldsContainSearchString = Object.values(table.fields).some(tableContainsSearchString);
        }
        if (table instanceof SelectType || table instanceof EnumType) {
          optionsContainSearchString = table.options.some(tableContainsSearchString);
        }

        if (fieldsContainSearchString || optionsContainSearchString) {
          searchSchemaSet.add(table);
        }
      }

      this.searchSchema = [...searchSchemaSet];
    }

    // Order activeSchema by alphabet and merge filterSchema and searchSchema
    this.activeSchema = this.filterSchema.filter((table) => this.searchSchema.includes(table)).sort((a, b) => a.name.localeCompare(b.name));
  }

  /**
   * Processes the pasted clipboard data to update schema
   * @param jsonDataString
   */
  async processClipboardData(jsonDataString: string) {
    let schemaUpdateLogs: GeneratedSchemaUpdateLog[] = [];

    try {
      const jsonData = JSON.parse(jsonDataString) as SchemaUpdate[];
      const selectTypes = jsonData.filter((table) => table.type === 'SelectType').map((table) => table.data) as GeneratedSelectType[];
      const structTypes = jsonData.filter((table) => table.type === 'StructType').map((table) => table.data) as GeneratedStructType[];
      const enumTypes = jsonData.filter((table) => table.type === 'EnumType').map((table) => table.data) as GeneratedEnumType[];
      schemaUpdateLogs = await lastValueFrom(this.schemaEndpoints.updateSchema(selectTypes, structTypes, enumTypes));
    } catch (error) {
      this.handleError(error);
      return;
    }

    this.selectedTables = [];
    if (schemaUpdateLogs.some((log) => log.logType === LogTypeEnum.Error)) {
      alert('An error occurred while updating the schema: \n\n' + schemaUpdateLogs.map((log) => log.message).join('\n'));
    } else {
      alert('Succesfully updated the schema: \n\n' + schemaUpdateLogs.map((log) => log.message).join('\n'));
    }
    await this.loadingScreenService.show(async () => {
      await this.loadSchema();
    });
  }

  /**
   * Copies a JSON of tables to the clipboard
   */
  async copyTables(tables: Array<StructType | EnumType | SelectType>) {
    let tableJSON = '';
    if (Object.values(this.isTagUsedForFilterTableCopy).includes(true)) {
      const filteredTables: {
        type: string;
        data: Readonly<GeneratedStructType> | Readonly<GeneratedEnumType> | Readonly<GeneratedSelectType>;
      }[] = [];
      for (const table of tables) {
        if (!table.tags.some((tag) => this.isTagUsedForFilterTableCopy[tag.uid])) {
          if (table instanceof StructType) {
            const structType: StructType = new StructType({
              typeId: table.typeId,
              name: table.name,
              fields: Object.values(table.fields).filter(
                (field) => !field.tags || !field.tags.some((tag) => this.isTagUsedForFilterTableCopy[tag.uid]),
              ),
              description: table.description,
              isResource: table.isResource,
              tags: table.tags.slice(),
            });
            filteredTables.push({ type: 'StructType', data: await structType.serialize() });
          } else if (table instanceof EnumType) {
            const enumType: EnumType = new EnumType({
              typeId: table.typeId,
              name: table.name,
              options: table.options.filter(
                (option) => !this.structTypes[option].tags.some((tag) => this.isTagUsedForFilterTableCopy[tag.uid]),
              ),
              description: table.description,
              isResource: table.isResource,
              tags: table.tags.slice(),
            });
            filteredTables.push({ type: 'EnumType', data: await enumType.serialize() });
          } else {
            filteredTables.push({ type: 'SelectType', data: await table.serialize() });
          }
        }
      }
      tableJSON = JSON.stringify(filteredTables);
    } else {
      const serializedTable = await Promise.all(
        tables.map(async (table) => {
          if (table instanceof StructType) {
            return { type: 'StructType', data: await table.serialize() };
          } else if (table instanceof EnumType) {
            return { type: 'EnumType', data: await table.serialize() };
          } else {
            return { type: 'SelectType', data: await table.serialize() };
          }
        }),
      );
      tableJSON = JSON.stringify(serializedTable);
    }

    navigator.clipboard
      .writeText(tableJSON)
      .then(() => {
        this.alertService.showAlert(`Successfully copied tables to clipboard`, BootstrapClass.SUCCESS);
        console.log('JSON string copied to clipboard successfully!');
      })
      .catch((error) => {
        this.handleError(error);
      });

    this.selectNoFilters();
    this.modalService.dismissAll();
  }

  openModal(content: TemplateRef<NgbModalRef>, options?: NgbModalOptions) {
    this.modalService.dismissAll('Closed before opening new modal');
    this.modalService.open(content, { ariaLabelledBy: 'upload-modal-title', ...options }).result.then();

    // Automatically select the locked tag for exclusion when copying the data-model which is often needed
    this.isTagUsedForFilterTableCopy['tag_defaultLockedTag'] = true;
  }

  /**
   * Turns any string into PascalCase
   * @param str
   */
  toPascalCase(str: string): string {
    return (
      str
        // Replace any non-word characters and underscores with a space
        .replace(/[^a-zA-Z0-9]+/g, ' ')
        // Remove any leading or trailing spaces
        .trim()
        // Convert the first character of each word to uppercase
        .replace(/\w+/g, (word) => word.charAt(0).toUpperCase() + word.substring(1))
        // Remove spaces
        .replace(/\s+/g, '')
    );
  }

  filterTable(tag: Tag) {
    this.isTagUsedForFilter[tag.uid] = !this.isTagUsedForFilter[tag.uid];
    if (Object.values(this.isTagUsedForFilter).every((value) => !value)) {
      this.activeSchema = this.schema.slice();
      return;
    }
    this.activeSchema = this.schema.filter((table) => table.tags.some((tagOfTable) => this.isTagUsedForFilter[tagOfTable.uid]));
  }

  selectFilterForTableCopy(tag: Tag) {
    this.isTagUsedForFilterTableCopy[tag.uid] = !this.isTagUsedForFilterTableCopy[tag.uid];
  }

  filterAll() {
    Object.keys(this.isTagUsedForFilter).forEach((key) => {
      this.isTagUsedForFilter[key] = true;
    });
    this.activeSchema = this.schema.filter((table) => table.tags.some((tagOfTable) => this.isTagUsedForFilter[tagOfTable.uid]));
  }

  selectAllFilters() {
    Object.keys(this.isTagUsedForFilterTableCopy).forEach((key) => {
      this.isTagUsedForFilterTableCopy[key] = true;
    });
  }

  filterNone() {
    Object.keys(this.isTagUsedForFilter).forEach((key) => {
      this.isTagUsedForFilter[key] = false;
    });
    this.activeSchema = this.schema.filter((table) => table.tags.length === 0);
  }

  selectNoFilters() {
    Object.keys(this.isTagUsedForFilterTableCopy).forEach((key) => {
      this.isTagUsedForFilterTableCopy[key] = false;
    });
  }

  /**
   * Handles multiselect checkbox event
   * @param table
   */
  onCheckBoxClick(table: StructType | EnumType | SelectType) {
    if (!this.selectedTables.includes(table)) {
      this.selectedTables.push(table);
    } else {
      this.selectedTables = this.selectedTables.filter((t) => t.typeId !== table.typeId);
    }
  }

  /**
   * Handles multiselect select-all event
   */
  onSelectAllClick() {
    if (this.selectedTables.length === this.activeSchema.length) {
      this.selectedTables = [];
    } else {
      this.selectedTables = this.activeSchema;
    }
  }

  /**
   * Handles any error
   * @param error
   */
  handleError(error: unknown) {
    console.error('An error occurred:', error);

    // Handle HTTP errors
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        alert('No Internet Connection\n\nPlease check your network connection.');
        this.alertService.showAlert('No Internet Connection', BootstrapClass.DANGER);
      } else {
        // Handle Http Error (error.status === 403, 404...)
        alert(`Server Error: ${error.status}\n\n${error.error.message}`);
        this.alertService.showAlert('Server Error', BootstrapClass.DANGER);
      }
    } else if (error instanceof TypeError) {
      // Handle client-side or network error
      alert('Error: A network error occurred\n\nPlease try again later.');
      this.alertService.showAlert('Error: A network error occurred', BootstrapClass.DANGER);
    } else if (error instanceof SyntaxError) {
      // Handle Syntax Errors
      alert(`Syntax Error: \n\n${error.message}`);
      this.alertService.showAlert('Syntax Error', BootstrapClass.DANGER);
    } else if (error instanceof Error) {
      // Handle generic error conditions
      alert(`Error\n\n${error.message}`);
      this.alertService.showAlert('Error', BootstrapClass.DANGER);
    } else {
      // Handle unknown errors
      alert('Unknown Error\n\nAn unknown error occurred. Please contact support.');
      this.alertService.showAlert('Unknown Error', BootstrapClass.DANGER);
    }
  }

  /**
   * Delete multiple tables
   */
  async deleteTables() {
    let alertString = 'Are you sure you want to delete:\n\n';

    alertString += this.selectedTables
      .map((table) => {
        return `• ${table.name}`;
      })
      .join('\n');

    if (!(await firstValueFrom(this.confirmationModalService.confirm(alertString)))) {
      return;
    }

    for (const table of this.selectedTables) {
      if (table instanceof StructType) {
        try {
          await this.structTypeRepository.delete(table);
          this.activeSchema = this.activeSchema.filter((t) => !(t.typeId === table.typeId && t instanceof StructType));
          this.schema = this.schema.filter((t) => !(t.typeId === table.typeId && t instanceof StructType));
        } catch (error) {
          console.log(error);
          this.alertService.showAlert(`Something went wrong while trying to delete ${table.name}`, BootstrapClass.DANGER);
          if (error instanceof HttpErrorResponse) {
            alert(`${error.error.error}: ${error.error.message}`);
          }
        }
      } else if (table instanceof EnumType) {
        try {
          await this.enumTypeRepository.delete(table);
          this.activeSchema = this.activeSchema.filter((t) => !(t.typeId === table.typeId && t instanceof EnumType));
          this.schema = this.schema.filter((t) => !(t.typeId === table.typeId && t instanceof EnumType));
        } catch (error) {
          console.log(error);
          this.alertService.showAlert(`Something went wrong while trying to delete ${table.name}`, BootstrapClass.DANGER);
          if (error instanceof HttpErrorResponse) {
            alert(`${error.error.error}: ${error.error.message}`);
          }
        }
      } else {
        try {
          await this.selectTypeRepository.delete(table);
          this.activeSchema = this.activeSchema.filter((t) => !(t.typeId === table.typeId && t instanceof SelectType));
          this.schema = this.schema.filter((t) => !(t.typeId === table.typeId && t instanceof SelectType));
        } catch (error) {
          console.log(error);
          this.alertService.showAlert(`Something went wrong while trying to delete ${table.name}`, BootstrapClass.DANGER);
          if (error instanceof HttpErrorResponse) {
            alert(`${error.error.error}: ${error.error.message}`);
          }
        }
      }
    }
  }

  private getTableType(table: StructType | EnumType | SelectType): 'StructType' | 'EnumType' | 'SelectType' {
    if (Object.keys(this.structTypes).includes(table.typeId)) return 'StructType';
    if (Object.keys(this.enumTypes).includes(table.typeId)) return 'EnumType';
    return 'SelectType';
  }
}
