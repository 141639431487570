<div class="resource-title">
  <h1 [ngbPopover]="enumType?.description" class="text-center text-white pb-2 drop-shadow" id="{{ enumTypeId }}-header" triggers="hover">
    {{ enumType?.name }}
  </h1>
  <select [(ngModel)]="newEnumType" class="form-control w-fit">
    <option *ngFor="let structType of possibleStructTypes" [value]="structType">{{ structType }}</option>
  </select>
  <button (click)="createEnumTypeInstance()" class="btn btn-primary" id="create-{{ enumTypeId }}-button">
    <i class="bi bi-plus-circle"></i>
  </button>
</div>
<app-instances-list
  (bulkTagSelectedEmitter)="onBulkTagSelected($event)"
  (deleteInstanceEmitter)="deleteEnumTypeInstance($event)"
  (downloadInstanceEmitter)="downloadEnumTypeInstance($event)"
  (duplicateInstanceEmitter)="duplicateEnumTypeInstance($event)"
  (selectInstanceEmitter)="openEnumTypeInstance($event)"
  (tagSelectedEmitter)="onTagSelected($event)"
  *ngIf="!loading && enumTypeInstances.length > 0"
  [allInstances]="enumTypeInstances"
  [allTags]="allTags"
  [listType]="enumTypeId"
  [tagsPerInstance]="tagsPerEnumTypeInstance"
/>

<ng-template #duplicationCompleteModal let-modal>
  <app-duplication-complete-modal
    (closeModal)="modalService.dismissAll()"
    (openInstance)="openEnumTypeInstance(newEnumTypeInstance!)"
    [info]="{ name: enumTypeInstanceName(newEnumTypeInstance!) }"
  />
</ng-template>
