<div class="container mt-2 pb-2">
  <div class="card">
    <a
      *ngIf="environment.playGameUrl && mapUid"
      class="btn btn-primary"
      href="{{ environment.playGameUrl }}?offline=1&map={{ mapUid }}"
      target="_blank"
    >
      <i class="bi bi-play-circle"></i> Play from current map
    </a>
    <app-struct-instance-editor *ngIf="mapInstance" [data]="mapInstance" />
  </div>
</div>
