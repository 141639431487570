<div *ngIf="isList && value" class="d-flex flex-column gap-1">
  <div *ngFor="let _ of value; let i = index">
    <div class="d-flex">
      <select
        (ngModelChange)="saveUpdates()"
        *ngIf="!loading"
        [(ngModel)]="value[i]"
        class="form-select"
        id="list-instance-selector"
        name="dropdownList"
      >
        <option *ngFor="let choice of choices" [value]="choice.optionId">
          {{ choice.label }}
        </option>
      </select>

      <span *ngIf="loading" class="mx-2 w-100">
        <i class="fas fa-refresh fa-spin"></i>
      </span>

      <button (click)="reloadChoices()" *ngIf="showReloadOptionButton" [disabled]="loading" class="btn" id="reload-list-dropdown-button">
        <i class="bi-arrow-repeat"></i>
      </button>

      <button
        (click)="onViewStruct(i)"
        *ngIf="value[i].startsWith('di_')"
        class="btn btn-primary mx-1"
        id="view-list-dropdown-struct-button"
      >
        <i class="bi-box-arrow-up-right"></i>
      </button>

      <button
        (click)="onAddInstanceOfStruct(i)"
        *ngIf="!loading && structType && structType !== 'ClickTarget' && structType !== 'Draggable'"
        class="btn btn-primary"
        id="add-struct-from-list-dropdown-button"
      >
        <i class="bi-plus-circle"></i>
      </button>

      <button (click)="removeResourceSelector(i)" class="btn btn-danger" id="delete-list-struct-instance-button">
        <span class="bi bi-trash"></span>
      </button>
    </div>
  </div>

  <button (click)="addResourceSelector()" *ngIf="isList" class="btn btn-primary btn-sm w-fit" id="add-resource-selector-button">Add</button>
</div>

<div *ngIf="!isList && value" class="d-flex">
  <select (ngModelChange)="saveUpdates()" *ngIf="!loading" [(ngModel)]="value[0]" class="form-select" id="nonListDropdown" name="dropdown">
    <option *ngFor="let choice of choices" [value]="choice.optionId">
      {{ choice.label }}
    </option>
  </select>

  <span *ngIf="loading" class="mx-2 w-100">
    <i class="fas fa-refresh fa-spin"></i>
  </span>

  <button (click)="reloadChoices()" *ngIf="showReloadOptionButton" [disabled]="loading" class="btn" id="reload-dropdown-button">
    <i class="bi-arrow-repeat"></i>
  </button>

  <button
    (click)="onViewStruct(0)"
    *ngIf="value[0] && value[0].startsWith('di_')"
    class="btn btn-primary mx-1"
    id="view-dropdown-struct-button"
  >
    <i class="bi-box-arrow-up-right"></i>
  </button>

  <button (click)="onAddInstanceOfStruct(0)" *ngIf="!loading && structType" class="btn btn-primary" id="add-struct-from-dropdown-button">
    <i class="bi-plus-circle"></i>
  </button>
</div>
