import { Serializable } from '../../types/Serializable';
import { GeneratedFieldValidation } from '../../types/generated';

interface NewFieldValidation {
  validationType: GeneratedFieldValidation.ValidationTypeEnum;
  validationValue: string;
  fieldId: string;
}

export class FieldValidation implements Serializable<GeneratedFieldValidation> {
  public static readonly fieldValidationsPerType: Record<string, GeneratedFieldValidation.ValidationTypeEnum[]> = {
    string: [
      GeneratedFieldValidation.ValidationTypeEnum.Regex,
      GeneratedFieldValidation.ValidationTypeEnum.MaxLength,
      GeneratedFieldValidation.ValidationTypeEnum.MinLength,
    ],
    number: [],
    boolean: [],
  };
  public static readonly fieldValidationWarnings: Record<GeneratedFieldValidation.ValidationTypeEnum, string> = {
    REGEX: 'The value must match the regular expression: %VAR%',
    MIN_LENGTH: 'The value must be at least %VAR% characters long.',
    MAX_LENGTH: 'The value must be at most %VAR% characters long.',
  };
  public validationType: GeneratedFieldValidation.ValidationTypeEnum;
  public validationValue: string;
  public fieldId: string;

  public constructor({ validationType, validationValue, fieldId }: NewFieldValidation) {
    this.validationType = validationType;
    this.validationValue = validationValue;
    this.fieldId = fieldId;
  }

  public static async deserialize(data: GeneratedFieldValidation): Promise<FieldValidation> {
    return new FieldValidation(data);
  }

  public async serialize(): Promise<Readonly<GeneratedFieldValidation>> {
    return Object.freeze({
      validationType: this.validationType,
      validationValue: this.validationValue,
      fieldId: this.fieldId,
    });
  }

  public getFieldValidationWarning(): string {
    return FieldValidation.fieldValidationWarnings[this.validationType].replace('%VAR%', this.validationValue);
  }
}
