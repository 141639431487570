import { Field } from './Field';
import { Color, Dummy, Logger, Try, Vector2, Vector3 } from '../../utils';
import { GeneratedFieldValue } from '../../types/generated';
import { StructType } from '../StructType';
import { AutoSave } from '../../decorators/AutoSave';
import { UpdateEmitter } from '../../decorators/UpdateEmitter';
import { Serializable } from '../../types/Serializable';
import GTInjector from '../../GTInjector';
import { DataInstanceRepository, SelectTypeRepository } from '@services/repositories';
import { Identifiable } from '../../types/Identifiable';
import { ShouldPersist } from '../../types/ShouldPersist';
import { FieldType, FieldTypes } from '@services/entities/helpers/FieldTypes';
import { Entity } from '@services/entities';
import { EventEmitter } from '@angular/core';
import { isNaN } from 'lodash';

export interface NewFieldValue {
  field: Field;
  value: string;
  dataInstanceUid: string;
}

export type DeserializedFieldValueType = string | number | boolean | Vector2 | Vector3 | Color | Array<DeserializedFieldValueType>;

@UpdateEmitter()
@AutoSave({
  ignoreKeys: ['onChange'],
})
export class FieldValue implements Serializable<GeneratedFieldValue>, Identifiable, ShouldPersist {
  public readonly field: Field;
  public shouldPersist = false;

  public readonly onChange = new EventEmitter<{ value: string }>();

  constructor({ field, value, dataInstanceUid }: NewFieldValue) {
    this.field = field;
    this._value = value;
    this._dataInstanceUid = dataInstanceUid;
  }

  private _dataInstanceUid: string;

  public get dataInstanceUid() {
    return this._dataInstanceUid;
  }

  /**
   * @deprecated This is meant to be used to swap out dummy identifiers for real ones. You will rarely have to use this.
   */
  public set dataInstanceUid(dataInstanceUid: string) {
    if (!Dummy.is(dataInstanceUid)) {
      throw new Error('Cannot change the dataInstanceUid of a non-dummy entity');
    }

    this._dataInstanceUid = dataInstanceUid;
  }

  public get identifier() {
    return `${this.field.fieldId}#${this.dataInstanceUid}`;
  }

  private _value: string;

  get value() {
    return this._value;
  }

  public static async deserialize(data: GeneratedFieldValue, structType: StructType, dataInstanceUid: string): Promise<FieldValue> {
    const fv = new FieldValue({
      field: structType.fields[data.field],
      value: data.value,
      dataInstanceUid: dataInstanceUid,
    });

    fv.shouldPersist = true;

    return fv;
  }

  /**
   * Method to check if the value is valid for a given field type. This should be done before creating a new FieldValue
   * as the constructor will not check if the value is valid.
   */
  public static async validate(fieldValueType: string, value: DeserializedFieldValueType): Promise<boolean> {
    switch (fieldValueType) {
      case FieldType.INT:
      case FieldType.FLOAT: {
        return typeof value === 'number' || (typeof value === 'string' && !isNaN(Number(value)));
      }
      case FieldType.VECTOR2:
        return value instanceof Vector2 || (typeof value === 'string' && Vector2.isValidString(value));
      case FieldType.VECTOR3:
        return value instanceof Vector3 || (typeof value === 'string' && Vector3.isValidString(value));
      case FieldType.COLOR: {
        return value instanceof Color || (typeof value === 'string' && Color.isValidHex(value));
      }
      case FieldType.BOOLEAN:
        return typeof value === 'boolean' || (typeof value === 'string' && (value === 'true' || value === 'false'));
      case FieldType.STRING:
      case FieldType.ICON:
      case FieldType.IMAGE_REF:
      case FieldType.AUDIO_REF:
      case FieldType.VIDEO_REF:
      case FieldType.FILE_REF:
      case FieldType.VARIABLE_REF:
        return typeof value === 'string';
    }

    if (FieldTypes.isDataInstanceReferenceType(fieldValueType)) {
      return true;
    }

    if (await FieldTypes.isSelectTypeValid(fieldValueType)) {
      const selectTypeId = FieldTypes.getReferencedTypeId(fieldValueType);

      if (!selectTypeId) {
        Logger.warn(`Failed to get select type id for ${fieldValueType}`);
        return false;
      }

      const selectTypeRepository = await GTInjector.inject(SelectTypeRepository);
      const selectType = await selectTypeRepository.get(selectTypeId);

      if (!selectType) {
        Logger.warn(`Failed to get select type for ${fieldValueType}`);
        return false;
      }

      return selectType.options.some((o) => o.optionId === value);
    }

    if (FieldTypes.isListType(fieldValueType)) {
      if (typeof value === 'string') value = JSON.parse(value as string);

      if (!(value instanceof Array)) {
        Logger.warn(`Value ${value} is not an array`);
        return false;
      }

      // TODO: This will break when we add recursive list support.

      const type = FieldTypes.getReferencedTypeId(fieldValueType);
      if (!type) {
        Logger.error(`Failed to get list type id for ${fieldValueType}`);
        return false;
      }

      return (await Promise.all(value.map((item) => FieldValue.validate(type, item)))).every((result) => result === true);
    }

    return false;
  }

  public static async serializeValue(fieldType: string, value: DeserializedFieldValueType): Promise<string> {
    // For these field types, we sometimes need to serialize the value to a string before setting it
    switch (fieldType) {
      case FieldType.VECTOR2:
        if (value instanceof Vector2) return await value.serialize();
        else return value.toString();
      case FieldType.VECTOR3:
        if (value instanceof Vector3) return await value.serialize();
        else return value.toString();
      case FieldType.COLOR: {
        if (value instanceof Color) return await value.serialize();
        else return value.toString();
      }
    }

    if (value instanceof Array) {
      return JSON.stringify(await Promise.all(value.map((v) => FieldValue.serializeValue(fieldType, v))));
    }

    // For the rest of the values where we don't need to do anything special, we can just call .toString() and it should be fine
    return value.toString();
  }

  public async validate(): Promise<boolean> {
    return FieldValue.validate(this.field.type, this.value);
  }

  /**
   * Method to set the value of this FieldValue. it will check to make sure the new value is valid for the field type.
   * @throws Error if the value is not valid for the field type
   */
  public async set(value: DeserializedFieldValueType | Entity) {
    const _value = value instanceof Entity ? await value.identifier : value;

    if (!(await FieldValue.validate(this.field.type, _value))) {
      throw new Error(`Value '${_value}' is not valid for type '${this.field.type}' (${this.field.fieldId})`);
    }

    if (value instanceof Entity && 'shouldPersist' in value && typeof value.shouldPersist === 'boolean') {
      value.shouldPersist = true;
    }

    this.shouldPersist = true;
    this._value = await this.serializeValue(_value);
    this.onChange.next({
      value: this._value,
    });
  }

  public async serialize(): Promise<Readonly<GeneratedFieldValue>> {
    return Object.freeze({
      // We don't use the serialized version of field as the API expects the uid
      field: this.field.fieldId,
      value: this.value,
    });
  }

  public getDeserializedValue(asType: FieldType.INT | FieldType.FLOAT, value: string): number;

  public getDeserializedValue(
    asType:
      | FieldType.STRING
      | FieldType.ICON
      | FieldType.IMAGE_REF
      | FieldType.AUDIO_REF
      | FieldType.VIDEO_REF
      | FieldType.FILE_REF
      | FieldType.VARIABLE_REF
      | FieldType.STRUCT
      | FieldType.STRUCT_REF
      | FieldType.ENUM
      | FieldType.ENUM_REF
      | FieldType.SELECT,
    value: string,
  ): string;

  public getDeserializedValue(asType: FieldType.VECTOR2, value: string): Vector2;

  public getDeserializedValue(asType: FieldType.VECTOR3, value: string): Vector3;

  public getDeserializedValue(asType: FieldType.COLOR, value: string): Color;

  public getDeserializedValue(asType: FieldType.BOOLEAN, value: string): boolean;

  public getDeserializedValue(asType: FieldType.LIST, value: string): Array<DeserializedFieldValueType>;

  // For some reason typing breaks without this overload here
  public getDeserializedValue(asType: FieldType, value: string): DeserializedFieldValueType;

  public getDeserializedValue(asType: FieldType, value: string): DeserializedFieldValueType {
    switch (asType) {
      case FieldType.COLOR:
        return new Color(value);
      case FieldType.VECTOR2: {
        return new Vector2(value);
      }
      case FieldType.VECTOR3: {
        return new Vector3(value);
      }
      case FieldType.INT:
      case FieldType.FLOAT:
        return Number(value);
      case FieldType.BOOLEAN:
        return value === 'true';
      case FieldType.LIST: {
        const referenceTypeId = FieldTypes.getReferencedTypeId(this.field.type);
        if (!referenceTypeId) {
          throw new Error(`Failed to get list type id for ${this.field.type}`);
        }

        const type = FieldTypes.getFieldType(referenceTypeId);
        return (JSON.parse(value) as string[]).map((v) => this.getDeserializedValue(type, v));
      }
      case FieldType.STRING:
      case FieldType.ICON:
      case FieldType.IMAGE_REF:
      case FieldType.AUDIO_REF:
      case FieldType.VIDEO_REF:
      case FieldType.FILE_REF:
      case FieldType.VARIABLE_REF:
      case FieldType.STRUCT:
      case FieldType.STRUCT_REF:
      case FieldType.ENUM:
      case FieldType.ENUM_REF:
      case FieldType.SELECT:
        return value;
    }
  }

  /**
   * Load all data instances referenced by this field value.
   * If the field is a list, it will load all referenced data instances.
   */
  public async loadDataInstances(): Promise<void> {
    const isList = FieldTypes.isListType(this.field.type);

    if (!FieldTypes.isDataInstanceReferenceType(this.field.type) && !isList) {
      return;
    }

    const referenced = FieldTypes.getReferencedTypeId(this.field.type);
    if (!referenced) {
      return;
    }

    // If it is a reference to a mission info or module, we do not need to load it.
    // Because then we would load another mission or module.
    if (referenced === 'MissionInfo' || referenced === 'Module') {
      return;
    }

    const dataInstanceUids: string[] = isList
      ? (this.getDeserializedValue(FieldType.LIST, this.value) as string[])
      : [this.getDeserializedValue(FieldType.STRING, this.value) as string];

    const dataInstanceRepository = await GTInjector.inject(DataInstanceRepository);
    await Promise.all(
      dataInstanceUids.map(async (dataInstanceUid) => {
        // This instance is already loaded, so we can safely return.
        if (dataInstanceRepository.isCached(dataInstanceUid)) return;

        const dataInstance = await Try(async () => dataInstanceRepository.get(dataInstanceUid));
        if (!dataInstance) return;

        return await Promise.all(Object.values(dataInstance.fieldValues).map((fv) => fv?.loadDataInstances()));
      }),
    );
  }

  private async serializeValue(value: DeserializedFieldValueType): Promise<string> {
    return FieldValue.serializeValue(this.field.type, value);
  }
}
