import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataInstance, NodePosition } from '@services/entities';
import { NodePositionRepository } from '@services/repositories';

@Component({
  selector: 'app-flowchart-node',
  templateUrl: './flowchart-node.component.html',
  styleUrls: ['./flowchart-node.component.scss'],
})
export class FlowchartNodeComponent implements OnInit, OnDestroy {
  @Input() node?: NodePosition;
  @Output() deleteNode: EventEmitter<string> = new EventEmitter<string>();

  nodeInstance?: DataInstance;

  private currentNodeSubscription?: Subscription;

  constructor(private nodePositionRepository: NodePositionRepository) {}

  ngOnInit() {
    this.currentNodeSubscription = this.nodePositionRepository.currentActivity$.subscribe((node) => {
      this.update(node).then();
    });
  }

  deleteKennisNode() {
    if (!this.node) return;
    this.deleteNode.emit(this.node.dataInstanceUid);
  }

  ngOnDestroy() {
    this.currentNodeSubscription?.unsubscribe();
  }

  private async update(newNode: NodePosition | undefined) {
    this.node = newNode;
    if (!this.node) return;

    this.nodeInstance = await this.node.getDataInstance();
  }
}
