<div class="card-body">
  <div class="row space">
    <div class="col-6">
      <h4 class="card-title">{{ 'Background' | prettifyName }}</h4>
    </div>
    <div class="col-6">
      <h4 class="card-title">{{ 'Character' | prettifyName }}</h4>
    </div>
  </div>
  <div class="row space">
    <div class="col-6">
      <img alt="Background" class="img-fluid" height="200" ngSrc="{{ backgroundUrl }}" width="350" />
    </div>
    <div class="col-6">
      <img alt="character" class="img-fluid" height="200" ngSrc="{{ characterUrl }}" width="350" />
    </div>
  </div>
  <div class="row space">
    <div class="col-6">
      <app-resource-selector-field
        (selectionChanged)="changeBackground($event)"
        *ngIf="backgroundField"
        [choices]="backgrounds"
        [data]="backgroundField"
      />
    </div>
    <div class="col-6">
      <app-resource-selector-field
        (selectionChanged)="changeCharacter($event)"
        *ngIf="characterField"
        [choices]="characters"
        [data]="characterField"
      />
    </div>
  </div>
</div>
