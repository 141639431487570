import { Entity } from './Entity';
import { AutoSave } from '../decorators/AutoSave';
import { Vector2 } from '../utils';
import { GeneratedNodePosition } from '../types/generated';
import GTInjector from '../GTInjector';
import { DataInstanceRepository, NodePositionRepository } from '@services/repositories';
import { NodeCategory } from '../types/NodeCategory';

interface NewNodePosition {
  dataInstanceUid: string;
  position: Vector2;

  name: string;
  type: string;
  nodeCategory: NodeCategory;
}

@AutoSave()
export class NodePosition extends Entity {
  public readonly dataInstanceUid: string;

  public name: string;
  public type: string;
  public nodeCategory: NodeCategory;

  public position: Vector2;

  constructor(data: NewNodePosition) {
    super();
    this.dataInstanceUid = data.dataInstanceUid;
    this.position = data.position;

    this.name = data.name;
    this.type = data.type;
    this.nodeCategory = data.nodeCategory;
  }

  public override get identifier() {
    return this.dataInstanceUid;
  }

  public static async deserialize(data: GeneratedNodePosition, category: NodeCategory): Promise<NodePosition> {
    const dataInstance = await (await GTInjector.inject(DataInstanceRepository)).get(data.dataInstanceUid);

    const nodePosition = new NodePosition({
      dataInstanceUid: data.dataInstanceUid,
      position: new Vector2([data.positionX, data.positionY]),
      name: dataInstance.getName(),
      type: dataInstance.dataType,
      nodeCategory: category,
    });

    await nodePosition.updateLastSavedValueHash();
    return nodePosition;
  }

  /**
   * @deprecated This method should be rarely used by a developer because @AutoSave() should handle most cases. If you
   * find any cases where auto saving does not work, please open a bug report so it can be fixed.
   */
  public async save() {
    return (await GTInjector.inject(NodePositionRepository)).save(this);
  }

  public async serialize(): Promise<Readonly<GeneratedNodePosition>> {
    return Object.freeze({
      dataInstanceUid: this.dataInstanceUid,
      positionX: this.position.x,
      positionY: this.position.y,
    });
  }

  public async getDataInstance() {
    return (await GTInjector.inject(DataInstanceRepository)).get(this.dataInstanceUid);
  }
}
