type Tried<T> = T extends Promise<infer U> ? Promise<U | null> : T | null;

/**
 * Calls a function and returns the result, or null if it throws an error.
 * If the function returns a promise, it is resolved and the result is returned,
 * or null if the promise rejects.
 *
 * @example Synchronously
 *
 * const result = Try(() => {
 *   return 1 + 2;
 * });
 *
 * if (result !== null) {
 *   console.log(result); // 3
 * }
 *
 * @example Asynchronously
 *
 * const result = await Try(() => {
 *   return new Promise<number>((resolve) => {
 *     setTimeout(() => resolve(1 + 2), 100);
 *   });
 * });
 *
 * if (result !== null) {
 *   console.log(result); // 3
 * }
 *
 * @example With a callback
 *
 * const result = Try(() => {
 *   throw new Error('Error!');
 * }, (e) => {
 *   console.log('Error: ', e); // Error: Error!
 *   return 42;
 * });
 *
 * console.log(result); // 42
 *
 * @example With a default
 *
 * const result = Try(() => {
 *   throw new Error('Error!');
 * }, 42);
 *
 * console.log(result); // 42
 *
 * @param fn The function to run.
 * @param ct An optional callback to run if an error occurs. This callback receives the error as its only argument.
 * @returns The result of calling the function, or null if an error occurs.
 */
export function Try<T, K = Error, V = T>(fn: () => T, ct?: ((e: K) => V) | T | V): Tried<T | V> {
  try {
    const d = fn();

    if (d instanceof Promise) {
      return d
        .then((v) => v)
        .catch((e) => {
          if (ct instanceof Function) {
            return ct(e) ?? null;
          }
          return ct ?? null;
        }) as Tried<T | V>;
    }

    return d as Tried<T>;
  } catch (e) {
    if (ct instanceof Function) {
      return (ct(e as K) ?? null) as Tried<T | V>;
    }

    return (ct ?? null) as Tried<T>;
  }
}
