import { ApiBase } from './ApiBase';
import { Injectable } from '@angular/core';
import { GeneratedNodePosition } from '../types/generated';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NodePositionEndpoints extends ApiBase {
  public getNodePosition(dataInstanceUid: string): Observable<GeneratedNodePosition> {
    return this.get<GeneratedNodePosition>(`/game/${this.gameId}/data/node-position/${dataInstanceUid}`);
  }

  public updateNodePosition(dataInstanceUid: string, position: GeneratedNodePosition): Observable<GeneratedNodePosition> {
    return this.put<GeneratedNodePosition>(`/game/${this.gameId}/data/node-position/${dataInstanceUid}`, position);
  }

  public deleteNodePosition(dataInstanceUid: string): Observable<void> {
    return this.delete<void>(`/game/${this.gameId}/data/node-position/${dataInstanceUid}`);
  }
}
