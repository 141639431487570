import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-duplication-complete-modal',
  standalone: true,
  imports: [],
  templateUrl: './duplication-complete-modal.component.html',
})
export class DuplicationCompleteModalComponent {
  @Input({ required: true }) info!: { name: string };

  @Output() openInstance = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();

  clickOpenInstance() {
    this.openInstance.emit();
    this.close();
  }

  close() {
    this.closeModal.emit();
  }
}
