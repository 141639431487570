import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { firstValueFrom, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { VariableType } from '@services/types/VariableType';
import { TagRepository, VariableRepository } from '@services/repositories';
import { Tag, Variable } from '@services/entities';
import { isVariable, ListInstance } from '@services/utils/ListInstance';
import { GeneratedTag } from '@services/types/generated';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-variable-list',
  templateUrl: './variable-list.component.html',
  styleUrls: ['./variable-list.component.scss'],
})
export class VariableListComponent implements OnInit, OnDestroy {
  variables: Variable[] = [];
  allTags: Tag[] = [];
  tagsPerVariable: Record<string, Tag[]> = {};
  structTypeDescription =
    'Variables are a way to save and use the same data throughout the whole game. For example, when needing the answer of a question in multiple modules, you can use a variable to save the answer in one place and use it in another.';
  loading = false;

  protected readonly environment = environment;
  private tagSubscription?: Subscription;

  constructor(
    private router: Router,
    private confirmService: ConfirmationModalService,
    private loadingScreenService: LoadingScreenService,
    private variableRepository: VariableRepository,
    private tagRepository: TagRepository,
    private navigationService: NavigationService,
  ) {}

  async ngOnInit() {
    await this.loadingScreenService.show(async () => {
      await this.loadVariables();
    });
  }

  ngOnDestroy() {
    this.tagSubscription?.unsubscribe();
  }

  async openVariable(instance: ListInstance) {
    if (!isVariable(instance)) return;

    await this.navigationService.navigateToResource(instance.variableRef, 'Variable');
  }

  createVariable() {
    return this.loadingScreenService.show(async () => {
      const newVariable = await this.variableRepository.create({
        name: '',
        variableRef: '_',
        valueType: VariableType.String,
        startValue: '',
        isPlayThroughStatistic: false,
        tags: [],
        modified: '',
      });

      this.openVariable(newVariable);
    });
  }

  async onTagSelected(event: { tag: Tag; instanceUid: string }) {
    const variable = this.variables.find((v) => v.variableRef === event.instanceUid);
    if (!variable) return;

    if (variable.tags.find((tag) => event.tag.uid === tag.uid)) {
      variable.tags.splice(
        variable.tags.findIndex((tag) => event.tag.uid === tag.uid),
        1,
      );
    } else {
      variable.tags.push(event.tag);
      variable.tags.sort((a, b) => a.name.localeCompare(b.name));
    }
    // force angular change detection
    this.variables = [...this.variables];
  }

  async onBulkTagSelected(event: { tag: Tag; instanceUids: string[]; isAdded: boolean }) {
    const oldVariables = this.variables.filter((variable) => event.instanceUids.includes(variable.variableRef));
    if (oldVariables.length === 0) return;

    for (const variable of oldVariables) {
      if (!event.isAdded && variable.tags.find((tag) => event.tag.uid === tag.uid)) {
        variable.tags.splice(
          variable.tags.findIndex((tag) => event.tag.uid === tag.uid),
          1,
        );
      } else if (event.isAdded && !variable.tags.find((tag) => event.tag.uid === tag.uid)) {
        variable.tags.push(event.tag);
        variable.tags.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.tagsPerVariable[variable.variableRef] = await Promise.all(variable.tags.map((tag) => Tag.deserialize(tag)));
    }

    // force angular change detection
    this.variables = [...this.variables];
  }

  sortVariables() {
    this.variables.sort((a, b) => a.name.localeCompare(b.name));
  }

  async deleteVariable(variable: ListInstance) {
    if (!isVariable(variable)) return;

    const confirmed = await firstValueFrom(this.confirmService.confirm('Are you sure you want to delete variable ' + variable.name + '?'));
    if (!confirmed) return;

    return await this.loadingScreenService.show(async () => {
      await this.variableRepository.delete(variable);
      this.variables = this.variables.filter((v) => v.variableRef !== variable.variableRef);
      delete this.tagsPerVariable[variable.variableRef];
      // force angular change detection
      this.variables = [...this.variables];
    });
  }

  async loadVariables() {
    this.loading = true;
    this.variables = await this.variableRepository.getAll();
    this.sortVariables();

    this.allTags = await this.tagRepository.getAll();
    this.tagSubscription = this.tagRepository.cache$.subscribe((tags) => {
      this.allTags = tags.filter((tag) => tag.scope === GeneratedTag.ScopeEnum.Instance).sort((a, b) => a.name.localeCompare(b.name));
    });

    const tagDeserializationPromises = this.variables.map(async (variable) => {
      variable.tags.sort((a, b) => a.name.localeCompare(b.name));
      this.tagsPerVariable[variable.variableRef] = await Promise.all(variable.tags.map((tag) => Tag.deserialize(tag)));
    });
    await Promise.all(tagDeserializationPromises);
    this.loading = false;
  }
}
