import { Entity } from './Entity';
import { Field } from './helpers/Field';
import { AutoSave } from '../decorators/AutoSave';
import { GeneratedFieldMigration, GeneratedStructType } from '../types/generated';
import GTInjector from '../GTInjector';
import { StructTypeRepository } from '@services/repositories';
import { ResourceService } from '@services/resource.service';
import { Tag } from '@services/entities';
import { OnChanges } from '@services/types/OnChanges';
import { EventEmitter } from '@angular/core';
import { EntityChanges } from '@services/types/EntityChanges';

interface NewStructType {
  typeId: string;
  name: string;
  description?: string;
  fields: Field[];
  isResource: boolean;
  tags: Tag[];
}

@AutoSave()
export class StructType extends Entity implements OnChanges {
  public typeId: string;
  public name: string;
  public description?: string;
  public fields: Record<string, Field>;
  public isResource: boolean;
  public tags: Tag[];

  // TODO implement
  public fieldMigrations?: GeneratedFieldMigration[];

  public readonly onChanges = new EventEmitter<EntityChanges<typeof this>>();

  constructor({ typeId, name, description, fields, isResource, tags }: NewStructType) {
    super();
    this.typeId = typeId;
    this.name = name;
    this.description = description;
    this.fields = fields.reduce(
      (acc, field) => {
        acc[field.fieldId] = field;
        return acc;
      },
      {} as Record<string, Field>,
    );
    this.isResource = isResource;
    this.tags = tags;

    this.onChanges.subscribe(async () => {
      (await GTInjector.inject(ResourceService)).update(this);
    });
  }

  public override get identifier() {
    return this.typeId;
  }

  public static async deserialize(data: GeneratedStructType): Promise<StructType> {
    const structType = new StructType({
      typeId: data.typeId,
      name: data.name,
      description: data.description,
      isResource: data.isResource,
      fields: await Promise.all(data.fields.map((field) => Field.deserialize(field))),
      tags: await Promise.all(data.tags.map((tag) => Tag.deserialize(tag))),
    });

    await structType.updateLastSavedValueHash();
    return structType;
  }

  public hasField(fieldId: string) {
    return !!this.fields[fieldId];
  }

  /**
   * @deprecated This method should be rarely used by a developer because @AutoSave() should handle most cases. If you
   * find any cases where auto saving does not work, please open a bug report so it can be fixed.
   */
  public async save() {
    return (await GTInjector.inject(StructTypeRepository)).save(this);
  }

  public async serialize(): Promise<Readonly<GeneratedStructType>> {
    return Object.freeze({
      typeId: this.typeId,
      name: this.name,
      description: this.description,
      fields: await Promise.all(Object.values(this.fields).map((f) => f.serialize())),
      isResource: this.isResource,
      tags: await Promise.all(Object.values(this.tags).map((tag) => tag.serialize())),
    });
  }
}
