import { Component } from '@angular/core';
import { DataPackageEndpoints } from '@services/api/DataPackageEndpoints';
import { AlertService } from '@services/UI-elements/alert-service';
import { FileEndpoints } from '@services/api';
import { GeneratedFileMeta } from '@services/types/generated';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-game-request-test-screen',
  standalone: true,
  imports: [],
  templateUrl: './game-request-test-screen.component.html',
  styles: ``,
})
export class GameRequestTestScreenComponent {
  private someFile?: GeneratedFileMeta = undefined;

  constructor(
    private dataPackageEndpoints: DataPackageEndpoints,
    private fileEndpoints: FileEndpoints,
    private alertService: AlertService,
  ) {}

  requestDataPackage() {
    this.dataPackageEndpoints.getDataPackage().subscribe((dp) => {
      this.alertService.info('Data Package Request Successful, got ' + dp.instances.length + ' Winstances');
    });
  }

  async requestSomeFile() {
    if (!this.someFile) {
      const fms = await lastValueFrom(this.fileEndpoints.getAllFileMetas());
      if (fms.files.length === 0) {
        this.alertService.error('No files found');
        return;
      }
      this.someFile = fms.files[0];
    }

    const blob = await lastValueFrom(this.fileEndpoints.downloadFile(this.someFile.uid));
    this.alertService.info(`Downloaded file ${this.someFile.name} with size ` + blob.size);
  }
}
