/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 */
export interface GeneratedSearchResult {
  uid: string;
  type: GeneratedSearchResult.TypeEnum;
  name: string;
  description: string;
  modifiedAt: string;
}
export namespace GeneratedSearchResult {
  export type TypeEnum = 'FIELD_VALUE' | 'FILE' | 'VARIABLE' | 'DATA_INSTANCE';
  export const TypeEnum = {
    FieldValue: 'FIELD_VALUE' as TypeEnum,
    File: 'FILE' as TypeEnum,
    Variable: 'VARIABLE' as TypeEnum,
    DataInstance: 'DATA_INSTANCE' as TypeEnum,
  };
}
