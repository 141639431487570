import { Injectable } from '@angular/core';
import { ApiBase } from '@services/api/ApiBase';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { GeneratedFileMeta, GeneratedFileMetaCollection } from '@services/types/generated';

@Injectable({ providedIn: 'root' })
export class FileEndpoints extends ApiBase {
  private fileUploaded = new Subject<GeneratedFileMeta>();
  readonly fileUploaded$ = this.fileUploaded.asObservable();

  public getAllFileMetas(): Observable<GeneratedFileMetaCollection> {
    return this.get<GeneratedFileMetaCollection>(`/game/${this.gameId}/media/${this.dataPackage}`);
  }

  public getFileMeta(fileUid: string): Observable<GeneratedFileMeta> {
    return this.get<GeneratedFileMeta>(`/game/${this.gameId}/media/${this.dataPackage}/${fileUid}`);
  }

  public deleteFile(fileUid: string, force: boolean = false): Observable<void> {
    return this.delete<void>(`/game/${this.gameId}/media/${this.dataPackage}/${fileUid}`, {
      params: force ? { force: 'true' } : undefined,
    });
  }

  public downloadFile(fileUid: string): Observable<Blob> {
    return this.get(`/game/${this.gameId}/media/${this.dataPackage}/${fileUid}/download`, { responseType: 'blob' });
  }

  public updateFileMeta(fileUid: string, newFileMeta: FormData): Observable<GeneratedFileMeta> {
    return this.put<GeneratedFileMeta>(`/game/${this.gameId}/media/${this.dataPackage}/${fileUid}`, newFileMeta);
  }

  public async uploadFile(newFileMeta: FormData): Promise<GeneratedFileMeta> {
    const fileMeta = await lastValueFrom(this.post<GeneratedFileMeta>(`/game/${this.gameId}/media/${this.dataPackage}`, newFileMeta));
    this.fileUploaded.next(fileMeta);
    return fileMeta;
  }
}
