import { Repository } from './Repository';
import { SelectType } from '@services/entities';
import { Injectable } from '@angular/core';
import { SelectTypeEndpoints } from '../api';
import { lastValueFrom, map, Observable, shareReplay } from 'rxjs';
import { GeneratedSelectType } from '../types/generated';
import { Cache } from '../utils';
import { DirtyHandling } from '@services/decorators/DirtyHandling';

@Injectable({ providedIn: 'root' })
export class SelectTypeRepository extends Repository<SelectType> {
  private readonly cache = new Cache<SelectType>();

  private allRequest?: Observable<Promise<SelectType[]>>;
  private allIdsRequest?: Observable<string[]>;
  private allIds?: string[];

  constructor(private selectTypeEndpoints: SelectTypeEndpoints) {
    super();
  }

  @DirtyHandling()
  public override async save<T extends string>(entity: SelectType<T>): Promise<void> {
    await lastValueFrom(this.selectTypeEndpoints.updateSelectType(await entity.serialize()));
  }

  public override async delete(entity: SelectType, force = false): Promise<void> {
    await lastValueFrom(this.selectTypeEndpoints.deleteSelectType(entity.typeId, force));
    this.cache.invalidate(entity.typeId);
  }

  public override async create(data: GeneratedSelectType): Promise<SelectType> {
    const selectType = await SelectType.deserialize(await lastValueFrom(this.selectTypeEndpoints.createSelectType(data)));
    this.allIds?.push(selectType.typeId);
    return this.cache.set(selectType.typeId, selectType, 5);
  }

  public override async get<T extends string = string>(id: string, skipCache: boolean = false): Promise<SelectType<T>> {
    // We first need to convert to unknown before converting to SelectType<T>, as string is not comparable to type <T>.
    //  This is a bit annoying but it works. If you have a workaround, please fix.

    if (!skipCache && this.cache.isValid(id)) {
      return this.cache.get(id)!.value as unknown as SelectType<T>;
    }

    if (this.requests[id] !== undefined) {
      return (await lastValueFrom(this.requests[id])) as unknown as SelectType<T>;
    }

    this.requests[id] = this.selectTypeEndpoints.getSelectType(id).pipe(
      map(async (data) => {
        if (this.cache.isValid(id)) return this.cache.get(id)!.value;
        return this.cache.set(id, await SelectType.deserialize(data), 5);
      }),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.requests[id]);
    delete this.requests[id];
    return data as unknown as SelectType<T>;
  }

  public async getAll(): Promise<SelectType[]> {
    if (this.cache.allKeysPresent) return Object.values(this.cache.getAll());

    if (this.allRequest !== undefined) {
      return await lastValueFrom(this.allRequest);
    }

    this.allRequest = this.selectTypeEndpoints.getSelectTypes().pipe(
      map((response) =>
        Promise.all(
          response.map(async (selectType) => {
            if (this.cache.isValid(selectType.typeId)) return this.cache.get(selectType.typeId)!.value;
            return await SelectType.deserialize(selectType);
          }),
        ),
      ),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.allRequest);
    this.cache.setAll(data, (d) => d.typeId, 5);
    this.allRequest = undefined;
    return data;
  }

  public async getAllIds(): Promise<string[]> {
    if (this.allIds !== undefined) {
      return this.allIds;
    }

    if (this.allIdsRequest !== undefined) {
      return await lastValueFrom(this.allIdsRequest);
    }

    this.allIdsRequest = this.selectTypeEndpoints.getSelectTypes().pipe(
      map((response) => response.map((selectType) => selectType.typeId)),
      shareReplay(1),
    );

    this.allIds = await lastValueFrom(this.allIdsRequest);
    this.allIdsRequest = undefined;

    return this.allIds;
  }
}
