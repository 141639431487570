import { ApiBase } from './ApiBase';
import { Injectable } from '@angular/core';
import {
  GeneratedClearCacheRequest,
  GeneratedDataCleanupActionRequest,
  GeneratedDataCleanupAnalyzeResult,
  GeneratedDataCleanupResponse,
} from '../types/generated';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MaintenanceEndpoints extends ApiBase {
  public analyze(): Observable<GeneratedDataCleanupAnalyzeResult> {
    return this.get<GeneratedDataCleanupAnalyzeResult>(`/maintenance/game/${this.gameId}/${this.dataPackage}/data-cleanup/analyze`);
  }

  public performDataCleanup(actions: GeneratedDataCleanupActionRequest[]): Observable<GeneratedDataCleanupResponse> {
    return this.post<GeneratedDataCleanupResponse>(`/maintenance/game/${this.gameId}/${this.dataPackage}/data-cleanup`, actions);
  }

  public clearCache(): Observable<void> {
    return this.post<void>(`/maintenance/clear-cache`, {});
  }

  public clearMediaCache(request: GeneratedClearCacheRequest = { dataPackageUid: this.dataPackage }): Observable<void> {
    return this.post<void>(`/maintenance/clear-media-cache`, request);
  }
}
