import { Entity } from '@services/entities';
import { Observable } from 'rxjs';

/**
 * A repository for entities. The repository can be used to save, delete, and get entities.  * The 'T' generic type
 * should be the class that implements Entity, and the 'V' generic type is the type that can be used to create the entity.
 */
export abstract class Repository<T extends Entity, K = string> {
  protected readonly requests: Record<string, Observable<Promise<T>>> = {};

  public abstract save(entity: T): Promise<void>;

  public abstract delete(entity: T): Promise<void>;

  public abstract create(...data: unknown[]): Promise<T>;

  public abstract get(id: K, invalidateCache: boolean, ...data: unknown[]): Promise<T>;
}
