import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FieldEditorComponentType } from '@services/dynamic-field.service';
import { FieldValue } from '@services/entities/helpers';
import { FieldEditorType } from '@services/types/FieldEditorType';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent implements OnInit, OnDestroy {
  @Input({ required: true }) fieldComponent!: FieldEditorComponentType;
  @Input({ required: true }) data!: FieldValue;

  @Input() name = '';
  @Input() description = '';
  @Input() editorType: FieldEditorType | undefined;
  fieldValidationWarning: string = '';
  protected readonly FieldEditorType = FieldEditorType;
  private updateSubscription?: Subscription;
  private fieldValueChangeSubscription?: Subscription;

  ngOnInit(): void {
    this.name = this.name || this.data?.field.name || '';
    this.description = this.data?.field.description || '';

    if (this.data.field.casOnly) {
      if (
        this.description.length > 0 &&
        !this.description.endsWith('.') &&
        !this.description.endsWith('?') &&
        !this.description.endsWith('!')
      )
        this.description += '.';
      this.description += ' Will not be shown in the game.';
    }
    if (this.data.field.deprecated) {
      this.description = '[Deprecated] ' + this.description;
    }

    this.editorType = this.editorType || this.fieldComponent.editorType;
    const failedFieldValidation = this.data.field.getFailedFieldValidations(this.data.value);
    if (failedFieldValidation) this.fieldValidationWarning = failedFieldValidation.getFieldValidationWarning();

    this.fieldValueChangeSubscription = this.data.onChange.subscribe(() => {
      const failedFieldValidation = this.data.field.getFailedFieldValidations(this.data.value);
      if (failedFieldValidation) this.fieldValidationWarning = failedFieldValidation.getFieldValidationWarning();
      else this.fieldValidationWarning = '';
    });
    this.updateSubscription = this.fieldComponent.onChange?.subscribe(() => (this.editorType = this.fieldComponent.editorType));
  }

  ngOnDestroy() {
    this.updateSubscription?.unsubscribe();
    this.fieldValueChangeSubscription?.unsubscribe();
  }
}
