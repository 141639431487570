import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { DataInstance } from '@services/entities';
import { DataInstanceRepository } from '@services/repositories';
import { environment } from '../../../../../environments/environment';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-map-editor',
  templateUrl: './map-editor.component.html',
  styleUrls: ['./map-editor.component.scss'],
})
export class MapEditorComponent implements OnInit {
  mapInstance?: DataInstance;
  mapUid?: string;
  protected readonly environment = environment;

  constructor(
    private route: ActivatedRoute,
    private loadingScreenService: LoadingScreenService,
    private dataInstanceRepository: DataInstanceRepository,
    private navigationService: NavigationService,
  ) {}

  async ngOnInit() {
    await this.loadingScreenService.show(async () => {
      this.route.params.subscribe(async (params) => {
        const mapUid = params[this.navigationService.queryParamKeys.Map];
        if (!mapUid) return;

        this.mapUid = mapUid;
        this.mapInstance = await this.dataInstanceRepository.get(mapUid);
      });
    });
  }
}
