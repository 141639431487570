import { Injectable } from '@angular/core';
import { DataInstance, StructType } from '@services/entities';
import { BehaviorSubject, first, lastValueFrom } from 'rxjs';
import { AuthService, AuthState } from '@services/authorization/auth.service';
import { DataInstanceRepository, StructTypeRepository } from '@services/repositories';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  private initialized = new BehaviorSubject<boolean>(false);

  private readonly onUpdated = new BehaviorSubject<Record<StructType['typeId'], StructType>>({});
  public readonly onUpdated$ = this.onUpdated.asObservable();

  constructor(
    authService: AuthService,
    private structTypeRepository: StructTypeRepository,
    private dataInstanceRepository: DataInstanceRepository,
  ) {
    authService.state.subscribe((state) => {
      if (state === AuthState.LOGGED_IN) {
        this.init().then();
      }
    });
  }

  private _resourceStructs: Record<StructType['typeId'], StructType> = {};

  get resourceStructs() {
    // TODO: We should probably attempt to freeze this thing somehow as returning the raw object isn't really safe.
    //  however if we call Object.freeze on this thing it will make the entire thing immutable.
    return this._resourceStructs;
  }

  private _resourceInstances: Record<StructType['typeId'], DataInstance[]> = {};

  get resourceInstances() {
    // TODO: We should probably attempt to freeze this thing somehow as returning the raw object isn't really safe.
    //  however if we call Object.freeze on this thing it will make the entire thing immutable.
    return this._resourceInstances;
  }

  public async waitForInit() {
    await lastValueFrom(this.initialized.pipe(first(Boolean)));
  }

  /**
   * Update the resource information when a struct type's resource status changes.
   * This method is called by the StructType's onChanges observable.
   */
  public update(updatedStructType: StructType) {
    if (updatedStructType.isResource) {
      this._resourceStructs[updatedStructType.typeId] = updatedStructType;
      this.callOnUpdated();
      return;
    }

    if (updatedStructType.typeId in this._resourceStructs) delete this._resourceStructs[updatedStructType.typeId];
    this.callOnUpdated();
  }

  public delete(removedStructType: StructType) {
    if (removedStructType.typeId in this._resourceStructs) delete this._resourceStructs[removedStructType.typeId];
    this.callOnUpdated();
  }

  private async init() {
    const structTypes = await this.structTypeRepository.getAllResources();
    this._resourceStructs = structTypes.reduce((acc, st) => ({ ...acc, [st.typeId]: st }), {});

    await Promise.all(
      Object.values(this._resourceStructs).map(async (structType) => {
        this._resourceInstances[structType.typeId] = [];
        this._resourceInstances[structType.typeId] = await this.dataInstanceRepository.getAllByStructTypeId(structType.typeId);
      }),
    );

    this.callOnUpdated();
    this.initialized.next(true);
  }

  private callOnUpdated() {
    this.onUpdated.next(this._resourceStructs);
  }
}
