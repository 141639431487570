import { Pipe, PipeTransform } from '@angular/core';
import Konva from 'konva';
import { BehaviorSubject } from 'rxjs';

type PreviousSubject = [
  Konva.Shape,
  string,
  () => void,
  BehaviorSubject<
    {
      label: string;
      value: unknown;
      set: (v: string) => void;
    }[]
  >,
];

@Pipe({
  name: 'menuSubject',
})
export class MenuSubjectPipe implements PipeTransform {
  private previous?: PreviousSubject;

  transform(shape: Konva.Shape) {
    const behaviorSubject = new BehaviorSubject(this.getObject(shape));

    const dragEnd = 'transformend dragend' as const;
    const handler = () => {
      behaviorSubject.next(this.getObject(shape));
    };

    shape.on(dragEnd, handler);

    if (this.previous) {
      const [prevShape, prevEvts, prevHandler, prevEe] = this.previous;
      prevShape.off(prevEvts, prevHandler);
      prevEe.complete();
    }

    this.previous = [shape, dragEnd, handler, behaviorSubject];

    return behaviorSubject;
  }

  private getObject(shape: Konva.Shape) {
    const props = ['x', 'y'];

    if (shape.className === 'Rect') {
      props.push('width', 'height');
    }

    if (shape.className === 'Circle') {
      props.push('radius');
    }

    return props.map((p) => {
      const fn = shape[p as keyof typeof shape].bind(shape);

      return {
        label: p.charAt(0).toUpperCase(),
        value: fn().toFixed(0) as unknown,
        set: (v: string) => {
          fn(parseFloat(v));
          shape.dispatchEvent(new Event('updatedFromMenuSubject'));
        },
      };
    });
  }
}
