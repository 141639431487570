/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeneratedTag } from './tag';

/**
 *
 */
export interface GeneratedFileMeta {
  uid: string;
  name: string;
  alt: string;
  fileSize: number;
  fileType: string;
  fileUrl: string;
  modified: string;
  url: string;
  width?: number;
  height?: number;
  tags: Array<GeneratedTag>;
  note?: string;
}
