import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BootstrapClass } from '@services/types/BootstrapClass';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { AlertService } from '@services/UI-elements/alert-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataInstance, EnumType, Tag } from '@services/entities';
import { DataInstanceRepository, EnumTypeRepository, TagRepository } from '@services/repositories';
import { GeneratedDataInstance, GeneratedTag } from '@services/types/generated';
import { DownloadService } from '@services/download.service';
import { isDataInstance, ListInstance } from '@services/utils/ListInstance';

@Component({
  selector: 'app-enum-list',
  templateUrl: './enum-list.component.html',
  styleUrls: ['./enum-list.component.scss'],
})
export class EnumListComponent implements OnInit, OnDestroy {
  @ViewChild('duplicationCompleteModal') duplicationCompleteModalRef!: NgbModalRef;

  enumTypeInstances: DataInstance[] = [];
  allTags: Tag[] = [];
  tagsPerEnumTypeInstance: Record<string, Tag[]> = {};
  enumTypeId = '';
  enumType?: EnumType;
  newEnumType: string = '';
  possibleStructTypes: string[] = [];
  routeSub?: Subscription;
  loading = false;

  protected newEnumTypeInstance?: DataInstance;
  private tagSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private confirmService: ConfirmationModalService,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
    private alertService: AlertService,
    protected modalService: NgbModal,
    private dataInstanceRepository: DataInstanceRepository,
    private enumTypeRepository: EnumTypeRepository,
    private tagRepository: TagRepository,
    private downloadService: DownloadService,
  ) {}

  ngOnInit() {
    return this.loadingScreenService.show(async () => {
      this.routeSub = this.route.params.subscribe((params) => {
        if (params && params['enumType']) {
          this.enumTypeId = params['enumType'];
          this.loadingScreenService.show(() =>
            this.loadEnumInstances().catch(() => {
              this.alertService.error(`Failed to load enumType '${this.enumTypeId}'!`);
              this.router.navigate(['/home']);
            }),
          );
        }
      });
    });
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
    this.tagSubscription?.unsubscribe();
  }

  async openEnumTypeInstance(instance: ListInstance): Promise<boolean> {
    if (isDataInstance(instance)) {
      return this.router.navigate(['/home', instance.structType.typeId, await instance.identifier]);
    }
    return Promise.resolve(false);
  }

  createEnumTypeInstance() {
    return this.loadingScreenService.show(async () => {
      const enumTypeInstance = await this.dataInstanceRepository.create(this.newEnumType);
      this.enumTypeInstances.push(enumTypeInstance);
      await this.openEnumTypeInstance(enumTypeInstance);
    });
  }

  enumTypeInstanceName(instance: ListInstance) {
    if (!isDataInstance(instance)) return '';
    return instance.getName();
  }

  async onTagSelected(event: { tag: Tag; instanceUid: string }) {
    const enumTypeInstance = this.enumTypeInstances.find((m) => m.randomIdentifier === event.instanceUid);
    if (!enumTypeInstance) return;
    if (enumTypeInstance.tags.find((tag) => event.tag.uid === tag.uid)) {
      enumTypeInstance.tags.splice(
        enumTypeInstance.tags.findIndex((tag) => event.tag.uid === tag.uid),
        1,
      );
    } else {
      enumTypeInstance.tags.push(event.tag);
      enumTypeInstance.tags.sort((a, b) => a.name.localeCompare(b.name));
    }
    // force angular change detection
    this.enumTypeInstances = [...this.enumTypeInstances];
  }

  async onBulkTagSelected(event: { tag: Tag; instanceUids: string[]; isAdded: boolean }) {
    const oldEnumTypeInstances = this.enumTypeInstances.filter((enumTypeInstance) =>
      event.instanceUids.includes(enumTypeInstance.randomIdentifier),
    );
    if (oldEnumTypeInstances.length === 0) return;

    for (const enumTypeInstance of oldEnumTypeInstances) {
      if (!event.isAdded && enumTypeInstance.tags.find((tag) => event.tag.uid === tag.uid)) {
        enumTypeInstance.tags.splice(
          enumTypeInstance.tags.findIndex((tag) => event.tag.uid === tag.uid),
          1,
        );
      } else if (event.isAdded && !enumTypeInstance.tags.find((tag) => event.tag.uid === tag.uid)) {
        enumTypeInstance.tags.push(event.tag);
        enumTypeInstance.tags.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.tagsPerEnumTypeInstance[enumTypeInstance.randomIdentifier] = await Promise.all(
        enumTypeInstance.tags.map((tag) => Tag.deserialize(tag)),
      );
    }

    // force angular change detection
    this.enumTypeInstances = [...this.enumTypeInstances];
  }

  deleteEnumTypeInstance(instance: ListInstance, force = false): Promise<void> {
    if (!isDataInstance(instance)) return Promise.resolve();
    const randomIdentifier = instance.randomIdentifier;
    return this.loadingScreenService.show(async () => {
      if (!force) {
        // Open a confirmation modal
        const confirmed = await firstValueFrom(
          this.confirmService.confirm('Are you sure you want to delete enumTypeInstance: "' + this.enumTypeInstanceName(instance) + '"?'),
        );

        if (!confirmed) return;
      }

      try {
        await this.dataInstanceRepository.delete(instance, force);
        this.enumTypeInstances = this.enumTypeInstances.filter((r) => r.randomIdentifier !== randomIdentifier);
        delete this.tagsPerEnumTypeInstance[randomIdentifier];
        // force angular change detection
        this.enumTypeInstances = [...this.enumTypeInstances];
      } catch (e) {
        if (
          !force &&
          e instanceof HttpErrorResponse &&
          e.status === 409 &&
          confirm(
            `The enumTypeInstance ${this.enumTypeInstanceName(instance)} is used in other places. Are you sure you want to delete it? This is a destructive action and cannot be undone.`,
          )
        ) {
          return this.deleteEnumTypeInstance(instance, true);
        }

        console.error('Error deleting enumTypeInstance: ', e);
      }
    }) as Promise<void>;
  }

  downloadEnumTypeInstance(instance: ListInstance) {
    if (!isDataInstance(instance)) return;

    return this.loadingScreenService.show(async () => {
      const serialized = await instance.serialize();
      const subObjects = await instance.getSubObjects();

      return this.downloadService.json((await instance.identifier) + '.json', {
        ...serialized,
        subObjects: (await Promise.all(subObjects.map(async (di) => await di.serialize()))).reduce(
          (acc, di) => {
            acc[di.uid] = di;
            return acc;
          },
          {} as Record<string, GeneratedDataInstance>,
        ),
      });
    });
  }

  duplicateEnumTypeInstance(instance: ListInstance) {
    if (!isDataInstance(instance)) return;
    return this.loadingScreenService.show(async () => {
      try {
        const identifier = await instance.identifier;
        if (!identifier) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error('Resource not found!');
        }

        const dataInstance = (
          await this.dataInstanceRepository.duplicateDataInstances({
            instanceUids: [identifier],
            dropExternalReferencesOfTypes: [],
          })
        ).dataInstances[0];

        this.enumTypeInstances.push(dataInstance);
        this.tagsPerEnumTypeInstance[dataInstance.randomIdentifier] = await Promise.all(
          dataInstance.tags.map((tag) => Tag.deserialize(tag)),
        );
        this.sortEnumTypeInstances();
        // force angular change detection
        this.enumTypeInstances = [...this.enumTypeInstances];

        this.newEnumTypeInstance = dataInstance;

        this.modalService.dismissAll('Closed before opening new modal');
        this.modalService.open(this.duplicationCompleteModalRef, {
          ariaLabelledBy: 'duplication-success-modal',
          centered: true,
        });
      } catch (e) {
        this.alertService.showAlert('Failed to duplicate enumTypeInstance!', BootstrapClass.DANGER);
        throw e;
      }
    });
  }

  private sortEnumTypeInstances() {
    this.enumTypeInstances.sort((a, b) => {
      const aName = this.enumTypeInstanceName(a) ?? '';
      const bName = this.enumTypeInstanceName(b) ?? '';

      // Empty strings should be sorted last
      if (!aName && !bName) return 0;
      if (!aName) return 1;
      if (!bName) return -1;
      return aName.localeCompare(bName);
    });
  }

  private async loadEnumInstances() {
    this.loading = true;

    try {
      const enumType = await this.enumTypeRepository.get(this.enumTypeId);
      this.enumType = enumType;
      this.possibleStructTypes = enumType.options;
      this.newEnumType = this.possibleStructTypes[0];
      this.enumTypeInstances = [];
      for (const option of enumType.options) {
        this.enumTypeInstances.push(...(await this.dataInstanceRepository.getAllByStructTypeId(option)));
      }
      this.sortEnumTypeInstances();

      this.allTags = await this.tagRepository.getAll();
      this.tagSubscription = this.tagRepository.cache$.subscribe((tags) => {
        this.allTags = tags.filter((tag) => tag.scope === GeneratedTag.ScopeEnum.Instance).sort((a, b) => a.name.localeCompare(b.name));
      });

      const tagDeserializationPromises = this.enumTypeInstances.map(async (enumTypeInstance) => {
        enumTypeInstance.tags.sort((a, b) => a.name.localeCompare(b.name));
        this.tagsPerEnumTypeInstance[enumTypeInstance.randomIdentifier] = await Promise.all(
          enumTypeInstance.tags.map((tag) => Tag.deserialize(tag)),
        );
      });
      await Promise.all(tagDeserializationPromises);
    } catch (e) {
      this.alertService.error(`Failed to load enumType '${this.enumTypeId}'!`);
      throw e;
    } finally {
      this.loading = false;
    }
  }
}
