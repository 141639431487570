import { ApiBase } from './ApiBase';
import { Injectable } from '@angular/core';
import { GeneratedVariable } from '../types/generated';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VariableEndpoints extends ApiBase {
  public getAllVariables(): Observable<GeneratedVariable[]> {
    return this.get<GeneratedVariable[]>(`/game/${this.gameId}/data/${this.dataPackage}/variables`);
  }

  public getVariable(variableRef: string): Observable<GeneratedVariable> {
    return this.get<GeneratedVariable>(`/game/${this.gameId}/data/${this.dataPackage}/variables/${variableRef}`);
  }

  public createVariable(variable: GeneratedVariable): Observable<GeneratedVariable> {
    return this.post<GeneratedVariable>(`/game/${this.gameId}/data/${this.dataPackage}/variables`, variable);
  }

  public updateVariable(variableRef: string, variable: GeneratedVariable): Observable<GeneratedVariable> {
    return this.put<GeneratedVariable>(`/game/${this.gameId}/data/${this.dataPackage}/variables/${variableRef}`, variable);
  }

  public deleteVariable(variableRef: string): Observable<void> {
    return this.delete<void>(`/game/${this.gameId}/data/${this.dataPackage}/variables/${variableRef}`);
  }
}
