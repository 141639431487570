import { GeneratedFieldEditor } from '../../types/generated';
import { FieldEditorType } from '@services/types/FieldEditorType';
import { Serializable } from '@services/types/Serializable';

export interface NewFieldEditor {
  fieldId: string;
  structId: string;
  editorType: FieldEditorType;
  hideInSeamlessInline: boolean;
  showIf?: string;
  showIfValue?: string;
  showResource?: boolean;
  position: number;
  scope?: string;
  displayField?: string;
  textEditorType: GeneratedFieldEditor.TextEditorTypeEnum;
}

export class FieldEditor implements Serializable<GeneratedFieldEditor> {
  public readonly fieldId: string;
  public readonly structId: string;

  public editorType: FieldEditorType;
  public hideInSeamlessInline: boolean;
  public showIf?: string;
  public showIfValue?: string;
  public showResource?: boolean;
  public position: number;
  public scope?: string;
  public displayField?: string;
  public textEditorType: GeneratedFieldEditor.TextEditorTypeEnum;

  constructor({
    fieldId,
    structId,
    editorType,
    hideInSeamlessInline,
    showIf,
    showIfValue,
    showResource,
    position,
    scope,
    displayField,
    textEditorType,
  }: NewFieldEditor) {
    this.fieldId = fieldId;
    this.structId = structId;
    this.editorType = editorType;
    this.hideInSeamlessInline = hideInSeamlessInline;
    this.showIf = showIf;
    this.showIfValue = showIfValue;
    this.showResource = showResource;
    this.position = position;
    this.scope = scope;
    this.displayField = displayField;
    this.textEditorType = textEditorType;
  }

  public static async deserialize(data: GeneratedFieldEditor): Promise<FieldEditor> {
    const fieldEditorType = (Object.values(FieldEditorType) as string[]).includes(data.editorType)
      ? (data.editorType as FieldEditorType)
      : FieldEditorType.Default;

    return new FieldEditor({
      structId: data.structId,
      fieldId: data.fieldId,
      editorType: fieldEditorType,
      hideInSeamlessInline: data.hideInSeamlessInline,
      showIf: data.showIf,
      showIfValue: data.showIfValue,
      showResource: data.showResource,
      position: data.position,
      scope: data.scope,
      displayField: data.displayField,
      textEditorType: data.textEditorType ?? GeneratedFieldEditor.TextEditorTypeEnum.Area,
    });
  }

  public async serialize(): Promise<Readonly<GeneratedFieldEditor>> {
    return Object.freeze({
      editorType: this.editorType,
      hideInSeamlessInline: this.hideInSeamlessInline,
      showIf: this.showIf,
      showIfValue: this.showIfValue,
      showResource: this.showResource,
      position: this.position,
      scope: this.scope,
      displayField: this.displayField,
      textEditorType: this.textEditorType,
      structId: this.structId,
      fieldId: this.fieldId,
    });
  }
}
