import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DataInstanceRepository, EnumTypeRepository } from '@services/repositories';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public readonly queryParamKeys = {
    ActivityNode: 'activityNode',
    KennisNode: 'kennisNode',
    MissionInfo: 'missionInfoUid',
    Module: 'moduleUid',
    Map: 'mapUid',
    Resource: 'resourceUid',
    ResourceStructType: 'resource',
    Variable: 'variableRef',
    DataModelType: 'type',
    DataModelTypeId: 'typeId',
    Redirect: 'redirectUrl',
  } as const;

  constructor(
    private dataInstanceRepository: DataInstanceRepository,
    private enumTypeRepository: EnumTypeRepository,
    private router: Router,
  ) {}

  /**
   * A method to find the url of a data instance, if it exists. Also handles special cases like MissionInfo and Modules.
   * @param uid
   */
  public async findDataInstanceUrl(uid: string): Promise<[string[], NavigationExtras?]> {
    const dataInstance = await this.dataInstanceRepository.get(uid);
    const [parent, traversedChildren] = await dataInstance.getParent();

    switch (parent.dataType) {
      case 'MissionInfo': {
        const activityEnum = await this.enumTypeRepository.get('Activity');
        const activity = activityEnum.options.includes(dataInstance.dataType)
          ? await dataInstance.identifier
          : await traversedChildren.find((child) => activityEnum.options.includes(child.dataType))?.identifier;

        return this.getUrlOfMissionInfo(await parent.identifier, activity ?? null);
      }

      case 'Module': {
        return this.getUrlOfModule(await parent.identifier, await dataInstance.identifier);
      }

      default: {
        return [this.getUrlOfResource(await parent.identifier, parent.dataType)];
      }
    }
  }

  public async navigateToDataInstance(uid: string) {
    const [url, extras] = await this.findDataInstanceUrl(uid);
    await this.router.navigate(url, extras);
  }

  public navigateToMissionInfo(missionInfoUid: string, activityUid: string | null) {
    const [url, extras] = this.getUrlOfMissionInfo(missionInfoUid, activityUid);
    return this.router.navigate(url, extras);
  }

  public navigateToModule(moduleUid: string, kennisNodeUid: string | null) {
    const [url, extras] = this.getUrlOfModule(moduleUid, kennisNodeUid);
    return this.router.navigate(url, extras);
  }

  public navigateToResource(resourceUid: string, resourceStructType: string) {
    return this.router.navigate(this.getUrlOfResource(resourceUid, resourceStructType));
  }

  public getUrlOfMissionInfo(missionInfoUid: string, activityUid: string | null): [string[], NavigationExtras?] {
    return [
      ['/home/MissionInfo/' + missionInfoUid],
      {
        queryParams: {
          [this.queryParamKeys.ActivityNode]: activityUid,
        },
      },
    ];
  }

  public getUrlOfModule(moduleUid: string, kennisNodeUid: string | null): [string[], NavigationExtras?] {
    return [
      ['/home/Module/' + moduleUid],
      {
        queryParams: {
          [this.queryParamKeys.KennisNode]: kennisNodeUid,
        },
      },
    ];
  }

  public getUrlOfResource(resourceUid: string, resourceStructType: string): string[] {
    return [`/home/${resourceStructType}/${resourceUid}`];
  }

  public getUrlOfDataModelType(typeId: string, type: string): string[] {
    return [`/home/data-model/${type}/${typeId}`];
  }
}
