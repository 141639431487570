import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor() {}

  /**
   * Downloads a JSON file. The `data` argument can be either an object which will be stringified
   * or a string which will be used directly.
   * @param fileName The name of the file to be downloaded.
   * @param data The data to be downloaded as a JSON file.
   */
  public json(fileName: string, data: object | string) {
    return this.download(
      fileName,
      'application/json;charset=utf-8,' + encodeURIComponent(typeof data === 'string' ? data : JSON.stringify(data)),
    );
  }

  private download(fileName: string, data: string) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:' + data);
    element.setAttribute('download', fileName);
    element.click();
    element.remove();
  }
}
