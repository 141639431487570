import { Injectable } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, lastValueFrom, Observable } from 'rxjs';
import { TokenManagerService } from './token-manager.service';

export const NO_AUTH = new HttpContextToken<boolean>(() => false);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenManager: TokenManagerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(NO_AUTH)) {
      return next.handle(request);
    }

    return from(this.handle(request, next));
  }

  private async handle(request: HttpRequest<unknown>, next: HttpHandler) {
    const authToken = await this.tokenManager.getAccessToken();

    const authorizedRequest = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authToken,
      },
    });

    return await lastValueFrom(next.handle(authorizedRequest));
  }
}
