<div class="wrapper">
  <div *ngIf="showLoadingScreen" class="overlay">
    <picture>
      <source srcset="assets/images/orange-slice-small.webp" type="image/webp" />
      <img alt="" class="rotate" height="96" ngSrc="assets/images/orange-slice-small.png" width="96" />
    </picture>

    <div class="spinner-message">
      <h3>Loading</h3>
    </div>
  </div>

  <div *ngIf="showSavingIndicator" class="saving-indicator" id="saving-indicator">
    <img alt="" class="rotate" height="28" ngSrc="assets/images/orange-slice-small.png" width="28" />
    <p>Saving...</p>
  </div>

  <div>
    <app-alert />
    <router-outlet />
  </div>
</div>
