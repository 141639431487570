/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 */
export interface GeneratedTag {
  name: string;
  color: string;
  uid: string;
  scope: GeneratedTag.ScopeEnum;
}
export namespace GeneratedTag {
  export type ScopeEnum = 'DATA_MODEL' | 'DATA_INSTANCE';
  export const ScopeEnum = {
    Model: 'DATA_MODEL' as ScopeEnum,
    Instance: 'DATA_INSTANCE' as ScopeEnum,
  };
}
