/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeneratedTag } from './tag';
import { GeneratedField } from './field';

/**
 *
 */
export interface GeneratedStructType {
  typeId: string;
  name: string;
  description?: string;
  fields: Array<GeneratedField>;
  isResource: boolean;
  tags: Array<GeneratedTag>;
}
