import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { Logger } from '@services/utils';

@Component({
  selector: 'app-icon-field',
  templateUrl: './icon-field.component.html',
  styleUrls: ['./icon-field.component.scss'],
})
export class IconFieldComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;
  @Output() iconChanged = new EventEmitter<string>();

  value: string = '';

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.ICON, this.data.value);
  }

  async update() {
    try {
      await this.data.set(this.value);
      this.iconChanged.emit(this.value);
    } catch (e) {
      Logger.error(`Failed to save field ${this.data.field.fieldId}: `, e);
      this.alertService.error(`Failed to save field value`);
    }
  }
}
