import { inject as ngInject, Injector, ProviderToken, runInInjectionContext } from '@angular/core';

/**
 * This class provides a workaround for the issue of not being able to access the injector in non-angular context.
 * This is needed for the entities where we need access to repositories which require angular context.
 * The setInjector() method is called first-thing in the app component so the injector should always be present.
 */
export default class GTInjector {
  private static injector: Injector | null = null;

  static setInjector(injector: Injector): void {
    this.injector = injector;
  }

  static inject<T>(token: ProviderToken<T>): Promise<T> {
    if (!this.injector) {
      return Promise.reject(new Error('Injector has not been set.'));
    }

    return new Promise((resolve) => {
      runInInjectionContext(this.injector!, () => {
        const instance = ngInject(token);
        resolve(instance);
      });
    });
  }
}
