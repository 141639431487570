import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { Logger } from '@services/utils';
import { FieldType, FieldValue } from '@services/entities/helpers';

@Component({
  selector: 'app-number-input-field',
  templateUrl: './number-input-field.component.html',
  styleUrls: ['./number-input-field.component.scss'],
})
export class NumberInputFieldComponent implements OnInit, FieldEditorComponent<number> {
  @Input({ required: true }) data!: FieldValue;
  @Input() min = 0;
  @Input() max = 10;

  value!: number;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.FLOAT, this.data.value);
  }

  async update() {
    try {
      await this.data.set(this.value);
    } catch (e) {
      Logger.error(`Failed to save field ${this.data.field.fieldId}: `, e);
      this.alertService.error(`Failed to save field value`);
    }
  }
}
