/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 */
export interface GeneratedFieldEditor {
  editorType: string;
  hideInSeamlessInline: boolean;
  fieldId: string;
  structId: string;
  showIf?: string;
  showIfValue?: string;
  showResource?: boolean;
  position: number;
  scope?: string;
  displayField?: string;
  textEditorType?: GeneratedFieldEditor.TextEditorTypeEnum;
}
export namespace GeneratedFieldEditor {
  export type TextEditorTypeEnum = 'TEXT_AREA' | 'TEXT_FIELD';
  export const TextEditorTypeEnum = {
    Area: 'TEXT_AREA' as TextEditorTypeEnum,
    Field: 'TEXT_FIELD' as TextEditorTypeEnum,
  };
}
