import { Component, OnInit } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { SelectorFieldComponent } from '../selector-field/selector-field.component';
import { Router } from '@angular/router';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { NavigationService } from '@services/navigation.service';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { DataInstanceRepository, StructTypeRepository } from '@services/repositories';
import { FieldType, FieldTypes, Resource, SelectTypeOption } from '@services/entities/helpers';
import { Logger, sleep, Try } from '@services/utils';
import { EnumTypeRepository } from '@services/repositories/EnumTypeRepository';
import { DataInstance } from '@services/entities';

@Component({
  selector: 'app-resource-selector-field',
  template: '<app-selector-field [data]="data" [choices]="choices" [loading]="!choicesLoaded" (reload)="reloadOptionsOfDropdown()" />',
})
export class ResourceSelectorFieldComponent extends SelectorFieldComponent implements OnInit, FieldEditorComponent<string | string[]> {
  choicesLoaded = false;
  checkedDataInstances: string[] = [];

  constructor(
    alertService: AlertService,
    router: Router,
    navigationService: NavigationService,
    loadingService: LoadingScreenService,
    structTypeRepository: StructTypeRepository,
    dataInstanceRepository: DataInstanceRepository,
    private enumTypeRepository: EnumTypeRepository,
  ) {
    super(alertService, router, navigationService, loadingService, structTypeRepository, dataInstanceRepository);
  }

  override async ngOnInit() {
    this.choices = [];
    await this.reloadOptionsOfDropdown(true);
  }

  async reloadOptionsOfDropdown(onInit = false) {
    if (!this.data) return;

    this.choicesLoaded = false;

    const dataInstance = await this.dataInstanceRepository.get(this.data.dataInstanceUid);
    if (!dataInstance) throw new Error('Data instance not found');

    this.isList = FieldTypes.isListType(this.data.field.type);

    // Get the choices for the dropdown
    const typeId = FieldTypes.getDeepestReference(this.data.field.type);
    if (!typeId) throw new Error('Type id not found');

    const scope = this.data.field.fieldEditor?.scope;
    this.choices = await this.loadChoices(typeId, scope);

    // Force some wait time, since if it's too fast, the dropdown will show the wrong selected option
    if (!onInit) await sleep(300);

    this.choicesLoaded = true;
  }

  private async loadChoices(typeId: string, scope: string | undefined): Promise<SelectTypeOption[]> {
    let loadedChoices: Resource[] = [];

    if (!this.data) return [];

    switch (scope) {
      case 'local': {
        this.checkedDataInstances = [];
        const options = await this.searchParentForField(this.data.dataInstanceUid, typeId);

        if (options.length === 0) {
          console.log('No options found for field ' + typeId + ' in parents, so searching globally');
          loadedChoices = await this.getGlobalResources(typeId);
          break;
        }

        for (const option of options) {
          try {
            const instance = await this.dataInstanceRepository.get(option);
            const name = this.getValueToDisplay(instance);
            loadedChoices.push(new Resource({ name: name, value: option }));
          } catch (e) {
            Logger.warn(`Failed to load resource ${option}: ${e}`);
            loadedChoices.push(new Resource({ name: option, value: option }));
          }
        }

        break;
      }

      case 'global': {
        loadedChoices = await this.getGlobalResources(typeId);
        break;
      }

      default: {
        // Default (when the field editor scope is not set) is to use the global scope
        loadedChoices = await this.getGlobalResources(typeId);
        break;
      }
    }

    loadedChoices.sort((a, b) =>
      ((a.name as string | string[]) instanceof Array ? String(a.name[0]) : a.name).localeCompare(
        (b.name as string | string[]) instanceof Array ? String(b.name[0]) : b.name,
      ),
    );

    return loadedChoices.map(
      (choice) =>
        new SelectTypeOption({
          optionId: choice.value,
          label: choice.name,
        }),
    );
  }

  private async getGlobalResources(typeId: string): Promise<Resource[]> {
    const loadedChoices: Resource[] = [];
    const referencedType = FieldTypes.getReferencedTypeId(typeId) ?? typeId;

    const structTypeIds = await this.structTypeRepository.getAllIds();
    if (structTypeIds.includes(referencedType)) {
      const structInstances = await this.dataInstanceRepository.getAllByStructTypeId(referencedType);
      for (const instance of structInstances) {
        loadedChoices.push(
          new Resource({
            name: this.getValueToDisplay(instance),
            value: await instance.identifier,
          }),
        );
      }

      return loadedChoices;
    }

    const enumTypeIds = await this.enumTypeRepository.getAllIds();
    if (enumTypeIds.includes(referencedType)) {
      const enumType = await this.enumTypeRepository.get(referencedType);
      const enumTypeOptions = enumType.options.slice();

      for (const struct of enumTypeOptions) {
        const structInstances = await this.dataInstanceRepository.getAllByStructTypeId(struct);
        for (const instance of structInstances) {
          loadedChoices.push(
            new Resource({
              name: this.getValueToDisplay(instance),
              value: await instance.identifier,
            }),
          );
        }
      }

      return loadedChoices;
    }

    return loadedChoices;
  }

  private getValueToDisplay(instance: DataInstance) {
    if (this.data.field.fieldEditor && this.data.field.fieldEditor.displayField) {
      try {
        const field = instance.fieldValues[this.data.field.fieldEditor.displayField];
        const value = field!.getDeserializedValue(FieldType.STRING, field!.value) as string;
        if (value === '') return instance.getName();
        return value;
      } catch (e) {
        console.warn(`Display field ${this.data.field.fieldEditor.displayField} not found for instance ${instance.identifier}: ${e}`);
        return instance.getName();
      }
    }
    return instance.getName();
  }

  private async searchParentForField(parentUid: string, fieldId: string): Promise<string[]> {
    const parent = await this.dataInstanceRepository.get(parentUid);
    if (!parent) {
      console.error('Datainstance not found with uid ' + parentUid);
      return [];
    }

    this.checkedDataInstances.push(parentUid);

    const structTypeIds = await this.structTypeRepository.getAllIds();
    if (structTypeIds.includes(parent.dataType)) {
      const parentStructType = await this.structTypeRepository.get(parent.dataType);

      const fieldFromParent = Object.values(parentStructType.fields).find((field) => {
        if (!FieldTypes.isListType(field.type)) return false;

        const referencedTypeId = FieldTypes.getReferencedTypeId(field.type);
        if (!referencedTypeId) return false;

        return (
          (FieldTypes.matches('STRUCT_MATCHER', referencedTypeId) || FieldTypes.matches('ENUM_MATCHER', referencedTypeId)) &&
          FieldTypes.getReferencedTypeId(referencedTypeId) === fieldId
        );
      });

      if (fieldFromParent) {
        const field = parent.fieldValues[fieldFromParent.fieldId];
        return field ? (field.getDeserializedValue(FieldType.LIST, field.value) as string[]) : [];
      }

      const nextParents = Object.values(this.dataInstanceRepository.getCached()).filter((dataInstance) => {
        const fieldInNextParent = Object.values(dataInstance.fieldValues).find((fv) =>
          Try(() => {
            if (!fv) return false;

            if (FieldTypes.isListType(fv.field.type)) {
              const parsed = fv.getDeserializedValue(FieldType.LIST, fv.value) as string[];
              return parsed?.includes(parentUid);
            }

            return fv.value === parentUid;
          }),
        );

        return !!fieldInNextParent;
      });

      for (const nextParent of nextParents) {
        const identifier = await nextParent.identifier;
        if (this.checkedDataInstances.indexOf(identifier) >= 0) continue;
        const result = await this.searchParentForField(identifier, fieldId);
        if (result.length > 0) return result;
      }

      return [];
    } else {
      console.error('Parent with uid ' + parentUid + ' is not a struct');
      return [];
    }
  }
}
