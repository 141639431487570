<div class="modal-header">
  <h4 class="modal-title">Confirmation</h4>
  <button (click)="activeModal.dismiss()" aria-label="Close" class="btn-close" type="button"></button>
</div>
<div class="modal-body">
  {{ message }}
</div>
<div class="modal-footer">
  <button (click)="activeModal.close(false)" class="btn btn-secondary" type="button">Cancel</button>
  <button (click)="activeModal.close(true)" class="btn btn-danger" id="confirmationModal-confirm" type="button">Confirm</button>
</div>
