import { Component, OnInit } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { AlertData } from '@services/types/AlertData';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  alerts: AlertData[] = [];

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.alertService.alert$.subscribe((data) => {
      this.alerts = data;
    });
  }
}
