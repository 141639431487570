import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DynamicFieldService, FieldDefinition } from '@services/dynamic-field.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { DataInstance } from '@services/entities';
import { DataInstanceRepository, StructTypeRepository } from '@services/repositories';
import { Logger } from '@services/utils';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-struct-instance-editor',
  templateUrl: './struct-instance-editor.component.html',
  styleUrls: ['./struct-instance-editor.component.scss'],
})
export class StructInstanceEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input({ required: true }) data!: DataInstance;
  @Input() isSeamlessInline = false;

  @Input() resourceStructType?: string;
  @Input() currentResourceUid?: string;
  @Input() titleOverride?: string;
  @Input() icon?: string;
  @Input() showMedia = true;
  @Input() showDeleteButton = false;
  @Input() showHeader = true;
  @Input() hideIfEmpty = false;

  @Output() deleteStructInstance: EventEmitter<void> = new EventEmitter<void>();

  currentResourceFieldComponents?: FieldDefinition[];

  routeSub?: Subscription;

  structTypeDescription: string = '';

  constructor(
    private route: ActivatedRoute,
    private loadingScreenService: LoadingScreenService,
    private dataInstanceRepository: DataInstanceRepository,
    private structTypeRepository: StructTypeRepository,
    private navigationService: NavigationService,
  ) {}

  async ngOnInit() {
    // If this component is not used as a child component,
    // we need to get the resourceStructType and currentResourceUid from the route
    if (!this.data && (!this.resourceStructType || !this.currentResourceUid)) {
      this.routeSub = this.route.params.subscribe((params) => {
        this.resourceStructType = params[this.navigationService.queryParamKeys.ResourceStructType];
        this.currentResourceUid = params[this.navigationService.queryParamKeys.Resource];
        return this.refreshData(true);
      });
    }

    await this.refreshData();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      (changes['data'] && !changes['data'].firstChange) ||
      (changes['currentResourceUid'] && !changes['currentResourceUid'].firstChange)
    ) {
      await this.refreshData();
    }
  }

  onDelete() {
    this.deleteStructInstance.emit();
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
  }

  async refreshData(newResource = false) {
    return await this.loadingScreenService.show(async () => {
      if (!this.data || newResource) {
        if (!this.currentResourceUid) throw new Error('Resource uid not found');
        this.data = await this.dataInstanceRepository.get(this.currentResourceUid);
      }

      this.resourceStructType = this.data.dataType;
      this.currentResourceUid = await this.data.identifier;

      if (this.resourceStructType) {
        this.structTypeRepository.get(this.resourceStructType).then((type) => (this.structTypeDescription = type.description ?? ''));
      }

      if (this.resourceStructType && !this.currentResourceUid) {
        // If the resource struct type is set, but the resource uid is not, we need to create a new resource, but the user has to do that
        Logger.warn('Resource uid not found');
        return;
      }

      if (!this.resourceStructType || !this.currentResourceUid) {
        Logger.warn(`Resource struct type (${this.resourceStructType}) or resource uid (${this.currentResourceUid}) not found`);
        return;
      }

      this.currentResourceFieldComponents = await DynamicFieldService.getFieldComponents(
        this.data,
        this.resourceStructType,
        this.showMedia,
        this.isSeamlessInline,
      );

      // Sort the fields by position
      if (this.currentResourceFieldComponents.some((field) => field.data?.field.fieldEditor)) {
        this.currentResourceFieldComponents.sort((a, b) => {
          // Provide a default position value when fieldEditor is undefined
          const positionA = a.data?.field.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
          const positionB = b.data?.field.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
          return positionA - positionB;
        });
      }
    });
  }
}
