import { Injectable } from '@angular/core';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { DataInstanceRepository, StructTypeRepository } from '@services/repositories';
import { DataInstance, StructType } from '@services/entities';

export interface SimpleStructInstance {
  uid: string;
  fields: Record<string, FieldValue>;
  structType: string;
}

@Injectable()
export class SubcomponentService {
  constructor(
    private dataInstanceRepository: DataInstanceRepository,
    private structTypeRepository: StructTypeRepository,
  ) {}

  async addSubStruct(structType: StructType): Promise<SimpleStructInstance> {
    const subStruct = await this.dataInstanceRepository.create(structType.typeId);
    const subStructData: Record<string, FieldValue> = {};

    for (const field of Object.values(structType.fields)) {
      if (!field.required) continue;
      // TODO @Bas: This might cause issues with the new classes system where things are referenced incorrectly..
      subStructData[field.fieldId] = subStruct.fieldValues[field.fieldId]!;
    }

    return {
      uid: await subStruct.identifier,
      fields: subStructData,
      structType: structType.typeId,
    } satisfies SimpleStructInstance;
  }

  async convertArrayData(data: FieldValue): Promise<SimpleStructInstance[]> {
    const value = data.getDeserializedValue(FieldType.LIST, data.value) as string[];
    return await Promise.all(value.map(async (reference) => await this.convertData(reference)));
  }

  async convertData(referenceUid: string): Promise<SimpleStructInstance> {
    const dataInstance = await this.dataInstanceRepository.get(referenceUid);
    if (!dataInstance) throw new Error('Data instance not found');

    const subStructData: Record<string, FieldValue> = {};
    const structType = await this.structTypeRepository.get(dataInstance.dataType);

    for (const fieldId in structType.fields) {
      // TODO @Bas: This might mess up with the new class system where things are referenced incorrectly..
      subStructData[fieldId] = dataInstance.fieldValues[fieldId]!;
    }

    return { uid: await dataInstance.identifier, fields: subStructData, structType: structType.typeId };
  }

  // Remove field from array and from the data instance
  async removeSubStruct(
    subStructData: { uid: string; fields: Record<string, FieldValue> },
    fieldList: { uid: string; fields: Record<string, FieldValue> }[],
    parentDataInstance: DataInstance,
    fieldId: string,
  ) {
    // Delete subfield from fieldList
    const index = fieldList.indexOf(subStructData);
    fieldList.splice(index, 1);

    // Delete subfield reference from parent data instance
    const updatedValue = parentDataInstance.fieldValues[fieldId];
    if (!updatedValue) return;

    await updatedValue.set(fieldList.map((subStructData: { uid: string; fields: Record<string, FieldValue> }) => subStructData.uid));

    // Delete subfield data instance
    const instance = await this.dataInstanceRepository.get(subStructData.uid);
    if (!instance) throw new Error('Subfield data instance not found');

    await this.dataInstanceRepository.delete(instance, true);
  }

  // /* METHODS FOR MOVING FIELDS IN ARRAYS */
  isLastField(field: SimpleStructInstance, fieldList: SimpleStructInstance[]) {
    return fieldList.indexOf(field) == fieldList.length - 1;
  }

  isFirstField(field: SimpleStructInstance, fieldList: SimpleStructInstance[]) {
    return fieldList.indexOf(field) == 0;
  }

  moveFieldUp(field: SimpleStructInstance, fieldList: SimpleStructInstance[], dataInstance: DataInstance, fieldId: string) {
    const index = fieldList.indexOf(field);
    return this.moveField(index, index - 1, fieldList, dataInstance, fieldId);
  }

  moveFieldDown(field: SimpleStructInstance, fieldList: SimpleStructInstance[], dataInstance: DataInstance, fieldId: string) {
    const index = fieldList.indexOf(field);
    return this.moveField(index, index + 1, fieldList, dataInstance, fieldId);
  }

  async moveField(from: number, to: number, fieldList: SimpleStructInstance[], dataInstance: DataInstance, fieldId: string) {
    // Remove `from` item and store it
    const f = fieldList.splice(from, 1)[0];
    // insert stored item into position `to`
    fieldList.splice(to, 0, f);

    // Update the data instance
    const updatedValue = dataInstance.fieldValues[fieldId];
    if (updatedValue) {
      await updatedValue.set(fieldList.map((field) => field.uid));
    }
  }
}
