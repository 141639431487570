import { ClassicPreset as Classic, GetSchemes } from 'rete';

type Node = ActivityNode | KennisNode;
type Conn = Connection<ActivityNode, ActivityNode> | Connection<KennisNode, KennisNode>;
export type Schemes = GetSchemes<Node, Conn>;

export class Connection<A extends Node, B extends Node> extends Classic.Connection<A, B> {}

const socket = new Classic.Socket('socket');

export class ActivityNode extends Classic.Node {
  activityType: string;
  backgroundColor: string;
  uid: string;
  width = 256;
  height = 100;

  constructor(name: string, activityType: string, uid: string, backgroundColor: string) {
    super(name);
    this.activityType = activityType;
    this.uid = uid;
    this.backgroundColor = backgroundColor;
    this.addOutput(this.uid, new Classic.Output(socket, 'Next activity'));
    this.addInput(this.uid, new Classic.Input(socket, 'Input', true));
  }
}

export class KennisNode extends Classic.Node {
  kennisNodeType: string;
  uid: string;
  width = 180;
  height = 119;

  constructor(name: string, kennisNodeType: string, uid: string) {
    super(name);
    this.kennisNodeType = kennisNodeType;
    this.uid = uid;
    this.addOutput(this.uid, new Classic.Output(socket, 'Outgoing'));
    this.addInput(this.uid, new Classic.Input(socket, 'Input', true));
  }
}
