import { Component, Input, OnInit } from '@angular/core';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldValue } from '@services/entities/helpers';

@Component({
  selector: 'app-video-field',
  templateUrl: './video-field.component.html',
  styleUrls: ['./video-field.component.scss'],
})
export class VideoFieldComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value!: string;

  currentURL = '';
  currentName?: string;
  currentMimeType?: string = 'video/mp4';

  ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.VIDEO_REF, this.data.value);
  }

  changeVideo(videoUrl: { name: string; url: string; mimeType: string }) {
    this.currentURL = videoUrl.url;
    this.currentName = videoUrl.name;
    this.currentMimeType = videoUrl.mimeType;
  }
}
