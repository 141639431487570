import { FieldEditor } from './FieldEditor';
import { GeneratedField, GeneratedFieldValidation } from '../../types/generated';
import { FieldType, FieldTypes } from '@services/entities/helpers';
import { Serializable } from '../../types/Serializable';
import { Color, Logger, Vector2, Vector3 } from '../../utils';
import { DeserializedFieldValueType } from './FieldValue';
import GTInjector from '../../GTInjector';
import { DataInstanceRepository, EnumTypeRepository, SelectTypeRepository } from '@services/repositories';
import { Tag } from '@services/entities';
import { FieldValidation } from '@services/entities/helpers/FieldValidation';

export interface NewField {
  fieldId: string;
  type: string;
  name: string;
  description?: string;
  required: boolean;
  casOnly: boolean;
  deprecated: boolean;
  tags: Tag[];
  defaultValue?: string;
  fieldEditor?: FieldEditor;
  fieldValidations?: FieldValidation[];
}

export class Field implements Serializable<GeneratedField> {
  public readonly fieldId: string;
  public readonly type: string;

  public name: string;
  public description?: string;
  public required: boolean;
  public casOnly: boolean;
  public deprecated: boolean;
  public tags: Tag[];
  public defaultValue?: string;
  public fieldEditor?: FieldEditor;
  public fieldValidations?: FieldValidation[];

  constructor({
    fieldId,
    type,
    name,
    description,
    required,
    casOnly,
    deprecated,
    tags,
    defaultValue,
    fieldEditor,
    fieldValidations,
  }: NewField) {
    this.fieldId = fieldId;
    this.type = type;
    this.name = name;
    this.description = description;
    this.required = required;
    this.casOnly = casOnly;
    this.deprecated = deprecated;
    this.tags = tags;
    this.defaultValue = defaultValue;
    this.fieldEditor = fieldEditor;
    this.fieldValidations = fieldValidations;
  }

  public static async deserialize(data: GeneratedField): Promise<Field> {
    if (!(await FieldTypes.isValid(data.type))) {
      throw new Error(`Failed to deserialize field due to an invalid field type: "${data.type}"`);
    }

    return new Field({
      fieldId: data.fieldId,
      type: data.type,
      name: data.name,
      description: data.description,
      required: data.required,
      casOnly: data.casOnly,
      deprecated: data.deprecated,
      tags: data.tags ? await Promise.all(data.tags.map((tag) => Tag.deserialize(tag))) : [],
      defaultValue: data.defaultValue,
      fieldEditor: data.fieldEditor ? await FieldEditor.deserialize(data.fieldEditor) : undefined,
      fieldValidations: data.fieldValidations
        ? await Promise.all(data.fieldValidations.map((fieldValidation) => FieldValidation.deserialize(fieldValidation)))
        : [],
    });
  }

  public async serialize(): Promise<Readonly<GeneratedField>> {
    return Object.freeze({
      fieldId: this.fieldId,
      type: this.type,
      name: this.name,
      description: this.description,
      required: this.required,
      casOnly: this.casOnly,
      deprecated: this.deprecated,
      tags: await Promise.all(this.tags.map((tag) => tag.serialize())),
      defaultValue: this.defaultValue,
      fieldEditor: await this.fieldEditor?.serialize(),
      fieldValidations: this.fieldValidations
        ? await Promise.all(this.fieldValidations.map((fieldValidation) => fieldValidation.serialize()))
        : [],
    });
  }

  public async getDefault(): Promise<DeserializedFieldValueType> {
    if (this.defaultValue) {
      // Enums can have a default value which is a struct that needs to be created
      if (await FieldTypes.isEnumTypeValid(this.type)) {
        const dataInstanceRepository = await GTInjector.inject(DataInstanceRepository);
        return await this.createEnumSubStruct(dataInstanceRepository);
      }
      return this.defaultValue;
    }

    switch (this.type) {
      case FieldType.INT:
        return 0;
      case FieldType.FLOAT:
        return 0.0;
      case FieldType.VECTOR2:
        return new Vector2([0, 0]);
      case FieldType.VECTOR3:
        return new Vector3([0, 0, 0]);
      case FieldType.COLOR:
        return new Color('#000000');
      case FieldType.BOOLEAN:
        return false;
      case FieldType.STRING:
      case FieldType.ICON:
      case FieldType.IMAGE_REF:
      case FieldType.AUDIO_REF:
      case FieldType.VIDEO_REF:
      case FieldType.FILE_REF:
      case FieldType.VARIABLE_REF:
        return '';
      case FieldType.STRUCT:
      case FieldType.STRUCT_REF:
      case FieldType.ENUM:
      case FieldType.ENUM_REF:
      case FieldType.SELECT:
        throw new Error(`Failed to get default value for field of type "${this.type}"`);
      case FieldType.LIST:
        return [];
    }

    if (FieldTypes.isListType(this.type)) {
      return [];
    }

    const dataInstanceRepository = await GTInjector.inject(DataInstanceRepository);

    if (FieldTypes.matches('STRUCT_REF_MATCHER', this.type) || FieldTypes.matches('ENUM_REF_MATCHER', this.type)) {
      return '';
    }

    if (await FieldTypes.isStructTypeValid(this.type)) {
      const referencing = FieldTypes.getReferencedTypeId(this.type);
      if (!referencing) throw new Error(`Failed to get default value for field of type "${this.type}"`);

      if (this.required) {
        return await dataInstanceRepository.create(referencing).then((i) => i.identifier);
      }

      Logger.warn(`Struct ${referencing} is not required, returning nothing`);
      return '';
    }

    if (await FieldTypes.isEnumTypeValid(this.type)) {
      if (this.required) {
        return await this.createEnumSubStruct(dataInstanceRepository);
      }

      Logger.warn(`Enum ${FieldTypes.getReferencedTypeId(this.type)} is not required, returning nothing`);
      return '';
    }

    if (FieldTypes.matches('SELECT_MATCHER', this.type)) {
      Logger.warn(`No default value configured for "${this.type}" field. Returning the first value!`);

      const selectType = await (await GTInjector.inject(SelectTypeRepository)).get(FieldTypes.getReferencedTypeId(this.type)!);
      return selectType.options[0]!.optionId;
    }

    throw new Error(`Failed to get default value for field of type "${this.type}"`);
  }

  public getFailedFieldValidations(value: string): FieldValidation | undefined {
    if (!this.fieldValidations) return;

    for (const validation of this.fieldValidations) {
      if (
        validation.validationType === GeneratedFieldValidation.ValidationTypeEnum.MinLength &&
        value.length < parseInt(validation.validationValue)
      ) {
        return validation;
      }
      if (
        validation.validationType === GeneratedFieldValidation.ValidationTypeEnum.MaxLength &&
        value.length > parseInt(validation.validationValue)
      ) {
        return validation;
      }
      if (
        validation.validationType === GeneratedFieldValidation.ValidationTypeEnum.Regex &&
        !new RegExp(validation.validationValue).test(value)
      ) {
        return validation;
      }
    }
    return;
  }

  private async createEnumSubStruct(dataInstanceRepository: DataInstanceRepository): Promise<string> {
    const referencing = FieldTypes.getReferencedTypeId(this.type);
    if (!referencing) throw new Error(`Failed to get default value for field of type "${this.type}"`);

    const enumTypeRepository = await GTInjector.inject(EnumTypeRepository);
    const enumType = await enumTypeRepository.get(referencing);
    const structType = enumType.options[0];

    return await dataInstanceRepository.create(structType).then((i) => i.identifier);
  }
}
