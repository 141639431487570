import { GeneratedTag } from '../types/generated';
import { AutoSave } from '../decorators/AutoSave';
import { Entity } from '@services/entities/Entity';
import GTInjector from '@services/GTInjector';
import { TagRepository } from '@services/repositories';
import { Color } from '@services/utils';

interface NewTag {
  name: string;
  color: string;
  uid: string;
  scope: GeneratedTag.ScopeEnum;
}

@AutoSave()
export class Tag extends Entity {
  public name: string;
  public uid: string;
  public scope: GeneratedTag.ScopeEnum;
  public contrastColor: string;
  public isDefault: boolean;

  constructor({ name, color, uid, scope }: NewTag) {
    super();
    this.name = name;
    this._color = color;
    this.uid = uid;
    this.scope = scope;
    this.contrastColor = Color.getContrastColor(color);
    this.isDefault = this.isDefaultTag();
  }

  private _color: string;

  public get color() {
    return this._color;
  }

  public set color(color: string) {
    this._color = color;
    this.contrastColor = Color.getContrastColor(color);
  }

  public override get identifier() {
    return this.uid;
  }

  public static async deserialize(data: GeneratedTag): Promise<Tag> {
    const tag = new Tag(data);
    await tag.updateLastSavedValueHash();
    return tag;
  }

  /**
   * @deprecated This method should be rarely used by a developer because @AutoSave() should handle most cases. If you
   * find any cases where auto saving does not work, please open a bug report so it can be fixed.
   */
  public async save() {
    return (await GTInjector.inject(TagRepository)).save(this);
  }

  public async serialize(): Promise<Readonly<GeneratedTag>> {
    return Object.freeze({
      name: this.name,
      color: this.color,
      uid: this.uid,
      scope: this.scope,
    });
  }

  private isDefaultTag(): boolean {
    return (
      this.uid === 'tag_defaultStructTag' ||
      this.uid === 'tag_defaultEnumTag' ||
      this.uid === 'tag_defaultSelectTag' ||
      this.uid === 'tag_defaultResourceTag'
    );
  }
}
