import { Injectable } from '@angular/core';
import { ApiBase } from './ApiBase';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserEndpoints extends ApiBase {
  public getIsContentEditor(): Observable<boolean> {
    return this.get<boolean>(`/user/me/is-content-editor`);
  }
}
