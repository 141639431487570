import { Injectable } from '@angular/core';
import { ApiBase } from './ApiBase';
import { Observable } from 'rxjs';
import { GeneratedEnumType, GeneratedSchema, GeneratedSchemaUpdateLog, GeneratedSelectType, GeneratedStructType } from '../types/generated';

export type SchemaUpdate = {
  type: string;
  data: Readonly<GeneratedStructType> | Readonly<GeneratedEnumType> | Readonly<GeneratedSelectType>;
};

@Injectable({ providedIn: 'root' })
export class SchemaEndpoints extends ApiBase {
  public getSchema(): Observable<GeneratedSchema> {
    return this.get<GeneratedSchema>(`/game/${this.gameId}/schema`);
  }

  updateSchema(
    selectTypes: GeneratedSelectType[],
    structTypes: GeneratedStructType[],
    enumTypes: GeneratedEnumType[],
  ): Observable<GeneratedSchemaUpdateLog[]> {
    return this.put<GeneratedSchemaUpdateLog[]>(`/game/${this.gameId}/schema`, {
      selectTypes: selectTypes,
      structTypes: structTypes,
      enumTypes: enumTypes,
    });
  }
}

export * from './schema/EnumTypeEndpoints';
export * from './schema/SelectTypeEndpoints';
export * from './schema/StructTypeEndpoints';
