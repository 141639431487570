import { Entity } from './Entity';
import { AutoSave } from '../decorators/AutoSave';
import { GeneratedVariable } from '../types/generated';
import GTInjector from '../GTInjector';
import { VariableRepository } from '@services/repositories';
import { VariableType } from '../types/VariableType';
import { Tag } from '@services/entities/Tag';

export interface NewVariable {
  variableRef: string;
  name: string;
  description?: string;
  valueType?: VariableType;
  startValue?: string;
  isPlayThroughStatistic?: boolean;
  tags: Tag[];
  modified: string;
}

@AutoSave()
export class Variable extends Entity {
  public readonly variableRef: string;

  public name: string;
  public description?: string;
  public valueType?: VariableType;
  public startValue?: string;
  public isPlayThroughStatistic?: boolean;
  public tags: Tag[] = [];
  public readonly modified: string;

  constructor({ variableRef, name, description, valueType, startValue, isPlayThroughStatistic, tags, modified }: NewVariable) {
    super();
    this.variableRef = variableRef;
    this.name = name;
    this.description = description;
    this.valueType = valueType;
    this.startValue = startValue;
    this.isPlayThroughStatistic = isPlayThroughStatistic;
    this.tags = tags;
    this.modified = modified;
  }

  public override get identifier() {
    return this.variableRef;
  }

  public static async deserialize(data: GeneratedVariable): Promise<Variable> {
    if (data.valueType && !Object.values(VariableType).includes(data.valueType as VariableType)) {
      throw new Error(`Invalid variable type ${data.valueType}`);
    }

    const variable = new Variable({
      variableRef: data.variableRef,
      name: data.name,
      description: data.description,
      valueType: data.valueType as VariableType | undefined,
      startValue: data.startValue,
      isPlayThroughStatistic: data.isPlayThroughStatistic,
      tags: await Promise.all(data.tags.map((tag) => Tag.deserialize(tag))),
      modified: data.modified ?? '',
    });

    await variable.updateLastSavedValueHash();
    return variable;
  }

  /**
   * @deprecated This method should be rarely used by a developer because @AutoSave() should handle most cases. If you
   * find any cases where auto saving does not work, please open a bug report so it can be fixed.
   */
  public async save() {
    return (await GTInjector.inject(VariableRepository)).save(this);
  }

  public async serialize(): Promise<Readonly<GeneratedVariable>> {
    return Object.freeze({
      variableRef: this.variableRef,
      name: this.name,
      description: this.description,
      valueType: this.valueType,
      startValue: this.startValue,
      isPlayThroughStatistic: this.isPlayThroughStatistic,
      tags: await Promise.all(Object.values(this.tags).map((tag) => tag.serialize())),
      modified: this.modified,
    });
  }
}
