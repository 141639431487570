import { Component } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { AlertService } from '@services/UI-elements/alert-service';
import { NavigationService } from '@services/navigation.service';
import { replaceBackticksWithElement } from '@services/utils';
import { MaintenanceEndpoints } from '@services/api';
import {
  GeneratedDataCleanupActionRequest,
  GeneratedDataCleanupAnalyzeResult,
  GeneratedDataCleanupAnalyzeResultItem,
  GeneratedDataCleanupResponse,
} from '@services/types/generated';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';

@Component({
  selector: 'app-maintenance-screen',
  templateUrl: './maintenance-screen.component.html',
})
export class MaintenanceScreenComponent {
  protected selectedCleanupActions: Record<string, boolean> = {};
  protected selectedCleanupActionsCount = 0;

  protected $analyzeRequest?: Observable<GeneratedDataCleanupAnalyzeResult>;
  protected analyzedData?: GeneratedDataCleanupAnalyzeResult;

  protected $cleanupRequest?: Observable<GeneratedDataCleanupResponse>;
  protected cleanupResponse?: GeneratedDataCleanupResponse;

  protected dataTypes?: Record<string, number>;
  protected suggestedActions?: Record<GeneratedDataCleanupActionRequest.ActionEnum, number>;

  protected readonly DataCleanupAction = GeneratedDataCleanupActionRequest.ActionEnum;
  protected readonly Object = Object;

  protected $clearCacheRequest?: Observable<void>;

  constructor(
    private alertService: AlertService,
    private navigationService: NavigationService,
    private maintenanceEndpoints: MaintenanceEndpoints,
    private confirmService: ConfirmationModalService,
  ) {
    if (this.analyzedData?.items) this.setCleanupActions(this.analyzedData.items);
  }

  runAnalyzeData() {
    this.$cleanupRequest = undefined;
    this.cleanupResponse = undefined;

    this.analyzedData = undefined;
    this.$analyzeRequest = this.maintenanceEndpoints.analyze();

    const subscription = this.$analyzeRequest.subscribe({
      next: (result) => {
        this.analyzedData = {
          ...result,
          items: result.items.map((item) => ({
            ...item,
            description: replaceBackticksWithElement(item.description, 'code'),
          })),
        };

        this.dataTypes = result.items.reduce(
          (acc, item) => {
            acc[item.dataType] = (acc[item.dataType] || 0) + 1;
            return acc;
          },
          {} as Record<string, number>,
        );

        this.suggestedActions = result.items.reduce(
          (acc, item) => {
            acc[item.action] = (acc[item.action] || 0) + 1;
            return acc;
          },
          {} as Record<GeneratedDataCleanupActionRequest.ActionEnum, number>,
        );

        this.setCleanupActions(result.items);
        this.$analyzeRequest = undefined;
        subscription.unsubscribe();
      },
      error: () => {
        this.alertService.error(`Failed to analyze data. Check the console for more information.`);
        this.$analyzeRequest = undefined;
        subscription.unsubscribe();
      },
    });
  }

  runSelectedActions() {
    if (!this.selectedCleanupActionsCount || !this.analyzedData) return;

    const confirmation = confirm(
      `Are you sure you want all ${this.selectedCleanupActionsCount} action(s)? This action is destructive and cannot be undone.`,
    );
    if (!confirmation) return;

    const actions = Object.entries(this.selectedCleanupActions)
      .filter(([, selected]) => selected)
      .map(([dataInstanceUid]) => {
        const item = this.analyzedData?.items.find((item) => item.dataInstanceUid + ':' + item.fieldValue === dataInstanceUid);
        if (!item) throw new Error(`Could not find action for ${dataInstanceUid}`);

        return {
          dataInstanceUid,
          action: item.action,
          fieldId: item.fieldId,
          fieldValue: item.fieldValue,
        } satisfies GeneratedDataCleanupActionRequest;
      });

    this.$cleanupRequest = this.maintenanceEndpoints.performDataCleanup(actions);

    this.analyzedData = undefined;
    this.$analyzeRequest = undefined;

    this.selectedCleanupActions = {};
    this.updateSelectionCount();

    const subscription = this.$cleanupRequest.subscribe((result) => {
      this.cleanupResponse = result;
      subscription.unsubscribe();
      this.$cleanupRequest = undefined;
    });
  }

  updateSelectionCount() {
    this.selectedCleanupActionsCount = Object.values(this.selectedCleanupActions).filter(Boolean).length;
  }

  async clearCache() {
    this.$clearCacheRequest = this.maintenanceEndpoints.clearCache();

    try {
      await lastValueFrom(this.$clearCacheRequest);
      this.alertService.success('Cache cleared');
    } catch (err) {
      void err;
      this.alertService.error('Failed to clear cache');
    } finally {
      this.$clearCacheRequest = undefined;
    }
  }

  async clearMediaCache() {
    const confirmed = await lastValueFrom(
      this.confirmService.confirm(
        'Are you sure you want to clear the media cache? This will delete all the cached media files from the server. ' +
          'This means that all the images you see in the game will have to be re-processed (scaling or compressing) ' +
          'before they can be shown again. This will cause the game to be slower to load the first time.',
      ),
    );

    if (!confirmed) return;

    this.$clearCacheRequest = this.maintenanceEndpoints.clearMediaCache();

    try {
      await lastValueFrom(this.$clearCacheRequest);
      this.alertService.success('Media cache cleared');
    } catch (err) {
      void err;
      this.alertService.error('Failed to clear media cache');
    } finally {
      this.$clearCacheRequest = undefined;
    }
  }

  async clickItem(resultItem: GeneratedDataCleanupAnalyzeResultItem) {
    if (resultItem.action !== GeneratedDataCleanupActionRequest.ActionEnum.ManualFix) {
      const id = resultItem.dataInstanceUid + ':' + resultItem.fieldValue;
      this.selectedCleanupActions[id] = !this.selectedCleanupActions[id];
      return;
    }

    const [segments] = await this.navigationService.findDataInstanceUrl(resultItem.dataInstanceUid);
    window.open(segments.join('/'));
  }

  private setCleanupActions(items: GeneratedDataCleanupAnalyzeResultItem[]) {
    this.selectedCleanupActions = items.reduce(
      (acc, item) => {
        acc[item.dataInstanceUid + ':' + item.fieldValue] = item.action !== GeneratedDataCleanupActionRequest.ActionEnum.ManualFix;
        return acc;
      },
      {} as Record<string, boolean>,
    );

    this.updateSelectionCount();
  }
}
