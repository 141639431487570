import { Repository } from './Repository';
import { Variable } from '@services/entities';
import { Injectable } from '@angular/core';
import { VariableEndpoints } from '../api';
import { lastValueFrom, map, shareReplay } from 'rxjs';
import { GeneratedVariable } from '../types/generated';
import { Cache } from '../utils';
import { DirtyHandling } from '@services/decorators/DirtyHandling';

@Injectable({ providedIn: 'root' })
export class VariableRepository extends Repository<Variable> {
  private readonly cache = new Cache<Variable>();

  constructor(private variableEndpoints: VariableEndpoints) {
    super();
  }

  @DirtyHandling()
  public override async save(entity: Variable): Promise<void> {
    await lastValueFrom(this.variableEndpoints.updateVariable(entity.variableRef, await entity.serialize()));
  }

  public override async delete(entity: Variable): Promise<void> {
    await lastValueFrom(this.variableEndpoints.deleteVariable(entity.variableRef));
    this.cache.invalidate(entity.variableRef);
  }

  public override async create(data: GeneratedVariable): Promise<Variable> {
    return this.cache.set(
      data.variableRef,
      await Variable.deserialize(await lastValueFrom(this.variableEndpoints.createVariable(data))),
      5,
    );
  }

  public async getAll(): Promise<Variable[]> {
    return await Promise.all(
      (await lastValueFrom(this.variableEndpoints.getAllVariables())).map(async (variable) => {
        if (this.cache.isValid(variable.variableRef)) return this.cache.get(variable.variableRef)!.value;
        return this.cache.set(variable.variableRef, await Variable.deserialize(variable), 5);
      }),
    );
  }

  public override async get(id: string, skipCache: boolean = false): Promise<Variable> {
    if (!skipCache && this.cache.isValid(id)) {
      return this.cache.get(id)!.value;
    }

    this.requests[id] = this.variableEndpoints.getVariable(id).pipe(
      map(async (response) => this.cache.set(id, await Variable.deserialize(response), 5)),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.requests[id]);
    delete this.requests[id];
    return data;
  }
}
