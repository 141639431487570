import { Serializable } from '../types/Serializable';
import { Identifiable } from '../types/Identifiable';
import { Savable } from '../types/Savable';
import { Hash } from '@services/utils';

export abstract class Entity implements Serializable, Identifiable, Savable {
  // This variable is public because it is also set in the DirtyHandling decorator to improve performance
  public lastSavedValueHash = '';

  /**
   * Returns a string that uniquely identifies this entity within its repository.
   * Should be overridden by subclasses where possible.
   * This is used in the AutoSave decorator to log the entity when it is saved.
   */
  public abstract get identifier(): string | Promise<string>;

  public abstract save(): Promise<void>;

  public abstract serialize(): Promise<Readonly<object>>;

  public async updateLastSavedValueHash() {
    this.lastSavedValueHash = await this.getCurrentValueHash();
  }

  /**
   * Calculates the hash of the entity's current state.
   * This is done by serializing the entity and then hashing the resulting object.
   * The hash is a unique string that can be used to compare the entity's state with its last saved state.
   *
   * Note: Use sparingly as it will serialize the entity's entire state.
   */
  public async getCurrentValueHash() {
    const data = await this.serialize();
    return await Hash.object(data);
  }
}
