<div *ngIf="shown" class="search-parent d-grid place-items-center">
  <div class="search-results-container d-flex justify-content-center w-100">
    <div #searchContainer class="search-container h-fit rounded-3 w-100 shadow-lg bg-body">
      <div [ngClass]="{ 'pb-1': !isLoading && searchResponse && searchResponse.results!.length }" class="p-3">
        <input
          #input
          (keydown)="inputKeyDown($event)"
          [(ngModel)]="searchString"
          class="form-control w-100"
          placeholder="Search (Ctrl + K)"
          type="text"
        />

        <div *ngIf="!isLoading && searchResponse && searchResponse.results!.length">
          <p class="text-muted m-0 small pt-1">{{ searchResponse.results!.length }} results in {{ searchResponse.searchMilliseconds }}ms</p>
        </div>
      </div>

      <hr class="my-0 mb-2 shadow-sm" />

      <div *ngIf="isLoading" class="p-3">
        <app-spinner />
      </div>

      <cdk-virtual-scroll-viewport
        #viewport
        (scrolledIndexChange)="scrolledIndex = $event"
        [itemSize]="72"
        class="overflow-x-hidden"
        style="height: 30rem"
        tabindex="-1"
      >
        <ng-container *ngIf="searchResponse?.results">
          <div
            *cdkVirtualFor="let res of searchResponse?.results; trackBy: trackByFn"
            [style]="{ height: '72px' }"
            class="px-3 py-1 mw-100"
            tabindex="-1"
          >
            <button
              (click)="navigateToResult(res)"
              class="w-100 rounded-1 btn text-left border p-2 d-flex gap-2 h-100 overflow-hidden focus-ring flex-none"
              id="list-item-{{ res.uid }}"
              tabindex="0"
            >
              <div [innerHtml]="getIcon(res.type)" class="search-icon"></div>

              <div class="d-flex flex-column justify-content-center">
                <div>{{ res.name }}</div>
                <div *ngIf="res.description.length" class="small text-muted overflow-hidden text-break-all">
                  {{ res.description }}
                </div>
              </div>
            </button>
          </div>
        </ng-container>

        <div *ngIf="!isLoading && !(searchResponse && searchResponse.results!.length)">
          <p class="text-center text-muted m-0 py-4">No results for your query</p>
        </div>
      </cdk-virtual-scroll-viewport>

      <div class="pb-3"></div>
    </div>
  </div>
</div>
