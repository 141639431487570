<ng-container
  *ngTemplateOutlet="
    editorType === FieldEditorType.SeamlessInline
      ? seamlessInlineTemplate
      : editorType === FieldEditorType.Hidden
        ? hiddenTemplate
        : template
  "
>
</ng-container>

<ng-template #seamlessInlineTemplate>
  <ng-container [appData]="data" [appField]="fieldComponent"></ng-container>
  <ng-content></ng-content>
</ng-template>

<ng-template #hiddenTemplate>
  <div></div>
</ng-template>

<ng-template #template>
  <div class="mode-container">
    <div class="input-group" id="{{ data.field.fieldId }}-field">
      <div id="{{ data.field.fieldId }}-field-label">
        <div [ngbPopover]="description" class="mode-label-above px-2 pt-1 h-100" triggers="hover">
          <span [ngStyle]="{ 'text-decoration-line': this.data.field.deprecated ? 'line-through' : 'none' }"
            >{{ name }}:
            <i
              *ngIf="fieldValidationWarning"
              [ngbPopover]="fieldValidationWarning"
              class="bi bi-exclamation-triangle-fill text-warning"
              container="body"
              triggers="hover"
            ></i
          ></span>
        </div>
        <div [ngbPopover]="description" class="mode-normal input-group-prepend h-100" triggers="hover">
          <span [ngStyle]="{ 'text-decoration-line': this.data.field.deprecated ? 'line-through' : 'none' }" class="input-group-text gap-1"
            >{{ name }}
            <i
              *ngIf="fieldValidationWarning"
              [ngbPopover]="fieldValidationWarning"
              class="bi bi-exclamation-triangle-fill text-warning"
              container="body"
              triggers="hover"
            ></i>
          </span>
        </div>
      </div>

      <div class="form-control">
        <ng-container [appData]="data" [appField]="fieldComponent"></ng-container>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
