import { CacheEntry } from '../types/CacheEntry';

/**
 * A simple cache for storing and retrieving items with a timeout.
 *
 * The cache is implemented as a simple object where the keys are the cache keys and the values are objects with two properties: `value` and `expiry`.
 * The `value` property is the cached value, and the `expiry` property is a date that represents when the cache item will expire.
 */
export class Cache<T> {
  private readonly cache: Record<string, CacheEntry<T>> = {};
  private _allKeysPresent = false;

  get allKeysPresent(): boolean {
    return this._allKeysPresent;
  }

  /**
   * Checks if a cache item is still valid.
   * @param key The cache key to check.
   * @returns True if the cache item is still valid, false if it has expired.
   */
  public isValid(key: string): key is keyof typeof this.cache {
    return key in this.cache && new Date() < this.cache[key].expiry;
  }

  /**
   * Invalidates a specific cache item or all cache items.
   * @param key The cache key to invalidate. If not provided, all cache items are invalidated.
   */
  public invalidate(key?: string): void {
    if (key !== undefined) {
      delete this.cache[key];
      return;
    }

    for (const key in this.cache) {
      delete this.cache[key];
    }
  }

  /**
   * Retrieves a cache item.
   * @param key The cache key to retrieve.
   * @returns The cache item if it is valid, undefined otherwise.
   */
  public get(key: string): CacheEntry<T> | undefined {
    return this.cache[key];
  }

  /**
   * Sets a cache item.
   * @param key The cache key to set.
   * @param value The value to cache.
   * @param durationMinutes The duration in minutes until the cache item expires.
   * @returns The cached value.
   */
  public set(key: string, value: T, durationMinutes: number): T {
    this.cache[key] = {
      value: value,
      expiry: this.getExpiryDate(durationMinutes),
    };
    return value;
  }

  /**
   * Sets all cache items for a given list of values.
   * @param values The values to cache.
   * @param keyRetriever A function that returns the cache key for a given value.
   * @param durationMinutes The duration in minutes until the cache items expire.
   * @returns The cached values.
   */
  public setAll(values: T[], keyRetriever: (value: T) => string, durationMinutes: number): T[] {
    this.invalidate();

    for (const [i, key] of values.map(keyRetriever).entries()) {
      this.cache[key] = {
        value: values[i],
        expiry: this.getExpiryDate(durationMinutes),
      };
    }

    this._allKeysPresent = true;
    return values;
  }

  /**
   * Retrieves all valid cache items.
   * @returns A map of cache key to cached value.
   */
  public getAll() {
    return Object.entries(this.cache).reduce(
      (acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      },
      {} as Record<string, T>,
    );
  }

  /**
   * Generates a date that is the given number of minutes in the future.
   * @param durationMinutes The number of minutes in the future.
   * @returns The generated date.
   */
  private getExpiryDate(durationMinutes: number): Date {
    const date = new Date();
    date.setMinutes(date.getMinutes() + durationMinutes);
    return date;
  }
}
