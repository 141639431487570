import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { decodeJwt } from 'jose';

const ACCESS_TOKEN_KEY = 'accessToken';

@Injectable({
  providedIn: 'root',
})
export class TokenManagerService {
  private accessToken: ReplaySubject<string | null> = new ReplaySubject<string | null>(1);
  private _tokenTimeout?: number;

  constructor() {
    this.loadStoredToken().then();
  }

  getAccessToken(): Promise<string | null> {
    return firstValueFrom(this.accessToken);
  }

  setAccessToken(token: string) {
    this.accessToken.next(token);
    sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
    this.setTimeoutForTokenRefresh(token);
  }

  clearAccessToken() {
    this.accessToken.next(null);
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  stream(): Observable<string | null> {
    return this.accessToken.asObservable();
  }

  private async loadStoredToken() {
    this.tryMigrateFromLocalStorage();

    const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
    if (!accessToken) {
      this.accessToken.next(null);
      return;
    }

    const decodedSetupToken = decodeJwt(accessToken);
    if (!decodedSetupToken.exp || decodedSetupToken.exp * 1000 < Date.now() + 60 * 1000) {
      // Token is expired
      this.clearAccessToken();
      this.accessToken.next(null);
      return;
    }

    // If we got here, the token is valid
    this.setTimeoutForTokenRefresh(accessToken);
    this.accessToken.next(accessToken);
  }

  private tryMigrateFromLocalStorage() {
    // In earlier (test) versions, the token was stored in localStorage. Migrate it to IonicStorage
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken) {
      this.setAccessToken(accessToken);
      localStorage.removeItem(ACCESS_TOKEN_KEY);
    }
  }

  private setTimeoutForTokenRefresh(token: string) {
    const decodedSetupToken = decodeJwt(token);
    if (!decodedSetupToken || !decodedSetupToken.exp) return;

    const expiresIn = decodedSetupToken.exp * 1000 - Date.now();
    if (expiresIn < 0) return;

    if (this._tokenTimeout) clearTimeout(this._tokenTimeout);

    this._tokenTimeout = setTimeout(() => {
      // The access token has expired, we need a new one
      this.clearAccessToken();
    }, expiresIn);
  }
}
