/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeneratedDataCleanupAnalyzeResultItem } from './dataCleanupAnalyzeResultItem';

/**
 *
 */
export interface GeneratedDataCleanupAnalyzeResult {
  items: Array<GeneratedDataCleanupAnalyzeResultItem>;
  total: number;
  iterations: number;
  totalDataInstances: number;
  duration: number;
}
