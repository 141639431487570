import { Injectable } from '@angular/core';
import { ApiBase } from './ApiBase';
import { GeneratedSearchRequest, GeneratedSearchResponse } from '../types/generated';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchEndpoints extends ApiBase {
  public search(searchRequest: GeneratedSearchRequest): Observable<GeneratedSearchResponse> {
    return this.post<GeneratedSearchResponse>(`/game/${this.gameId}/search/${this.dataPackage}`, searchRequest);
  }
}
