import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SavingIndicatorService {
  private showPromises: Record<string, Promise<unknown> | unknown> = {};

  private shown = new BehaviorSubject<boolean>(false);
  public readonly shown$ = this.shown.asObservable();

  public async show<T = void>(data: () => Promise<T> | T) {
    const id = crypto.randomUUID();
    const startTime = Date.now();

    try {
      this.showPromises[id] = data();

      this.update();
      return await this.showPromises[id];
    } finally {
      // We add a small delay so it doesn't flicker as much
      setTimeout(
        () => {
          delete this.showPromises[id];
          this.update();
        },
        500 - (Date.now() - startTime),
      );
    }
  }

  private update() {
    this.shown.next(Object.keys(this.showPromises).length > 0);
  }
}
