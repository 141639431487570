import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import { AlertService } from '@services/UI-elements/alert-service';
import { BootstrapClass } from '@services/types/BootstrapClass';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { VariableType } from '@services/types/VariableType';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { UnsavedChangesCheck } from '@guards/saved-changes-checker.guard';
import { VariableRepository } from '@services/repositories';
import { Variable } from '@services/entities';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-variable-editor',
  templateUrl: './variable-editor.component.html',
  styleUrls: ['./variable-editor.component.scss'],
})
export class VariableEditorComponent implements OnInit, OnDestroy, UnsavedChangesCheck {
  variableRef?: string;
  variable?: Variable;
  newName = '';
  newDescription?: string;
  newValueType?: VariableType;
  newStartValue: string | boolean | number = '';
  newIsPlayThroughStatistic = false;
  hasChanges = false;

  protected readonly valueTypeOptions = Object.values(VariableType);
  protected readonly String = String;
  protected readonly Number = Number;
  protected readonly VariableType = VariableType;
  private routeSub?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private confirmService: ConfirmationModalService,
    private loadingScreenService: LoadingScreenService,
    private variableRepository: VariableRepository,
    private navigationService: NavigationService,
  ) {}

  @HostListener('window:beforeunload')
  hasNoUnsavedChanges() {
    return !this.hasUnsavedChanges();
  }

  ngOnInit(): void {
    this.routeSub = this.activatedRoute.params.subscribe(async (params) => {
      this.variableRef = params[this.navigationService.queryParamKeys.Variable];

      if (!this.variableRef) {
        await this.navigationService.navigateToResource('', 'Variable');
        this.alertService.showAlert('Variable not found in the url', BootstrapClass.DANGER);
        return;
      }

      this.variable = await this.variableRepository.get(this.variableRef);

      if (!this.variable) {
        await this.navigationService.navigateToResource('', 'Variable');
        this.alertService.showAlert('Variable instance not found', BootstrapClass.DANGER);
        return;
      }

      this.newName = this.variable.name;
      this.newDescription = this.variable.description;
      this.newValueType = this.variable.valueType;
      this.newIsPlayThroughStatistic = this.variable.isPlayThroughStatistic ?? false;

      if (this.variable.startValue && this.variable.valueType) {
        switch (this.variable.valueType) {
          case VariableType.Number:
            this.newStartValue = parseFloat(this.variable.startValue);
            break;
          case VariableType.Boolean:
            this.newStartValue = this.variable.startValue === 'true';
            break;
          case VariableType.String:
          case VariableType.Color:
            this.newStartValue = this.variable.startValue;
            break;
        }
      }
    });
  }

  onValueTypeChange() {
    if (!this.variable) {
      return;
    }

    this.hasChanges = true;

    switch (this.newValueType) {
      case VariableType.Boolean:
        this.newStartValue = false;
        break;
      case VariableType.Number:
        this.newStartValue = 0;
        break;
      case VariableType.Color:
        this.newStartValue = '#ffffff';
        break;
      case VariableType.String:
      default:
        this.newStartValue = '';
        break;
    }
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  hasUnsavedChanges(): boolean {
    return this.hasChanges;
  }

  async saveVariable(): Promise<void> {
    if (!this.variable) {
      return;
    }

    if (this.newValueType === undefined) {
      throw new Error('newValueType must be set');
    }

    if (this.variable.valueType !== this.newValueType) {
      if (
        !(await lastValueFrom(
          this.confirmService.confirm(
            'Since the valuetype has changed, all instances of this variable will be removed in VariableComparisons and SetVariableActions. Are you sure you want to continue?',
          ),
        ))
      )
        return;
    }

    this.variable.name = this.newName;
    this.variable.description = this.newDescription;
    this.variable.valueType = this.newValueType;
    this.variable.startValue = this.newStartValue.toString();
    this.variable.isPlayThroughStatistic = this.newIsPlayThroughStatistic;

    this.alertService.showAlert('Variable saved', BootstrapClass.SUCCESS);
    this.hasChanges = false;
  }

  async deleteVariable() {
    if (!this.variable) return;

    const confirmed = await firstValueFrom(
      this.confirmService.confirm('Are you sure you want to delete variable ' + this.variable.name + '?'),
    );
    if (!confirmed) return;

    return this.loadingScreenService.show(async () => {
      if (!this.variable) return;
      await this.variableRepository.delete(this.variable);
      return await this.navigationService.navigateToResource('', 'Variable');
    });
  }

  updateColor(color: string) {
    this.hasChanges = true;
    this.newStartValue = color;
  }

  onNewNumber() {
    this.hasChanges = true;
    this.newStartValue = Math.round(Number(this.newStartValue));
  }
}
