import { Injectable } from '@angular/core';
import { ApiBase } from '../ApiBase';
import { Observable } from 'rxjs';
import { GeneratedStructType, GeneratedStructTypeUpdate } from '../../types/generated';

@Injectable({ providedIn: 'root' })
export class StructTypeEndpoints extends ApiBase {
  public getAllStructTypes(): Observable<GeneratedStructType[]> {
    return this.get<GeneratedStructType[]>(`/game/${this.gameId}/schema/struct-types`);
  }

  public getStructType(structTypeId: string): Observable<GeneratedStructType> {
    return this.get<GeneratedStructType>(`/game/${this.gameId}/schema/struct-types/${structTypeId}`);
  }

  public createStructType(structType: GeneratedStructType): Observable<GeneratedStructType> {
    return this.post<GeneratedStructType>(`/game/${this.gameId}/schema/struct-types`, structType);
  }

  public updateStructType(updateStructType: GeneratedStructTypeUpdate, force = false): Observable<GeneratedStructType> {
    return this.put<GeneratedStructType>(`/game/${this.gameId}/schema/struct-types/${updateStructType.typeId}`, updateStructType, {
      params: force ? { force: 'true' } : undefined,
    });
  }

  public deleteStructType(structTypeId: string, force = false): Observable<void> {
    return this.delete<void>(`/game/${this.gameId}/schema/struct-types/${structTypeId}`, {
      params: force ? { force: 'true' } : undefined,
    });
  }
}
