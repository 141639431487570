<!-- The mission tree screen -->
<div class="mission-tree">
  <app-flowchart
    (addActivity)="onAddActivity($event)"
    (deleteActivities)="deleteSelectedNodes($event)"
    (duplicateActivities)="duplicateSelectedNodes($event)"
    (nodeClicked)="setSelectedNode($event)"
    (pasteActivities)="duplicateSelectedNodes($event, true)"
    [flowchartType]="FlowchartType.MISSION"
    [nodes]="nodes"
  />
</div>

<!--Buttons to save and add new activities -->
<div class="d-flex justify-content-center align-items-center gap-2 m-2">
  <p class="h4 mb-0 me-2">{{ missionName }}</p>

  <button (click)="openOverview()" class="btn btn-primary"><i class="bi bi-info-circle"></i> Overview</button>

  <div *ngIf="startActivityField">Start activity:</div>
  <div *ngIf="startActivityField">
    <app-resource-selector-field [data]="startActivityField" />
  </div>

  <a
    *ngIf="environment.playGameUrl && missionInfo"
    class="btn btn-primary"
    href="{{ environment.playGameUrl }}?offline=1&mission={{ missionInfoIdentifier }}{{ moduleId ? '&module=' + moduleId : '' }}"
    target="_blank"
  >
    <i class="bi bi-play-circle"></i> Play from start
  </a>

  <a
    *ngIf="environment.playGameUrl && missionInfo && activityInstanceUid"
    class="btn btn-primary"
    href="{{ environment.playGameUrl }}?offline=1&mission={{ missionInfoIdentifier }}&activity={{ activityInstanceUid }}{{
      moduleId ? '&module=' + moduleId : ''
    }}"
    target="_blank"
  >
    <i class="bi bi-play-circle"></i> Play from current activity
  </a>
</div>

<!--The editor where the current activity or the overview of the mission can be added-->
<div *ngIf="!loadingMission && !loadingActivity" class="container mt-2 pb-2">
  <app-activity *ngIf="currentActivity" [uid]="currentActivity.dataInstanceUid" />

  <app-struct-instance-editor *ngIf="!currentActivity && missionInfo" [data]="missionInfo"></app-struct-instance-editor>
</div>
