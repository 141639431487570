import { environment } from '../../../environments/environment';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { merge } from 'lodash';
import { Injectable } from '@angular/core';

interface RequestOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json' | 'arraybuffer' | 'blob' | 'text';
  withCredentials?: boolean;
  withBase?: boolean;
  context?: HttpContext;
}

@Injectable({ providedIn: 'root' })
export abstract class ApiBase {
  public readonly baseUrl = environment.casHost;
  public readonly gameId = environment.defaultGame;
  public readonly dataPackage = environment.dataPackage;

  protected constructor(private http: HttpClient) {}

  protected get<T>(endpoint: `/${string}`, options?: RequestOptions): Observable<T> {
    // @ts-expect-error method resolution has multiple overloads that ts doesn't understand
    return this.http.get<T>(this.buildUrl(endpoint, options), merge({ ...this.defaultOptions }, options));
  }

  protected post<T, V = object>(endpoint: `/${string}`, data: V, options?: RequestOptions): Observable<T> {
    // @ts-expect-error method resolution has multiple overloads that ts doesn't understand
    return this.http.post<T>(this.buildUrl(endpoint, options), data, merge({ ...this.defaultOptions }, options));
  }

  protected put<T, V = object>(endpoint: `/${string}`, data: V, options?: RequestOptions): Observable<T> {
    // @ts-expect-error method resolution has multiple overloads that ts doesn't understand
    return this.http.put<T>(this.buildUrl(endpoint, options), data, merge({ ...this.defaultOptions }, options));
  }

  protected delete<T>(endpoint: `/${string}`, options?: RequestOptions): Observable<T> {
    // @ts-expect-error method resolution has multiple overloads that ts doesn't understand
    return this.http.delete<T>(this.buildUrl(endpoint, options), merge({ ...this.defaultOptions }, options));
  }

  private buildUrl(endpoint: string, options?: RequestOptions): string {
    if (options?.withBase === false) return new URL(endpoint).toString();
    return new URL(this.baseUrl.replace(/\/$/, '') + (endpoint.startsWith('/') ? endpoint : '/' + endpoint)).toString();
  }
}
