import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchEndpoints } from '../api';
import { GeneratedSearchRequest, GeneratedSearchResponse } from '../types/generated';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private readonly shown = new BehaviorSubject<boolean>(false);
  public readonly shown$ = this.shown.asObservable();

  constructor(private searchEndpoints: SearchEndpoints) {}

  public show() {
    this.shown.next(true);
  }

  public hide() {
    this.shown.next(false);
  }

  public toggle() {
    if (this.shown.value) this.hide();
    else this.show();
  }

  public search(query: GeneratedSearchRequest): Observable<GeneratedSearchResponse> {
    return this.searchEndpoints.search(query);
  }
}
