import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldValue, SelectTypeOption } from '@services/entities/helpers';
import { DataInstance } from '@services/entities';
import { DataInstanceRepository } from '@services/repositories';
import { Logger, Try } from '@services/utils';
import { ResourceService } from '@services/resource.service';
import { FileEndpoints } from '@services/api';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value: string = '';

  activityInstance?: DataInstance;

  backgroundUrl = 'assets/images/select_image.png';
  characterUrl = 'assets/images/select_image.png';
  characters: SelectTypeOption[] = [
    new SelectTypeOption({
      label: 'Select a character',
      optionId: '',
    }),
  ];
  backgrounds: SelectTypeOption[] = [
    new SelectTypeOption({
      label: 'Select a background',
      optionId: '',
    }),
  ];

  backgroundField?: FieldValue;
  characterField?: FieldValue;

  private routeSub?: Subscription;

  constructor(
    private dataInstanceRepository: DataInstanceRepository,
    private resourceService: ResourceService,
    private fileEndpoints: FileEndpoints,
  ) {}

  async ngOnInit() {
    if (!this.data) {
      throw new Error('Data not found');
    }

    const characters = this.resourceService.resourceInstances['Character'];
    if (characters)
      this.characters = [
        ...(await Promise.all(
          characters.map(
            async (r) =>
              new SelectTypeOption({
                label: r.getName(),
                optionId: await r.identifier,
              }),
          ),
        )),
      ];

    const backgrounds = this.resourceService.resourceInstances['Background'];
    if (backgrounds)
      this.backgrounds = [
        ...(await Promise.all(
          backgrounds.map(
            async (r) =>
              new SelectTypeOption({
                label: r.getName(),
                optionId: await r.identifier,
              }),
          ),
        )),
      ];

    await this.loadEditor(this.data.dataInstanceUid);
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
  }

  async changeCharacter(characterInstanceUid: string | string[]) {
    const character = characterInstanceUid instanceof Array ? characterInstanceUid[0] : characterInstanceUid;

    if (!character) {
      this.characterUrl = 'assets/images/select_image.png';
      await this.characterField?.set('');
      return;
    }

    const characterInstance = await this.dataInstanceRepository.get(character);
    if (!characterInstance) throw new Error('Character not found');

    await this.characterField?.set(character);

    const imageMetaUid = characterInstance?.fieldValues['normalSprite']?.value;
    if (!imageMetaUid) {
      Logger.warn('No image found for character');
      this.characterUrl = 'assets/images/select_image.png';
      return;
    }

    this.characterUrl = URL.createObjectURL(await lastValueFrom(this.fileEndpoints.downloadFile(imageMetaUid)));
  }

  async changeBackground(backgroundInstanceUid: string | string[]) {
    const background = backgroundInstanceUid instanceof Array ? backgroundInstanceUid[0] : backgroundInstanceUid;

    if (!background) {
      this.backgroundUrl = 'assets/images/select_image.png';
      await this.backgroundField?.set('');
      return;
    }

    const backgroundInstance = await this.dataInstanceRepository.get(background);
    if (!backgroundInstance) throw new Error('Background not found');

    await this.backgroundField?.set(background);

    const imageMetaUid = backgroundInstance.fieldValues['image']?.value;

    if (!imageMetaUid) {
      Logger.warn('No image found for character');
      this.backgroundUrl = 'assets/images/select_image.png';
      return;
    }

    this.backgroundUrl = URL.createObjectURL(await lastValueFrom(this.fileEndpoints.downloadFile(imageMetaUid)));
  }

  private async loadEditor(activityInstanceUid: string) {
    this.activityInstance = await this.dataInstanceRepository.get(activityInstanceUid);
    if (!this.activityInstance) {
      Logger.warn('Activity instance not found');
      return;
    }

    await Promise.all([
      Try(
        async () => {
          const backgroundField = this.activityInstance!.fieldValues['background'];
          if (!this.backgroundField || !this.backgroundField.value) {
            this.backgroundField = backgroundField!;
            await this.changeBackground(this.backgroundField.value as string);
          }
        },
        () => Logger.warn('No background found'),
      ),
      Try(
        async () => {
          const characterField = this.activityInstance!.fieldValues['character'];
          if (!this.characterField || !this.characterField.value) {
            this.characterField = characterField!;
            await this.changeCharacter(this.characterField.value as string);
          }
        },
        () => Logger.warn('No character found'),
      ),
    ]);
  }
}
