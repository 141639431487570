<div class="background-gradient"></div>
<section class="min-vh-100">
  <nav class="navbar navbar-expand-lg bg-primary sticky-top py-1">
    <div class="container-fluid">
      <a class="navbar-brand me-1" routerLink="/">
        <img alt="CAS Logo" height="30" ngSrc="assets/images/orange-slice-30px.webp" width="30" />
      </a>

      <button
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        class="navbar-toggler"
        data-bs-target="#navbarSupportedContent"
        data-bs-toggle="collapse"
        type="button"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!--        All resource structs-->
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <ng-container *ngIf="resourceStructsPromise | async as resourceStructs; else loader">
            <li *ngFor="let resourceStruct of resourceStructs" class="nav-item">
              <a [routerLink]="resourceStruct.route" class="nav-link" id="resource-{{ resourceStruct.name }}-button">{{
                resourceStruct.name
              }}</a>
            </li>
          </ng-container>

          <ng-template #loader>
            <li class="nav-item">
              <div class="nav-link">Loading navigation items..</div>
            </li>
          </ng-template>
        </ul>

        <!-- Dark mode toggle-->
        <button (click)="toggleDarkMode()" class="btn btn-primary ms-auto cursor-pointer border-0 mt-0">
          <div *ngIf="themeService.currentTheme === 'light'"><i class="fas fa-moon"></i></div>
          <div *ngIf="themeService.currentTheme === 'dark'"><i class="fas fa-sun"></i></div>
        </button>

        <!-- Search-->
        <button (click)="showSearch()" class="btn btn-link ms-2 p-0 cursor-text border-0">
          <input class="form-control search-input" placeholder="Search" type="text" />
        </button>
      </div>
    </div>
  </nav>

  <app-search-popover />
  <router-outlet />

  <div class="build-info text-muted">
    <p *ngFor="let line of versions" style="margin: 0">
      {{ line }}
    </p>
  </div>
</section>
