<div class="row">
  <div *ngFor="let message of messages">
    <!--  Messages use different CSS styling based on the character that sends the message-->
    <div
      [ngClass]="{
        'player-message': isSentByPlayer(message),
        'guide-message': isSentByGuide(message),
        'NPC-message': isSentByNPC(message),
      }"
      class="message"
    >
      <app-selector-field [choices]="chatCharacters" [data]="message.fields['character']" [showReloadOptionButton]="false" />

      <ng-container *ngIf="message.structType === 'TextMessage'">
        <app-text-with-audio-field
          *ngIf="
            message.fields['text'].field.type === 'Struct<TextWithAudio>' ||
            message.fields['text'].field.type === 'StructRef<TextWithAudio>'
          "
          [data]="message.fields['text']"
          class="chat"
        />

        <ng-container *ngIf="hasEmotionField">
          <app-select-type-selector [data]="message.fields['emotion']" />
        </ng-container>

        <!-- TODO: This is never true -->
        <app-text-input-field *ngIf="message.fields['text'].field.type === FieldType.STRING" [data]="message.fields['text']" class="chat" />
      </ng-container>

      <ng-container *ngIf="message.structType === 'ImageMessage'">
        <!-- TODO: This image has hard coded height and width.. -->
        <img *ngIf="mediaUrls[message.uid]" alt="" height="100" ngSrc="{{ mediaUrls[message.uid] }}" width="150" />

        <app-file-upload (newFileSelected)="changeImageOfMessage($event, message.uid)" [data]="message.fields['image']" />
      </ng-container>

      <app-struct-instance-field-editor
        *ngIf="message.structType === 'RunActionsMessage'"
        [data]="message.fields['actions']"
        class="chat"
        editorType="SeamlessInline"
      />

      <button (click)="moveMessageUp(message)" [disabled]="isFirstMessage(message)" class="arrow-btn">
        <img alt="buttonpng" height="20" ngSrc="../../../../../assets/images/arrow_up.png" width="20" />
      </button>

      <button (click)="moveMessageDown(message)" [disabled]="isLastMessage(message)" class="arrow-btn">
        <img alt="buttonpng" height="20" ngSrc="../../../../../assets/images/arrow_down.png" width="20" />
      </button>

      <button (click)="removeMessage(message)" class="btn btn-no-space btn-outline-danger">
        <span class="bi bi-trash"></span>
      </button>
    </div>
  </div>
</div>

<select #messageType class="form-select" name="dropdown">
  <option [value]="'TextMessage'">Text message</option>
  <option [value]="'ImageMessage'">Image message</option>
  <option [value]="'RunActionsMessage'">Run Action message</option>
</select>

<button (click)="addMessage('NPC', messageType.value)" class="btn btn-success btn-space">Add Message</button>
