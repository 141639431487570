/**
 * A type that defines a set of transformers for an object. The transformers are
 * defined as an object with the same keys as the object that the transformers
 * are applied to. The value of each key is an object with a get and/or a set
 * method. The get method is called when the corresponding property of the object
 * is accessed, and the set method when it is set.
 */
type Transformers<T> = {
  [K in keyof Partial<T>]?: {
    set?: (value: T[K]) => T[K];
    get?: (originalValue: T[K]) => T[K];
  };
};

/**
 * Creates a proxy for an object that applies transformers to the object's
 * properties.
 *
 * @example
 * const obj = { foo: 'bar' };
 * const proxiedObj = addTransformers(obj, {
 *   foo: {
 *     get: (value: string) => value.toUpperCase(),
 *     set: (value: string) => value.toLowerCase(),
 *   },
 * });
 *
 * console.log(proxiedObj.foo); // 'BAR'
 * proxiedObj.foo = 'BAZ';
 * console.log(obj.foo); // 'baz'
 *
 * @param obj The object to proxy.
 * @param transformers The transformers to apply to the object's properties.
 * @returns The proxied object.
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addTransformers<T extends { [key: string | symbol]: any }>(obj: T, transformers: Transformers<T>): T {
  return new Proxy(obj, {
    get(target, prop: keyof T) {
      if (prop in transformers && transformers[prop as keyof typeof transformers]?.get) {
        return transformers[prop as keyof typeof transformers]?.get!(target[prop]);
      }

      return target[prop];
    },
    set(target: T, prop: keyof T, newValue: (typeof target)[keyof T]): boolean {
      const transformedValue = transformers[prop as keyof typeof transformers]?.set
        ? transformers[prop as keyof typeof transformers]?.set!(newValue)
        : newValue;

      if (target[prop] === transformedValue) {
        return false;
      }

      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      (target as any)[prop] = transformedValue;
      return true;
    },
  });
}
